import { useContext } from "react";
import { FormCP } from "./components/steps";
import { FormTable } from "./components/table";
import { useRulesBy22 } from "pages/endoso/hooks/ruleByTypes";
import { InfoTable } from "./components/infoTable";
import { EndosoContext } from "pages/endoso/context";

export const AltaUbicacion22 = (props) => {
    const { finishR22, setFinishR22 } = useContext(EndosoContext);
    const { continueR22 } = useRulesBy22();

    return (
        <div>
            {continueR22 ? (
                <InfoTable setData={props.setData} />
            ) : (
                finishR22 ? (
                    <FormTable idSolicitud={props.noSolicitud} />
                ) :
                    <FormCP data={props.data} noSolicitud={props.noSolicitud} setFinish={setFinishR22}/>
            )}
        </div>
    );
};
