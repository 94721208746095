import { Dialog } from "@material-ui/core";

const Modal = ({ children, open, close, ...rest }) => {
  return (
    <Dialog {...rest} open={open} onClose={close}>
      {children}
    </Dialog>
  );
};

export default Modal;
