import { makeStyles } from "@material-ui/core";
import { colors } from "../../shared/utils";
const useStyles = makeStyles({
  root: {
    background: colors.disabledSoft,
    width: "43%",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    boxSizing: "border-box",
    padding: "24px",
  },

  sections: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    "& h5": { color: colors.blueDarken },
    "& h6": { color: colors.blueDarken },
    "&:last-child": {
      display: "flex",
      alignItems: "end",
    },
  },
  subSections: {
    display: "flex",
    columnGap: "24px",
  },
  formContainer: {
    flex: 1,
  },
  label: {
    marginLeft: "16px",
    marginBottom: "4px",
    color: colors.blueDarken,
  },
  submit: {
    width: "50%",
    padding: "4px 0px",
    color: colors.blueDarken,
    background: colors.yellow,
    borderRadius: "19px",
    "&:hover": {
      background: colors.yellow,
    },
  },
});
export default useStyles;
