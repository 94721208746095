import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils/colors";

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  section: {
    display: "flex",
    columnGap: "16px",
  },
  button: {
    color: colors.blueDarken,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    width: "55%",
    columnGap: "24px",
  },
});

export default useStyles;
