import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import { useEffect, useState } from "react";

const DataSection = ({ dataPoliza, dataContractor }) => {
  const [contratante, setContratante] = useState(dataPoliza.contratante);
  const classes = useStyles();
  useEffect(() => {
    if (dataContractor) {
      if (dataContractor) {
        const {
          NOMBR1 = "",
          NOMBR2 = "",
          DSAPEL1 = "",
          DSAPEL2 = "",
        } = dataContractor;
        const name = `${NOMBR1 ?? ""} ${NOMBR2 ?? ""} ${DSAPEL1 ?? ""} ${
          DSAPEL2 ?? ""
        }`.trim();
        if (name.length > 0) {
          setContratante(name);
        }
      }
    }
  }, [dataContractor]);
  return (
    <Paper elevation={3} className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Datos de la póliza
      </Typography>
      <div className={classes.sections}>
        <div className={classes.information + " " + classes.fisrtInfo}>
          <Typography variant="h6">{dataPoliza.poliza}</Typography>
          <Typography variant="caption">Póliza</Typography>
        </div>
        <div
          className={clsx(classes.status, {
            [classes.good]: dataPoliza.status === "Vigente",
            [classes.special]: dataPoliza.status === "Especial",
            [classes.blocked]: dataPoliza.status === "Bloqueado",
          })}
        >
          <Typography variant="h6">{dataPoliza.status}</Typography>
          <Typography variant="caption">Estatus</Typography>
        </div>
      </div>
      <div className={classes.sections}>
        <div className={classes.information}>
          <Typography variant="h6">{dataPoliza.oficina}</Typography>
          <Typography variant="caption">Oficina</Typography>
        </div>
        <div className={classes.information}>
          <Typography variant="h6">{dataPoliza.ramo}</Typography>
          <Typography variant="caption">Ramo</Typography>
        </div>
      </div>
      <div>
        <div className={classes.information}>
          <Typography variant="h6">{contratante}</Typography>
          <Typography variant="caption">Contratante</Typography>
        </div>
      </div>
      <div className={classes.sections}>
        <div className={classes.information}>
          <Typography variant="h6">{dataPoliza.iniVigencia}</Typography>
          <Typography variant="caption">Inicio Vigencia</Typography>
        </div>
        <div className={classes.information}>
          <Typography variant="h6">{dataPoliza.finVigencia}</Typography>
          <Typography variant="caption">Fin Vigencia</Typography>
        </div>
      </div>
      <div>
        <div className={classes.information}>
          <Typography variant="h6">{dataPoliza.detallesPoliza}</Typography>
          <Typography variant="caption">Detalles de la Póliza</Typography>
        </div>
      </div>
    </Paper>
  );
};

export default DataSection;
