import { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { EndosoContext } from "../../../../../context";
import { Select } from "../fields";
import { AuthContext } from "../../../../../../../context/context";

export const CargaBForm = () => {
  const { setTimer } = useContext(AuthContext);
  const { cargaB, cargaBData, setCargaBData } = useContext(EndosoContext);
  const { camiones = [], tgcargab = [] } = cargaB;

  /* methods */
  const handleChange = ({ target: { name, value } }) => {
    setTimer(0);
    setCargaBData({ ...cargaBData, [name]: value });
  };

  return (
    <div>
      <Typography variant="h6" color="initial" style={{ marginBottom: "16px" }}>
        Modificacion de la carga B en camiones
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Select
            label="Camion"
            name="situacion"
            required
            onChange={handleChange}
            optionsProps={{
              options: camiones,
              text: "descVehiculo",
              value: "nmsituac",
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Select
            label="Tipo de Carga"
            name="mercancia"
            required
            onChange={handleChange}
            optionsProps={{ options: tgcargab }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
