import { format } from "date-fns";
import {
  GET,
  POST,
  PUT,
  DELETE,
  POSTMEDIA,
  GETFILE,
} from "../shared/utils/crud";

const list = async (n_solicitud, user) => {
  const body = {
    noSolicitud: n_solicitud || null,
    oficina: null,
    pagina: null,
    poliza: null,
    ramo: null,
    registros: null,
    status: "WCM",
    tipoEndoso: null,
    usuario: user,
  };
  const res = await POST("endoso/solicitud", body);
  return res;
};

const create = async (body) => {
  const res = await POST("endoso", body);
  return res;
};

const edit = async (body) => {
  const { id, update } = body;

  const res = await PUT(`endoso/${id}`, update);
  return res;
};

const destroy = async (id) => {
  const res = await DELETE(`endoso/${id}`);
  return res;
};

const listType = async (ramo) => {
  const res = await GET(`endoso/${ramo}`);
  return res;
};

const getOficce = async () => {
  const res = await GET("endoso/oficina");
  return res;
};

const getRamo = async () => {
  const res = await GET("endoso/ramo");
  return res;
};
const getReport = async (body) => {
  const dateIni = new Date(body.dateStart);
  const dateIntial =
    dateIni.getDate() +
    "-" +
    (dateIni.getMonth() + 1) +
    "-" +
    dateIni.getFullYear();

  const dateFn = new Date(body.dateEnd);
  const dateFinshed =
    dateFn.getDate() +
    "-" +
    (dateFn.getMonth() + 1) +
    "-" +
    dateFn.getFullYear();
  const { oficce, ramo } = body;
  const res = await GET(
    `dashboard/datosGraficas/${oficce}/${ramo}/${dateIntial}/${dateFinshed}`
  );
  return res;
};

const generateReport = async (body) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const { dateEnd, dateStart, oficce, ramo } = body;
  const formatDate = (date) => format(date, "d-MM-yyyy");
  const url = `${apiUrl}/dashboard/reporteExcel/${oficce}/${ramo}/${formatDate(
    dateStart
  )}/${formatDate(dateEnd)}`;
  window.open(url, "_blank");
};

const sendComments = async (body) => {
  const res = POST("endoso/comentarios", body);
  return res;
};

const configuration = async (body) => {
  const res = await POST("poliza/configuracion", body);
  return res;
};
const configType20 = async (solicitud) => {
  const res = await GET(`endosos/camiones/${solicitud}`);
  return res;
};

const getLevel = async (body) => {
  const res = await POST("endoso/nivel", body);
  return res;
};

const cancelRequest = async (request) => {
  const res = await GET(`endoso/cancela/${request}`);
  return res;
};

const saveEndoso = async (body) => {
  const res = await POST("endoso/guarda", body);
  return res;
};

const savePerson = async (body) => {
  const res = await POST("persona/guarda", body);
  return res;
};

const confirma = async (body) => {
  const res = await POST("endoso/confirma", body);
  return res;
};

const codigoPostal = async (codigo) => {
  const res = await GET(`domicilio/codigoPostal/${codigo}`);
  return res;
};

const guardarDomicilio = async (body) => {
  const res = await POST("domicilio/guarda", body);
  return res;
};

const buscarSolicitud = async (body) => {
  const res = await POST("endoso/solicitud", body);
  return res;
};

const getCoberturas = async (body) => {
  const res = await POST("cobertura", body);
  return res;
};

const guardarCobertura = async (body) => {
  const res = await POST("cobertura/guarda", body);
  return res;
};

const guardarSituacion = async (body) => {
  const res = await POST("cobertura/tarifa/situacion", body);
  return res;
};
const diferencia = async (body) => {
  const res = await POST("cobertura/diferencia", body);
  return res;
};

const clausula = async (body) => {
  const res = await POST("clausula", body);
  return res;
};

const tarifa = async (body) => {
  const res = await POST("cobertura/tarifa", body);
  return res;
};

const situacionAlta = async (body) => {
  const res = await POST("persona/situacion/riesgo/alta", body);
  return res;
};
const situacionBaja = async (body) => {
  const res = await POST("persona/situacion/riesgo/baja", body);
  return res;
};

const sendDocuments = async (body) => {
  const res = await POST("endoso/notificacion", body);
  return res;
};

const buscarPersona = async (body) => {
  const res = await POST("persona/busqueda", body);
  return res;
};
const buscarDomicilio = async (body) => {
  const res = await POST("persona/domicilio/busqueda", body);
  return res;
};

const checkeo = async (body) => {
  const res = await POST("persona/situacion/riesgo/check607", body);
  return res;
};
const secctions21 = async (solicitud) => {
  const res = await GET(`endoso/${solicitud}/secciones`);
  return res;
}
const benPref = async (body) => {
  const res = await POST("endoso/guarda_ben_pref", body);
  return res;
}
const configType16 = async (solicitud) => {
  const res = await GET(`check607/situaciones/${solicitud}`);
  return res;
};
const configType14 = async (solicitud) => {
  const res = await GET(`check902/config/${solicitud}`);
  return res;
};
const configType22 = async (solicitud) => {
  const res = await GET(`check420430/${solicitud}/agrupadores`);
  return res;
};
const alta607 = async (body) => {
  const res = await POST("persona/situacion/riesgo/alta607", body);
  return res;
};
const alta902 = async (body) => {
  const res = await POST("persona/situacion/riesgo/alta902", body);
  return res;
};

const validaRegla902 = async (body) => {
  const res = await POST("endoso/valida-regla-902", body);
  return res;
};

const bajasUbicacion = async (idSolicitud) => {
  const res = await GET(`endoso/${idSolicitud}/baja_asegurados`);
  return res;
};
const bajaUbicacion23 = async (body) => {
  const res = await POST("endoso/baja_ubicacion_420_430", body);
  return res; 
};
const motivo = async (body) => {
  const res = await POST("endoso/motivo", body);
  return res;
};

const saveCargaB = async (body) => {
  const res = await POST("endoso/cargab", body);
  return res;
};

const cambioSexo = (body) => {
  const res = POST("persona/cambia", body);
  return res;
};

const check605 = (body) => {
  const res = POST("persona/situacion/riesgo/check605", body);
  return res;
};

const checkBaja605 = (idSolicitud) => {
  const res = GET(`check/importe/${idSolicitud}`);
  return res;
};

const nivel902 = async (body) => {
  const res = await POST("endoso/nivel902", body);
  if (res.data) {
    const { niveles } = res.data;
    const newNiveles = niveles.map((nivel) => ({ ...nivel, checked: false }));
    return { ...res, data: { ...res.data, niveles: newNiveles } };
  }
  return res;
};

const uploadFile = async (body, oficina, ramo, poliza, nSolicitud) => {
  const formData = new FormData();
  formData.append("file", body);
  const res = await POSTMEDIA(
    `endoso/${oficina}/${ramo}/${poliza}/upload-xlsx/${nSolicitud}`,
    formData
  );
  return res;
};

const configModDescripcion = async ({ oficina, ramo, poliza }) => {
  const res = await GET(`endoso/${oficina}/${ramo}/${poliza}/descripcion`);
  return res;
};

const saveDescription = async (body) => {
  const res = await POST("endoso/descripcion_autos", body);
  return res;
};

const descargarLayout = async (action) => {
  const res = await GETFILE("descargar-layout", action);
  return res;
};

const validateCP = async (cp) => {
  const res = await GET(`endoso/valida-cp/${cp}`);
  return res;
};

const validateColonia = async (body) => {
  const res = await POST("endoso/valida-colonia", body);
  return res;
};

const altaSit420_430 = async (body) => {
  const res = await POST("endoso/alta_sit_420_430", body);
  return res;
};

const coberturas = async (idSolicitud) => {
  const res = await GET(`endoso/find_coberturas/${idSolicitud}`);
  return res;
};

const insertCobertura = async (body) => {
  const res = await POST("endoso/ins_cobertura_420_430", body);
  return res;
};

const endoso = {
  list: list,
  create: create,
  edit: edit,
  destroy: destroy,
  listType: listType,
  getOficce: getOficce,
  getRamo: getRamo,
  getReport: getReport,
  generateReport: generateReport,
  sendComments: sendComments,
  configuration: configuration,
  getLevel: getLevel,
  cancelRequest: cancelRequest,
  saveEndoso: saveEndoso,
  savePerson: savePerson,
  confirma: confirma,
  codigoPostal: codigoPostal,
  guardarDomicilio: guardarDomicilio,
  buscarSolicitud: buscarSolicitud,
  getCoberturas: getCoberturas,
  guardarCobertura: guardarCobertura,
  guardarSituacion: guardarSituacion,
  diferencia: diferencia,
  clausula: clausula,
  tarifa: tarifa,
  situacionAlta: situacionAlta,
  situacionBaja: situacionBaja,
  sendDocuments: sendDocuments,
  buscarPersona: buscarPersona,
  buscarDomicilio: buscarDomicilio,
  checkeo: checkeo,
  configType16: configType16,
  alta607: alta607,
  motivo: motivo,
  configType14: configType14,
  alta902: alta902,
  configType20: configType20,
  saveCargaB: saveCargaB,
  cambioSexo: cambioSexo,
  check605: check605,
  uploadFile: uploadFile,
  nivel902: nivel902,
  checkBaja605: checkBaja605,
  configModDescripcion: configModDescripcion,
  saveDescription: saveDescription,
  descargarLayout: descargarLayout,
  secctions21: secctions21,
  benPref: benPref,
  validaRegla902: validaRegla902,
  validateCP: validateCP,
  validateColonia: validateColonia,
  altaSit420_430: altaSit420_430,
  coberturas: coberturas,
  insertCobertura: insertCobertura,
  configType22: configType22,
  bajasUbicacion: bajasUbicacion,
  bajaUbicacion23: bajaUbicacion23,
};
export default endoso;
