import { createContext, useState } from "react";

export const AuthContext = createContext({
  state: undefined,
  dispatch: undefined,
  token: undefined,
  setToken: undefined,
  direction: undefined,
  setDirection: undefined,
  coberturas: undefined,
  setCoberturas: undefined,
  persons: undefined,
  setPersons: undefined,
  confirmData: undefined,
  setConfirmData: undefined,
  timer: undefined,
  setTimer: undefined,
  message: undefined,
  setSnackData: undefined,
  showMessage: undefined,
  setSnackMessage: undefined,
  firstLoad: undefined,
  setFirstLoad: undefined,
  isInterno: undefined,
  setIsInterno: undefined,
  setSections21: undefined,
  sections21: undefined,
  typeEnd: undefined,
  showMonthlySalary: undefined,
  setShowMonthlySalary: undefined,
  handleContinuer: undefined,
  setHandleContinue: undefined,
  showContinue: undefined,
  setShowContinue: undefined,
});

export const ContextProvider = ({ children }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [state, dispatch] = useState();
  const [direction, setDirection] = useState({});
  const [coberturas, setCoberturas] = useState({});
  const [persons, setPersons] = useState([]);
  const [confirmData, setConfirmData] = useState({});
  const [timer, setTimer] = useState(0);
  const [showMessage, setSnackMessage] = useState(false);
  const [message, setSnackData] = useState({});
  const [sections21, setSections21] = useState([]);
  const [showContinue, setShowContinue] = useState(false);
  const [showMonthlySalary, setShowMonthlySalary] = useState(false);
  const [handleContinue, setHandleContinue] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : {}
  );
  const [isInterno, setIsInterno] = useState();
  const [typeEnd, setTypeEnd] = useState();
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        token,
        setToken,
        direction,
        setDirection,
        coberturas,
        setCoberturas,
        persons,
        setPersons,
        confirmData,
        setConfirmData,
        timer,
        setTimer,
        message,
        setSnackData,
        showMessage,
        setSnackMessage,
        firstLoad,
        setFirstLoad,
        isInterno,
        setIsInterno,
        setSections21,
        sections21,
        typeEnd,
        setTypeEnd,
        showMonthlySalary,
        setShowMonthlySalary,
        handleContinue,
        setHandleContinue,
        showContinue,
        setShowContinue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
