import { Fragment } from "react";
import { InputLabel, TextField } from "@material-ui/core";
import useStyles from "../styles";

export const Number = ({
  label,
  name,
  onChange,
  required,
  maxLenght,
  defaultValue,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <InputLabel>{label + (required ? " (*)" : "")}</InputLabel>
      <TextField
        className={classes.input}
        variant="outlined"
        name={name}
        size="small"
        value={defaultValue ?? ""}
        onChange={onChange}
        fullWidth
        inputProps={{
          ...Boolean(maxLenght && { maxLenght: maxLenght }),
          style: { textTransform: "uppercase" },
        }}
        onInput={(e) => {
          e.target.value = e.target.value
            .replace(/[^0-9.]/, "")
            .replace(/(\..*)\./g, "$1");
        }}
      />
    </Fragment>
  );
};
