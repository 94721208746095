import { EndosoContext } from "pages/endoso/context";
import { useContext, useEffect } from "react";
import { endoso } from "services";

export const useRulesBy22 = () => {
    const { dataR22, setShowButtons, showButtons, setContinueR22, continueR22, createMessage, setListErrors22 } = useContext(EndosoContext);
    let error = false;

    useEffect(() => {
        setShowButtons({
            ...showButtons,
            disableTwentyTwo: !dataR22?.accumulatedData?.length > 0,
        });
    }, [dataR22]); // eslint-disable-line

    const handleConfirm = async () => {
        if (dataR22?.accumulatedData?.length > 0) {
            let accumulatedErrors = [];
            for (const item of dataR22?.accumulatedData) {
                let sumaAseguradaItem = item.sumaAsegurada.toString();
                const hasThreeEqualDigitsOrCurrency = (str) => {
                    const cleanedStr = str.replace(/^\$/, '');
                    return /^(\d)\.\1{2}$/.test(cleanedStr);
                };

                let sumaAseguradaStr = typeof item.sumaAsegurada === 'string'
                    ? item.sumaAsegurada
                    : sumaAseguradaItem.length === 4 && hasThreeEqualDigitsOrCurrency(sumaAseguradaItem) 
                        ? item.sumaAsegurada.toString() 
                        : (item.sumaAsegurada / 100).toString();

                item.sumaAsegurada = parseFloat(sumaAseguradaStr.replace(/[^0-9.-]+/g, ""));
                if (typeof item.sumaAsegurada === 'number' && item.sumaAsegurada < item.sumaAsegurada * 100) {
                    item.banderaDividido = true;
                }

                const res = await endoso.insertCobertura(item);
                if (item.banderaDividido) {
                    item.sumaAsegurada *= 100;
                    delete item.banderaDividido;
                }

                if (res?.data?.codigo === 0 || res.error) {
                    accumulatedErrors.push(item.cdGarantia);
                    createMessage({
                        text: res?.data?.mensaje || "Ocurrió un error al insertar la cobertura: " + item.cdGarantia,
                        variant: "warning",
                    });
                    error = true;
                }
            }
            if (accumulatedErrors.length > 0) {
                setListErrors22(accumulatedErrors);
            }
            if (error) {
                if (accumulatedErrors.length !== dataR22?.accumulatedData?.length) {
                    createMessage({
                        text: "Algunas coberturas no se insertaron correctamente",
                        variant: "warning",
                    });
                }
            } else {
                setShowButtons({
                    ...showButtons,
                    infoTable: true,
                });
                setContinueR22(true);
            }
        }
    };

    return {
        handleConfirm,
        continueR22,
    };
};
