import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "98vh",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      flexDirection: "column-reverse",
      rowGap: "24px",
    },
  },
  sections: {
    flex: 0.75,
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      flex: 0.25,
      marginLeft: "24px",
    },

    /* responsive */
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: "100%",
      alingItems: "center",
      "&:last-child": {
        marginLeft: "0",
      },
    },
  },
  subSectionRight: {
    flex: 0.25,
    "&:last-child": {
      marginTop: "24px",
      flex: 0.75,
    },
  },
}));

export default useStyles;
