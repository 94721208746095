import { Fragment, useContext, useEffect, useState } from "react";
import { Fade, Paper } from "@material-ui/core";
import { endoso } from "../../services";
import { AlertModal, SnackBar, Spinner } from "../../shared/components";
import ChartSection from "./sections/chartSection";
import FormSection from "./sections/formSection";
import TableSections from "./sections/tableSections";
import useStyles from "./styles";
import { AuthContext } from "../../context/context";

const DashBoard = () => {
  const classes = useStyles();
  const { token, firstLoad, setFirstLoad, setTimer } = useContext(AuthContext);
  const [endosos, setEndosos] = useState([]);
  const [ramos, setRamos] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({});
  const products = [
    {
      name: "Motor",
      value: endosos.filter((endoso) => endoso.producto === "AUTOS").length,
    },
    {
      name: "Personas",
      value: endosos.filter((endoso) => endoso.producto === "PERSONAS").length,
    },
    {
      name: "Daños",
      value: endosos.filter((endoso) => endoso.producto === "DANIOS").length,
    },
  ];
  const activities = [
    {
      name: "Pendientes",
      value: endosos.filter((solicitud) => solicitud.estatus === "W").length,
    },
    {
      name: "Terminadas",
      value: endosos.filter((solicitud) => solicitud.estatus === "M").length,
    },
  ];
  /* methods */
  const getData = async () => {
    const endososResponse = await endoso.list(null, token.cdUsuario);
    setEndosos(endososResponse.data?.solicitudes || []);
    const ramosResponse = await endoso.getRamo();
    setRamos(ramosResponse.data?.ramos || []);
    const oficinasResponse = await endoso.getOficce();
    setOficinas(oficinasResponse.data?.oficinas || []);
    setLoading(false);
  };

  const showAlert = () => {
    const numberOfPending = endosos.filter(
      (solicitud) => solicitud.estatus === "W"
    ).length;

    if (numberOfPending > 0 && firstLoad) return true;
    return false;
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line


  if (loading) {
    return <Spinner />;
  } else
    return (
      <Fragment>
        <div></div>
        <SnackBar
          HandleShow={setShowMessage}
          show={showMessage}
          severity={message.severity}
          text={message.text}
          time={2000}
          action={message.action}
        />
        <Fade in={!loading} timeout={600}>
          <div className={classes.root}>
            <Paper elevation={3} className={classes.sections}>
              <TableSections
                title="Actividades pendientes"
                setMessage={setMessage}
                setShowMessage={setShowMessage}
                rows={endosos.filter((solicitud) => solicitud.estatus === "W")}
                type={1}
              />
              <TableSections
                title="Actividades recientes"
                setMessage={setMessage}
                setShowMessage={setShowMessage}
                rows={endosos.filter(
                  (solicitud) =>
                    solicitud.estatus === "M" || solicitud.estatus === "C"
                )}
                type={1}
              />
            </Paper>
            <div className={classes.sections}>
              <div className={classes.subSectionRight}>
                <ChartSection products={products} activities={activities} />
              </div>
              <div className={classes.subSectionRight}>
                <FormSection
                  ramos={ramos}
                  oficinas={oficinas}
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                />
              </div>
            </div>
          </div>
        </Fade>
        <AlertModal
          open={showAlert()}
          close={() => {
            setFirstLoad(false);
          }}
          SuccessText={"Continuar"}
          message="Cuentas con endosos pendientes que deben ser confirmados, recuerda concluirlos o cancelarlos. De otro modo los procesos automáticos los estarían cancelando, debiendo iniciar nuevamente el endoso."
          title={"Atención"}
          success={() => {
            setTimer(0);
            setFirstLoad(false);
          }}
          type={2}
        />
      </Fragment>
    );
};

export default DashBoard;
