import { makeStyles } from "@material-ui/core";
import { colors } from "../../shared/utils/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "98vh",
    width: "100%",
    columnGap: "24px",
  },
  sections: {
    flex: 0.46,
    "&:last-child": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  },
  subSections: {
    flex: 1,
    boxSizing: "border-box",
    marginLeft: "8px",
    "&:first-child": {
      padding: "24px 0 8px 0",
    },
    padding: "8px 0 ",
    "&#forms": { flex: 7 },
  },
  title: { color: colors.blueDarken },
});

export default useStyles;
