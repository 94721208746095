import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    width: "400px",
    textAlign: "left",
    height: "35px",
    borderRadius: "18px",
    background: "none",
    border: `1px solid ${colors.greyLighten}`,
    cursor: "pointer",
  },
});

export default useStyles;
