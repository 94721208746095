import { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "./styles";

const InfoTable = ({ title, rows, type = 1 }) => {
  const classes = useStyles();
  const columns = [
    {
      field: "campo",
      headerName: "Campo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "valorOriginal",
      headerName: "Valor Original",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "valorNuevo",
      headerName: "Valor Nuevo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];

  const columsRisk = [
    {
      field: "campo",
      headerName: "Campo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "valorOriginal",
      headerName: "Valor Original",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "valorNuevo",
      headerName: "Valor Nuevo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "diferencia",
      headerName: "Diferencia",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
  /* methods */
  const handleOrderData = () => {
    return [
      {
        id: 1,
        campo: "Nombre",
        valorOriginal: "No disponible",
        valorNuevo: rows.nombre,
        diferencia: "No disponible",
      },
      {
        id: 2,
        campo: "Categoría",
        valorOriginal: "No disponible",
        valorNuevo:
          rows.categoria === 1 ? "ALUMNO/ASEGURADO" : "DOCENTE/ADMINISTRATIVO",
        diferencia: "No disponible",
      },
      {
        id: 3,
        campo: "Sexo",
        valorOriginal: "No disponible",
        valorNuevo: rows.sexo,
        diferencia: "No disponible",
      },
      {
        id: 4,
        campo: "Fecha de Nacimiento",
        valorOriginal: "No disponible",
        valorNuevo: rows.fechainicial,
        diferencia: "No disponible",
      },
      {
        id: 5,
        campo: "Importe",
        valorOriginal: rows.importeAnterior,
        valorNuevo: rows.importeActual,
        diferencia: rows.importeDiferencia,
      },
    ];
  };
  return (
    <Fragment>
      <div className={classes.headerComponent}>
        <Typography variant="h6" className={classes.title} align="left">
          <strong>{title}</strong>
        </Typography>
      </div>
      <DataGrid
        columns={type === 1 ? columns : columsRisk}
        rows={type === 1 ? rows : handleOrderData()}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[8]}
        pageSize={8}
        pagination
      />
    </Fragment>
  );
};

export default InfoTable;
