import { makeStyles } from "@material-ui/core";
import { colors } from "shared/utils";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1.3fr .8fr 1fr",
    maxWidth: '90%',
    gap: "30px",
  },
  label: {
    marginLeft: "16px",
    marginBottom: "6px",
    color: colors.blueDarken,
    fontSize: ".82rem",
  },
  input: { fontSize: ".52rem" },
  table: {
    borderRadius: "19px",
    overflow: "hidden",
    minWidth: "500px",
    minHeight: "280px",
    "& .MuiDataGrid-iconButtonContainer": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      pointerEvents: "none", 
      cursor: "default", 
    },
    "& .header": {
      background: colors.blueLighten,
      color: colors.white,
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-cell": {
      textAlign: "center",
    },
    "& .MuiDataGrid-columnsContainer": {
      background: colors.blueLighten,
      color: colors.white,
    },
  },
  submit: {
    borderRadius: "20px",
    minWidth: "120px",
    marginTop: "19px",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  containerTable: { minHeight: '250px', width: '100%', marginTop: '20px' },
  rowError: {
    background: "#FF000033",
    color: "#FF0000",
  }
});

export default useStyles;
