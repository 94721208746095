import { Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { colors } from "../../../../shared/utils";
import { CircleChart, ColorBox } from "../../../../shared/components";

const CircleChartSection = ({ data }) => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.sections}>
        <CircleChart
          data={data}
          palette={colors.efficentPallete}
          size={{ width: 145, height: 145, radius: 0.7 }}
        />
      </div>
      <div className={classes.sections}>
        <Typography variant="h5" className={classes.title}>
          Eficiencia
        </Typography>
        <div className={classes.subSections}>
          {data.map((item, index) => (
            <ColorBox
              color={colors.efficentPallete[index]}
              legend={item.name}
            />
          ))}
        </div>
      </div>
    </Paper>
  );
};

export default CircleChartSection;
