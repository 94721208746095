import React from "react";
import useStyles from "./styles";
import { Footer } from "shared/components";

const MainLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <div className={classes.content}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
