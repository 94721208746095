export const rulesByThirteen = async (
  data,
  setMessage,
  setShowMessage,
  formDta,
  endoso,
  setShowButtons,
  showButtons,
  persons,
  setPersons,
  history
) => {
  const saveEndoso = async () => {
    const siniestros = [];
    if (persons.length > 0) {
      const savePersons = persons.map(async (person) => {
        const body = {
          accion: null,
          codMensaje: null,
          idPersona: person,
          mensaje: null,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
        };
        const personaBaja = await endoso.situacionBaja(body);
        if (
          personaBaja.data?.codigo === 1 ||
          personaBaja.data?.codigo === null
        ) {
          return 1;
        } else {
          setMessage({
            text:
              personaBaja.data?.mensaje ||
              "OCURRIO UN PROBLEMA PROCESANDO SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
          siniestros.push(person);
          return 0;
        }
      });

      const responseSaves = await Promise.all(savePersons).then((id) => id);
      if (!responseSaves.filter((save) => save === 1).length) {
        console.log("error");
      } else {
        setPersons(persons.filter((person) => !siniestros.includes(person)));
        const clausulaResponse = await endoso.clausula({
          solicitud: formDta.noSolicitud,
        });
        if (clausulaResponse.data?.codigo === "1") {
          if (data.tipend === 13) {
            const checkBajas = await endoso.checkBaja605(formDta.noSolicitud);
            if (checkBajas.data?.codigo === "1") {
              setShowButtons({
                ...showButtons,
                multiTable: true,
                formTable: false,
              });
            } else {
              setMessage({
                text:
                  checkBajas.data?.mensaje ||
                  "OCURRIO UN PROBLEMA PROCESANDO SU SOLICITUD",
                severity: "warning",
                time: 4000,
              });
              setShowMessage(true);
              setPersons([]);
              setTimeout(() => history.push("/"), 4000);
            }
          } else
            setShowButtons({
              ...showButtons,
              multiTable: true,
              formTable: false,
            });
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA PROCESANDO SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
        }
      }
    } else {
      setMessage({
        text: "PARA CONTINUAR DEBES SELECCIONAR AL MENOS UNA PERSONA",
        severity: "warning",
      });
      setShowMessage(true);
    }
  };
  if (data.tipend === 15) {
    const numberOfPersons = persons.length;
    const asegurados = formDta?.cantidad;
    if (asegurados > 10 && asegurados - numberOfPersons >= 10) {
      await saveEndoso();
    } else {
      setMessage({
        text: "No es posible dar de baja si la póliza tiene menos de 10 asegurados",
        severity: "warning",
      });
      setShowMessage(true);
    }
  } else await saveEndoso();
};
