import { useContext } from "react";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { endoso } from "../../../../../../../services";
import { AuthContext } from "../../../../../../../context/context";
import { EndosoContext } from "../../../../../context";
import { colors } from "../../../../../../../shared/utils";
import useStyles from "./styles";

export const SearchBar = ({ setLoader }) => {
  const { setTimer } = useContext(AuthContext);
  const {
    setSearchWord,
    formDta,
    searchWord,
    bajaRiesgo15,
    setBajaRiesgo15,
    setMessage,
    selected,
  } = useContext(EndosoContext);
  const classes = useStyles();

  const searchPersons = (e) => {
    e.preventDefault();
    setTimer(0);
    setLoader(true);
    const body = {
      pagina: 1,
      solicitud: formDta.noSolicitud,
      nivel: formDta.nivel,
      clave: searchWord.toUpperCase() || null,
    };
    endoso.nivel902(body).then(({ data }) => {
      if (data) {
        const newRows = data.niveles.map((row) => ({
          ...row,
          checked: selected.some(({ codigo }) => codigo === row.codigo),
        }));
        setBajaRiesgo15({
          ...bajaRiesgo15,
          initialData: data,
          niveles: newRows,
          cantidad: data.cantidad,
        });
      } else {
        setMessage({
          text: "No se encontraron resultados",
          severity: "warning",
        });
      }
      setLoader(false);
    });
  };
  return (
    <form onSubmit={searchPersons}>
      <div className={classes.container}>
        <TextField
          className={classes.root}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search style={{ color: colors.blueDarken }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setTimer(0);
            setSearchWord(e.target.value);
          }}
        />
        <Button type="submit" color="primary" variant="contained">
          Buscar
        </Button>
      </div>
    </form>
  );
};
