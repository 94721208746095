import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { EndosoContext } from "pages/endoso/context";
import { useContext, useState, useEffect, Fragment } from "react";
import { endoso } from "services";
import { Spinner } from "shared/components";

export const InfoTable = ({ numeroSituacion }) => {
    const classes = useStyles();
    const { rows23, infoTable23 } = useContext(EndosoContext);
    const [diferencia, setDiferencia] = useState({});
    const [loading, setLoading] = useState(true);

    const filteredInfo = infoTable23.filter(infoRow =>
        rows23.some(row23 => infoRow.ubicacion === row23.situacion)
    );

    useEffect(() => {
        (async () => {
            const { data: resp, error} = await endoso.diferencia({ solicitud: numeroSituacion.noSolicitud })
            if (resp) setDiferencia(resp);
            if (error || resp) setLoading(false);
        }
        )();
    }, []); // eslint-disable-line

    const columns = [
        {
            field: "id",
            headerName: "Ubicación",
            flex: 0.3,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.id || ""}>
                    <span className={classes.tableCellTrucate}>
                        {row.id || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "cdpostal",
            headerName: "Código Postal",
            flex: 0.4,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.cdpostal || ""}>
                    <span className={classes.tableCellTrucate}>
                        {row.cdpostal || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "poblacion",
            headerName: "Población",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.poblacion || ""}>
                    <span className={classes.tableCellTrucate}>
                        {row.poblacion || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "calle",
            headerName: "Calle",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.calle || ""}>
                    <span className={classes.tableCellTrucate}>
                        {row.calle || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
    ];

    const rows = filteredInfo?.map((asegurado, index) => ({
        id: asegurado.ubicacion,
        cdpostal: asegurado.codigoPostal,
        poblacion: asegurado.poblacion,
        calle: asegurado.calle,
    }));

    const formatCurrency = (value) => {
        const cleanedValue = value.toString();

        if (cleanedValue.includes('.')) {
            const number = parseFloat(cleanedValue);
            return isNaN(number) ? '0.00' : number.toLocaleString('es-MX', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }

        const number = parseFloat(cleanedValue);

        return isNaN(number) ? '0.00' : number.toLocaleString('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    return (
        <Fragment>
            {loading ? <Spinner /> : (
                <div className={classes.root}>
                    <div className={classes.headerComponent}>
                        <Typography variant="h6" className={classes.title} align="left">
                            <strong>Baja de Ubicación</strong>
                        </Typography>
                    </div>
                    <DataGrid
                        rows={rows || []}
                        columns={columns}
                        disableSelectionOnClick
                        className={classes.root}
                        disableColumnMenu
                        rowsPerPageOptions={[4]}
                        pageSize={4}
                        pagination
                        components={{
                            NoRowsOverlay: () => (
                                <GridOverlay>No se encontró información</GridOverlay>
                            ),
                        }}
                    />
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography>Importe Actual: {'$' + formatCurrency(diferencia?.importeActual || 0)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Importe Anterior: {'$' + formatCurrency(diferencia?.importeAnterior || 0)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Diferencia: {'$' + formatCurrency(diferencia?.importeDiferencia || 0)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Fragment>
    );
};
