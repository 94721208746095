import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
  },
  sections: {
    flex: 0.46,
    "&:last-child": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  },
  label: {
    color: colors.blueDarken,
    marginLeft: "16px",
  },
  subSections: {
    flex: 1,
    "&#forms": { flex: 7 },
  },
  title: { color: colors.blueDarken },
});

export default useStyles;
