import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "24px",
    rowGap: "24px",
  },
  label: {
    marginLeft: "16px",
    marginBottom: "6px",
    color: colors.blueDarken,
    fontSize: ".82rem",
  },
  input: { fontSize: ".52rem" },
});

export default useStyles;
