import { useContext, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { CircleChart, TabGraphic } from "../../../../shared/components";
import { colors } from "../../../../shared/utils";
import useStyles from "./styles";
import { AuthContext } from "../../../../context/context";
const ChartSection = ({ products, activities }) => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  const [data, setData] = useState(products);
  const [palette, setPalette] = useState(colors.productsPalette);
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setTimer(0);
    setValue(newValue);
    if (newValue === 0) {
      setData(activities);
      setPalette(colors.activitiesPalette);
    } else {
      setData(products);
      setPalette(colors.productsPalette);
    }
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.sections}>
        <CircleChart
          data={data}
          palette={palette}
          size={{ width: 140, height: 140, radius: 0.7 }}
        />
      </div>
      <div className={classes.sections}>
        <div className={classes.tabContainer}>
          <TabGraphic value={value} handleChange={handleChange} />
        </div>

        <div className={classes.legendContainer}>
          {data.map((item, index) => (
            <div className={classes.legend} key={index}>
              <div id="colorBox" style={{ background: palette[index] }} />
              <Typography variant="body2">{item.name}</Typography>
            </div>
          ))}
        </div>
      </div>
    </Paper>
  );
};

export default ChartSection;
