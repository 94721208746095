export const colors = {
  blueLighten: "#00AEC7",
  blueSoft: "#e5f7f9",
  blueDarken: "#0033A0",
  greyLighten: "#8189A9",
  greyDarken: "#707070",
  disabled: "#00000029",
  disabledLighten: "#D8D8D8",
  disabledSoft: "#F9F9FB",
  white: "#ffffff",
  greyFont: "#53565A",
  yellow: "#e3e829",
  redSpecial: "#956E8E",
  greyBlocked: "#7E8083",
  whiteDark: "#f5f7f7",
  warning: "#FFCD00",

  productsPalette: ["#0033a0", "#E3E829", "#00FFD5"],
  activitiesPalette: ["#bbff00", "#00ffd5"],
  endososOverPallete: ["#e3e829", "#956E8E"],
  efficentPallete: ["#0033A0", "#00AEC7"],
};
