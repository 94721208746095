import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation, Palette } from "@devexpress/dx-react-chart";

const CircleChart = ({ data, palette, size = {} }) => {
  return (
    <div>
      <Chart data={data} width={size.width} height={size.height}>
        <Palette scheme={palette} />
        <PieSeries
          valueField="value"
          argumentField="name"
          innerRadius={size.radius}
        />
        <Animation />
      </Chart>
    </div>
  );
};

export default CircleChart;
