import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../../../shared/utils";

export const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paper": {
      padding: "16px 24px",
      minWidth: "55%",
      display: "flex",
      rowGap: "24px",
      flexDirection: "column",
    },
  },
  header: { flex: 0.1 },
  title: { color: colors.blueDarken },
  section: { flex: 0.8 },
  label: {
    marginLeft: "16px",
    marginBottom: "6px",
    color: colors.blueDarken,
    fontSize: ".82rem",
  },
  input: {
    fontSize: ".52rem",
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: colors.greyFont,
      },
    },
  },
  actions: { flex: 0.1, display: "flex", justifyContent: "flex-end" },
  button: { color: colors.blueDarken },
});
