import { Paper, Typography } from "@material-ui/core";
import { PictureAsPdf } from "@material-ui/icons";
import useStyles from "./styles";

const CardDocuments = ({ text }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <PictureAsPdf className={classes.icon} />
      <Typography variant="caption" color="initial" align="center">
        {text}
      </Typography>
    </Paper>
  );
};

export default CardDocuments;
