import { useContext, useMemo } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Modal } from "../../../../../../../shared/components";
import { AltaContext, EndosoContext } from "../../../../../context";
import { useStyles } from "./styles";
import { Input, Select, DatePicker } from "..";
import { useOptions, usePersonValidation } from "../../hooks";
import { AuthContext } from "../../../../../../../context/context";

export const ModalForm = () => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  const { createMessage } = useContext(EndosoContext);
  const {
    showModal,
    closeModal,
    currentTitular,
    handleChange,
    situacion,
    setFamiliares,
    familiares,
    titulares,
    setTitulares,
  } = useContext(AltaContext);
  const {
    sexOptions,
    relationOptions,
    agrupadoresOptions,
    categoriaOptions,
    reglaOptions,
    tReglaOptions,
    asociadoOptions,
  } = useOptions();
  const { isValid, text } = usePersonValidation();
  const showAsociados =
    situacion?.parentesco !== "T" || currentTitular ? true : false;
  /* methods */
  const disableButton = useMemo(() => {
    const fields = [
      "primerNombre",
      "apPaterno",
      "sexo",
      "fchNacimineto",
      "parentesco",
      "agrupador",
      "categoria",
      "regla",
      "tregla",
      "antiguedad",
      ...(Boolean(situacion?.parentesco !== "T") ? ["parentId"] : []),
    ];
    if (situacion) {
      const emptlyFields = fields.map((field) =>
        !situacion[field] ? true : false
      );
      return emptlyFields.find((field) => field) || false;
    } else return true;
  }, [situacion]);
  const saveTitular = () => {
    const { primerNombre, segundoNombre, apPaterno, apMaterno } = situacion;

    const titular = {
      ...situacion,
      id: titulares.length + 1,
      familiares: 0,
      nombre: `${primerNombre} ${segundoNombre ?? ""} ${apPaterno} ${
        apMaterno ?? ""
      }`,
    };
    setTitulares([...titulares, titular]);
    createMessage({
      text: "Se creo el titular de manera exitosa",
      variant: "success",
    });
  };
  const saveFamiliar = () => {
    const { primerNombre, segundoNombre, apPaterno, apMaterno } = situacion;
    const updateTitular = titulares.map((titular) =>
      titular.id === situacion.parentId
        ? { ...titular, familiares: titular.familiares + 1 }
        : titular
    );
    setFamiliares([
      ...familiares,
      {
        ...situacion,
        nombre: `${primerNombre} ${segundoNombre ?? ""} ${apPaterno} ${
          apMaterno ?? ""
        }`,
        id: familiares.length + 1,
      },
    ]);
    setTitulares(updateTitular);
    createMessage({
      text: "Se creo el asociado de manera exitosa",
      variant: "success",
    });
  };
  const saveSituacion = () => {
    setTimer(0);
    if (isValid) {
      if (situacion.parentesco !== "T") saveFamiliar();
      else saveTitular();
      closeModal();
    } else createMessage({ text: text, variant: "warning" });
  };
  return (
    <Modal open={showModal} close={closeModal} className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Agregar nuevo asegurado
        </Typography>
      </Box>
      <Grid container className={classes.section} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.title}>
            Datos Personales
          </Typography>
        </Grid>
        <Input
          name="primerNombre"
          label="Primer Nombre"
          required
          onChange={handleChange}
          onlyLetters
        />
        <Input
          name="segundoNombre"
          label="Segundo Nombre"
          onChange={handleChange}
          onlyLetters
        />
        <Input
          name="apPaterno"
          label="Apellido Paterno"
          required
          onChange={handleChange}
          onlyLetters
        />
        <Input
          name="apMaterno"
          label="Apellido Materno"
          onChange={handleChange}
          onlyLetters
        />

        <Select
          name="sexo"
          label="Sexo"
          required
          optionsProps={{ options: sexOptions }}
          onChange={handleChange}
        />
        <DatePicker
          name="fchNacimineto"
          label="Fecha de Nacimiento"
          value={situacion?.fchNacimineto}
          onChange={handleChange}
          required
        />
        <Select
          name="parentesco"
          label="Parentesco"
          required
          optionsProps={{ options: relationOptions }}
          onChange={handleChange}
        />

        {showAsociados && (
          <Select
            name="parentId"
            label="Titular Asociado"
            required
            defaultValue={currentTitular?.id}
            disabled={Boolean(currentTitular)}
            onChange={handleChange}
            optionsProps={{
              options: asociadoOptions,
              value: "id",
              text: "nombre",
            }}
          />
        )}

        <Grid item xs={12}>
          <Typography variant="body1" className={classes.title}>
            Datos De Póliza
          </Typography>
        </Grid>
        <Select
          name="agrupador"
          label="Agrupador"
          optionsProps={{ options: agrupadoresOptions }}
          required
          onChange={handleChange}
        />
        <Select
          name="categoria"
          label="Categoría"
          required
          disabled={Boolean(situacion?.categoria)}
          optionsProps={{ options: categoriaOptions }}
          onChange={handleChange}
        />
        <Select
          name="regla"
          label="Regla"
          required
          optionsProps={{ options: reglaOptions }}
          onChange={handleChange}
        />
        <Select
          name="tregla"
          label="Tipo de Regla"
          required
          optionsProps={{ options: tReglaOptions }}
          onChange={handleChange}
        />
      </Grid>
      <Box className={classes.actions}>
        <Button variant="text" className={classes.button} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          variant="text"
          className={classes.button}
          disabled={disableButton}
          onClick={saveSituacion}
        >
          Guardar
        </Button>
      </Box>
    </Modal>
  );
};
