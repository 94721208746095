import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils";
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    background: colors.disabledSoft,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "16px",
    rowGap: "16px",
    maxHeight: "400px",
    overflow: "auto",
    position: "relative",
  },
  sections: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "24px",
    rowGap: "32px",
    "&:last-child": { flex: 0.1, display: "flex", justifyContent: "flex-end" },
  },
  submit: {
    borderRadius: "19px",
  },
  personsContainer: {
    marginTop: "24px",
  },
  personsGrid: {
    marginTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "16px",
    rowGap: "16px",
  },
  person: {
    background: colors.blueLighten,
    borderRadius: "19px",
    color: colors.white,
    padding: "8px",
    "&:hover": { cursor: "pointer" },
  },
});

export default useStyles;
