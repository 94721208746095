import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    width: "100%",
  },
  form: {
    marginTop: "16px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    rowGap: "32px",
    columnGap: "24px",
  },

  label: {
    marginLeft: "16px",
    marginBottom: "6px",
    color: colors.blueDarken,
    fontSize: ".82rem",
  },
  input: { fontSize: ".52rem" },
  submit: {
    borderRadius: "19px",
  },
});

export default useStyles;
