import { Fragment, useContext } from "react";
import { Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "./styles";
import { AuthContext } from "../../../../../context/context";
import { EndosoContext } from "../../../context";

const InfoTableForRisk = ({ title, rows, type = 1 }) => {
  const classes = useStyles();
  const { persons } = useContext(AuthContext);
  const { selected, formDta } = useContext(EndosoContext);
  const columns = [
    {
      field: "codigo",
      headerName: rows.titulo1 || "Número",
      flex: 0.4,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "descripcion",
      headerName: rows.titulo2 || "Nombre",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
  /* methods */
  const filterRows = () => {
    if (formDta?.tipoEndoso === 15)
      return selected.filter((row) => persons?.includes(row.codigo));
    return rows.niveles?.filter((row) => persons?.includes(row.codigo));
  };

  return (
    <Fragment>
      <div className={classes.headerComponent}>
        <Typography variant="h6" className={classes.title} align="left">
          <strong>{title}</strong>
        </Typography>
      </div>
      <DataGrid
        columns={columns}
        rows={filterRows()}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[8]}
        pageSize={8}
        pagination
        getRowId={(row) => row.codigo}
      />
    </Fragment>
  );
};

export default InfoTableForRisk;
