import { Grid, Typography } from "@material-ui/core";
import { useContext } from "react";
import { EndosoContext } from "../../../../../context";
import { useStyles } from "./style";
const findItem = (key, array, element) => {
  return array.find((item) => item[key] === element);
};

export const CargaBInfo = () => {
  const classes = useStyles();
  const { cargaBData, cargaB } = useContext(EndosoContext);
  const { camiones = [], tgcargab = [] } = cargaB;
  const camion = findItem("nmsituac", camiones, cargaBData.situacion);
  const mercancia = findItem("value", tgcargab, cargaBData.mercancia);

  return (
    <div>
      <Typography
        variant="h6"
        color="initial"
        style={{ margin: "16px 0 26px 0" }}
      >
        Resumen modificacion de la carga B en situacion
      </Typography>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={3} className={classes.description}>
          <Typography variant="body2" color="initial" style={{ margin: "8px" }}>
            Descripcion del Vehiculo:
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.information}>
          <Typography variant="body2" color="initial" style={{ margin: "8px" }}>
            {camion.descVehiculo}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.description}>
          <Typography variant="body2" color="initial" style={{ margin: "8px" }}>
            Modelo del Vehiculo:
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.information}>
          <Typography variant="body2" color="initial" style={{ margin: "8px" }}>
            {camion.modelo}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.description}>
          <Typography variant="body2" color="initial" style={{ margin: "8px" }}>
            Tipo de Mercancia:
          </Typography>
        </Grid>
        <Grid item xs={9} className={classes.information}>
          <Typography variant="body2" color="initial" style={{ margin: "8px" }}>
            {mercancia.text}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
