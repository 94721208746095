import { ContextProvider } from "./context/context";
import Routes from "./router/routes";
import theme from "./styles/theme";
import { ThemeProvider } from "@material-ui/styles";
import MainLayout from "layout";

function App() {
  return (
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <MainLayout>
          <Routes />
        </MainLayout>
      </ThemeProvider>
    </ContextProvider>
  );
}

export default App;
