import { EndosoContext } from "pages/endoso/context";
import { useContext, useState, useEffect } from "react";
import { endoso } from "services";


export const useSteps = (props) => {
    const [cp, setCp] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [coloniaOptions, setColoniaOptions] = useState([]);
    const [selectedColonia, setSelectedColonia] = useState('');
    const [calle, setCalle] = useState('');
    const [numero, setNumero] = useState('');
    const [sigla, setSigla] = useState('');
    const [piso, setPiso] = useState('');
    const [agrupador, setAgrupador] = useState('');
    const { createMessage, setNumeroSituacion } = useContext(EndosoContext);
    const [disabledButton, setDisabledButton] = useState(true);
    const [agrupadorOptions, setAgrupadorOptions] = useState([]);
    const [siglaOptions, setSiglaOptions] = useState([]);

    useEffect(() => {
        (async () => {
            const resp = await endoso.configType22(props.noSolicitud);
            setAgrupadorOptions(resp.data.agrupadores);
        })();
    }, [props.noSolicitud]);



    const handleSubmitCP = async () => {
        setLoading(true);
        const response = await endoso.validateCP(cp);
        if (response.data?.codigo === 1) {
            const cleanSiglaOptions = response.data.siglas.map((sigla) =>
                sigla.codigo === 'NE' ? { ...sigla, descripcion: 'NO EXISTENTE' } : sigla);
            setSiglaOptions(cleanSiglaOptions);
            if (response.data.colonias.length >= 1) {
                setColoniaOptions(response.data.colonias);
                setStep(2);
            } else {
                createMessage({
                    text: 'No se encontraron colonias asociadas a este código postal',
                    variant: 'warning',
                });
            }
        } else {
            createMessage({
                text: response.data?.mensaje || 'Código postal no encontrado',
                variant: 'warning',
            });
        }
        if (response.error) {
            createMessage({
                text: 'Ocurrió un error al validar el código postal',
                variant: 'warning',
            });
        }
        setLoading(false);
    };

    const handleSubmitColonia = async () => {
        setLoading(true);
        const postData = {
            colonia: selectedColonia,
            calle,
            numero,
            ramo: props.data.ramo,
            oficina: props.data.oficina,
            poliza: props.data.poliza,
        };
        const response = await endoso.validateColonia(postData);
        if (response.data?.codigo === 1) {
            setStep(3);
        } else {
            createMessage({
                text: response.data?.mensaje || 'Colonia no encontrada',
                variant: 'warning',
            });
        }
        if (response.error) {
            createMessage({
                text: 'Ocurrió un error al validar la colonia',
                variant: 'warning',
            });
        }
        setLoading(false);
    };

    const handleFinalSubmit = async () => {
        setLoading(true);
        const finalData = {
            solicitud: parseInt(props.noSolicitud),
            calle,
            siglas: sigla,
            codigoPostal: cp,
            piso,
            numero,
            colonia: selectedColonia,
            cdAgrupador: agrupador,
        };
        const response = await endoso.altaSit420_430(finalData);
        if (response.data?.codigo === 1) {
            createMessage({
                text: 'Datos enviados correctamente',
                variant: 'success',
            });
            props.setFinish(true);
            setNumeroSituacion(response.data.numeroSituacion);
        } else {
            createMessage({
                text: response.data?.mensaje || 'Ocurrió un error al enviar los datos finales',
                variant: 'warning',
            });
        }
        setLoading(false);
    };

    const cpValidate = (e) => {
        const value = e.target.value;
        if (value.length <= 5 && /^[0-9]*$/.test(value)) {
            setCp(value);
        }
    };

    const inputValidate = (e, set, max, special, onlySpecialDash) => {
        let value = e.target.value;
        const generalRegex = special ? /.*/ : /^[a-zA-Z0-9\s-]*$/;
        const dashRegex = /^[a-zA-Z0-9\s-]*$/;
        const noSpaceOrDashAtStart = !/^[\s-]/.test(value);
        const noMoreThanTwoSpaces = !/\s{2,}/.test(value);
        const noMoreThanTwoDashes = !/-{2,}/.test(value);
        const noDashSpaceDash = !/-\s-/.test(value);
        if (!onlySpecialDash) value = value.replace(/-/g, '');
        if (value.length <= max && generalRegex.test(value) && (!onlySpecialDash || dashRegex.test(value)) && noSpaceOrDashAtStart && noMoreThanTwoSpaces && noMoreThanTwoDashes && noDashSpaceDash) {
            set(value);
        }
    };


    const validateButton = () => {
        if (step === 1 && cp.length === 5) {
            setDisabledButton(false);
        } else if (step === 2 && selectedColonia && calle.length >= 3 && numero) {
            setDisabledButton(false);
        } else if (step === 3 && sigla && agrupador) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }

    useEffect(() => {
        validateButton();
    }, [cp, selectedColonia, calle, numero, sigla, agrupador, step]); // eslint-disable-line

    return {
        cp,
        cpValidate,
        handleSubmitCP,
        step,
        setStep,
        coloniaOptions,
        selectedColonia,
        setSelectedColonia,
        handleSubmitColonia,
        calle,
        setCalle,
        numero,
        setNumero,
        sigla,
        setSigla,
        piso,
        setPiso,
        agrupador,
        setAgrupador,
        siglaOptions,
        agrupadorOptions,
        handleFinalSubmit,
        loading,
        disabledButton,
        validateButton,
        inputValidate,
    };
}