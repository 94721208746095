import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "95vh",
    margin: "0 30px",
  },
  content: {
    flex: "1 0 auto",
  },
  footer: {
    flexShrink: 0,
    width: "100%",
    marginTop: "16px",
  },
});

export default useStyles;
