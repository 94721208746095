import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils/colors";

const useStyles = makeStyles({
  root: {
    background: colors.disabledSoft,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    boxSizing: "border-box",
    padding: "24px",
  },
  title: {
    color: colors.blueDarken,
    width: "100%",
    marginBottom: "24px",
  },
  sections: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    flex: 0.7,
    "&:last-child": {
      flex: 0.3,
      justifyContent: "flex-end",
    },
  },
  label: {
    marginLeft: "16px",
    marginBottom: "4px",
    color: colors.blueDarken,
  },
  submit: {
    padding: "8px 0px",
    color: colors.blueDarken,
    background: colors.yellow,
    borderRadius: "19px",
    "&:hover": {
      background: colors.yellow,
    },
  },
});

export default useStyles;
