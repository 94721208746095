import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "93%",
    background: colors.disabledSoft,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "16px",
    rowGap: "24px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      minHeight: "400px",
    },
  },
  titleButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "16px",
    padding: 0,
    textTransform: "none",
    "&:hover": { background: "none" },
  },
  colorBlue: { color: colors.blueDarken },
  colorGrey: { color: colors.greyDarken },
  caption: {
    marginLeft: "48px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "48%",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30%",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      columnGap: "16px",
    },
  },
  label: {
    marginLeft: "16px",
    marginBottom: "8px",
    color: colors.blueDarken,
  },
  section: {
    "&:last-child": {
      position: "absolute",
      width: "90%",
      margin: "0 auto",
      bottom: 10,
    },
  },
  submitBottom: {
    color: colors.blueDarken,
    background: colors.yellow,
    borderRadius: "19px",
    "&:hover": {
      background: colors.yellow,
    },
  },
}));

export default useStyles;
