import React, { useState, useEffect, useContext } from "react";
import { TextField, Button, MenuItem, IconButton, InputLabel } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { endoso } from "services";
import useStyles from "./styles";
import { Delete } from "@material-ui/icons";
import { colors } from "shared/utils";
import { EndosoContext } from "pages/endoso/context";

export const FormTable = ({ idSolicitud }) => {
    const [coberturas, setCoberturas] = useState([]);
    const [selectedGarantia, setSelectedGarantia] = useState("");
    const [sumaAsegurada, setSumaAsegurada] = useState("");
    const [editId, setEditId] = useState(null);
    const { setDataR22, createMessage, dataR22, listErrors22, setListErrors22, numeroSituacion } = useContext(EndosoContext);
    const [tableData, setTableData] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const classes = useStyles();

    const formatCurrency = (value) => {
        const cleanedValue = value.toString().replace(/\D/g, '');
        const number = parseFloat(cleanedValue) / 100;
        if (isNaN(number)) return '0.00';
        return number.toLocaleString('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const formatRows = dataR22.accumulatedData?.map((row) => {
        return {
            id: row.id,
            dsGarantia: row.dsGarantia,
            cdGarantia: row.cdGarantia,
            cdCapita: row.cdCapita,
            sumaAsegurada: typeof row.sumaAsegurada === 'string' ? row.sumaAsegurada : (row.sumaAsegurada / 100).toLocaleString('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
        };
    });

    useEffect(() => {
        (async () => {
            if (dataR22?.accumulatedData?.length > 0) {
                setTableData(formatRows);
                setLoadData(true);
            }
            if (idSolicitud === null) return;
            const response = await endoso.coberturas(idSolicitud);
            if (response.data?.codigo === 1) {
                setCoberturas(response.data.coberturas?.map(item => ({
                    cdGarantia: item.cdGarantia,
                    dsGarantia: item.dsGarantia,
                    cdCapita: item.cdCapita,
                }))
                );
            } else {
                createMessage({
                    text: response?.data?.mensaje || "Ocurrió un error al cargar las coberturas",
                    variant: "warning",
                });
            }
        })();
    }, []); // eslint-disable-line

    const handleAdd = async (e) => {
        e.preventDefault();

        const exists = tableData.some(item => item.cdGarantia === selectedGarantia);
        if (exists && editId === null) {
            createMessage({
                text: "No puede agregar la misma cobertura más de una vez.",
                variant: "warning",
            });
            return;
        }
        if (selectedGarantia === "4301") {
            const cobertura1030 = tableData.find(item => item.cdGarantia === "1030");
            if (!cobertura1030) {
                createMessage({
                    text: "No puede agregar la cobertura ROBO CON VIOLENCIA Y ASALTO sin seleccionar la cobertura INCENDIO Y/O RAYO CONTENIDO.",
                    variant: "warning",
                });
                return;
            }
            const sumaAsegurada1030 = parseFloat(cobertura1030.sumaAsegurada.replace(/\D/g, '')) / 100;
            const sumaAsegurada4301 = parseFloat(sumaAsegurada.replace(/\D/g, '')) / 100;
            if (sumaAsegurada4301 > sumaAsegurada1030 * 0.5) {
                createMessage({
                    text: "La suma asegurada para la cobertura ROBO CON VIOLENCIA Y ASALTO no puede superar el 50% de la suma asegurada para la cobertura INCENDIO Y/O RAYO CONTENIDO.",
                    variant: "warning",
                });
                return;
            }
        }
        if (selectedGarantia === "1030") {
            const cobertura4301 = tableData.find(item => item.cdGarantia === "4301");
            if (cobertura4301) {
                const sumaAsegurada1030 = parseFloat(sumaAsegurada.replace(/\D/g, '')) / 100;
                const sumaAsegurada4301 = parseFloat(cobertura4301.sumaAsegurada.replace(/\D/g, '')) / 100;
                if (sumaAsegurada1030 < sumaAsegurada4301 * 2) {
                    createMessage({
                        text: "La suma asegurada para la cobertura INCENDIO Y/O RAYO CONTENIDO debe ser al menos el doble de la suma asegurada para la cobertura ROBO CON VIOLENCIA Y ASALTO.",
                        variant: "warning",
                    });
                    return;
                }
            }
        }
        const newEntry = {
            id: editId !== null ? editId : new Date().getTime(),
            cdGarantia: selectedGarantia,
            dsGarantia: coberturas.find(item => item.cdGarantia === selectedGarantia).dsGarantia,
            cdCapita: coberturas.find(item => item.cdGarantia === selectedGarantia).cdCapita,
            sumaAsegurada: sumaAsegurada || "$0.00",
        };
        const newData = [...tableData];
        if (editId !== null) {
            const index = newData.findIndex(item => item.id === editId);
            newData[index] = newEntry;
            setEditId(null);
        } else {
            newData.push(newEntry);
        }
        setTableData(newData);
        setSelectedGarantia("");
        setSumaAsegurada("");
    };

    const handleEdit = (id) => {
        const itemToEdit = tableData.find(item => item.id === id);
        if (itemToEdit) {
            setListErrors22(listErrors22.filter(errorCode => errorCode !== itemToEdit.cdGarantia));
            setSelectedGarantia(itemToEdit.cdGarantia);
            setSumaAsegurada(itemToEdit.sumaAsegurada.toString());
            setEditId(id);
        }
    };

    const handleDelete = (id) => {
        const itemToDelete = tableData.find(item => item.id === id);
        if (itemToDelete.cdGarantia === "1030") {
            const cobertura4301 = tableData.find(item => item.cdGarantia === "4301");
            if (cobertura4301) {
                createMessage({
                    text: "No se puede eliminar la cobertura INCENDIO Y/O RAYO CONTENIDO sin eliminar primero la cobertura ROBO CON VIOLENCIA Y ASALTO.",
                    variant: "warning",
                });
                return;
            }
        }
        setListErrors22(listErrors22.filter(errorCode => errorCode !== itemToDelete.cdGarantia));
        setTableData(tableData.filter(item => item.id !== id));
    };

    const handleConfirm = () => {
        const accumulatedData = tableData.map(item => ({
            idSolicitud: parseInt(idSolicitud),
            sumaAsegurada: loadData ? item.sumaAsegurada.toLocaleString('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) : Number(item.sumaAsegurada.replace(/\D/g, '')),
            cdGarantia: item.cdGarantia,
            cdCapita: item.cdCapita,
            dsGarantia: item.dsGarantia,
            numeroSituacion,
            id: item.id,
        }));
        setDataR22(dataR22 => ({
            ...dataR22,
            accumulatedData,
        }));
    };

    useEffect(() => {
        handleConfirm();
    }, [tableData]); // eslint-disable-line

    const handleSumaAseguradaChange = (e) => {
        if (e.target.value.length > 23) return;
        const formattedValue = formatCurrency(e.target.value);
        setSumaAsegurada('$' + formattedValue);
    };

    const columns = [
        { field: 'dsGarantia', headerName: 'Garantía', flex: 2, headerAlign: "center" },
        { field: 'sumaAsegurada', headerName: 'Suma Asegurada', flex: 1, headerAlign: "center" },
        {
            field: 'edit', headerName: 'Acciones', flex: 1, headerAlign: "center",
            renderCell: (params) => (
                <div className={classes.icon}>
                    <IconButton onClick={() => handleEdit(params.row.id)}>
                        <EditIcon style={{ color: colors.blueLighten }} />
                    </IconButton>
                    <IconButton
                        disabled={editId !== null}
                        onClick={() => handleDelete(params.row.id)}>
                        <Delete style={{ color: editId !== null ? "" : "#FF000088" }} />
                    </IconButton>
                </div>
            ),
        },
    ];    

    return (
        <div>
            <form className={classes.root} onSubmit={handleAdd}>
                <div>
                    <InputLabel htmlFor={"garantia"}>Garantía (*)</InputLabel>
                    <TextField
                        select
                        value={selectedGarantia}
                        onChange={(e) => setSelectedGarantia(e.target.value)}
                        fullWidth
                        disabled={editId !== null}
                    >
                        {coberturas.map((option) => (
                            <MenuItem key={option.cdGarantia} value={option.cdGarantia}>
                                {option.dsGarantia}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <InputLabel htmlFor={"sumaAsegurada"}>Suma Asegurada (*)</InputLabel>
                    <TextField
                        value={sumaAsegurada}
                        onChange={handleSumaAseguradaChange}
                        fullWidth
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 250 }}
                    />
                </div>
                <div>
                    <Button type="submit" className={classes.submit} variant="contained" color="primary"
                        disabled={!selectedGarantia || !sumaAsegurada}>
                        {editId !== null ? "Editar" : "Agregar"}
                    </Button>
                </div>
            </form>
            <div className={classes.containerTable}>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    className={classes.table}
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    hideFooter
                    getRowClassName={(params) => {
                        return listErrors22.map(item => {
                            if (item === params.row.cdGarantia) {
                                return classes.rowError
                            }
                            return null;
                        })
                    }}
                    components={{
                        NoRowsOverlay: () => (
                            <GridOverlay>No se han agregado coberturas</GridOverlay>
                        ),
                    }}
                />
            </div>
        </div>
    );
};
