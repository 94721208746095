import {
  validarCambioNombre,
  contarDiferencias,
  generarInfoTabla,
  getTypePerson,
} from "../";

const validateRfc = (inputs, value) => {
  const regex =
    /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;
  const person = getTypePerson(inputs);
  if (person === "MORAL" && (value.length < 9 || !Boolean(value.match(regex))))
    return true;
  else if (
    person !== "MORAL" &&
    (value.length < 10 || !Boolean(value.match(regex)))
  )
    return true;
  else return false;
};

export const rulesByOneTwoThree = async (
  data,
  oldData,
  dataSend,
  setMessage,
  setShowMessage,
  direction,
  formDta,
  endoso,
  setInfoTable,
  setShowButtons,
  showButtons
) => {
  if (
    !validarCambioNombre(oldData.DSAPEL1, dataSend.DSAPEL1, -1) &&
    dataSend.TIPPER === "1"
  ) {
    setMessage({
      text: "La modificacion del Apellido Paterno afecta al RFC",
      severity: "warning",
    });
    setShowMessage(true);
  } else if (
    !validarCambioNombre(oldData.DSAPEL2, dataSend.DSAPEL2, 0) &&
    dataSend.TIPPER === "1"
  ) {
    setMessage({
      text: "La modificacion del Apellido Materno afecta al RFC",
      severity: "warning",
    });
    setShowMessage(true);
  } else if (
    !validarCambioNombre(oldData.NOMBR1, dataSend.NOMBR1, 0) &&
    dataSend.TIPPER === "1"
  ) {
    setMessage({
      text: "No se puede modificar la primer letra del nombre",
      severity: "warning",
    });
    setShowMessage(true);
  } else if (
    data.tipend !== 3 &&
    !validarCambioNombre(oldData.RFC, dataSend.RFC, dataSend.RFC.length - 4)
  ) {
    setMessage({
      text: "Solo puede modificar los 3 ultimos digitos del RFC",
      severity: "warning",
    });
    setShowMessage(true);
  } else if (
    contarDiferencias(oldData.NOMBRE, dataSend.NOMBRE) > 10 &&
    dataSend.TIPPER === "2"
  ) {
    setMessage({
      text: "No se pueden modificar más de 10 caracteres en la razón social",
      severity: "warning",
    });
    setShowMessage(true);
  } else if (
    data.tipend === 3 &&
    validateRfc(formDta.datosEndoso, dataSend.RFC)
  ) {
    setMessage({
      text: "El RFC no cumple con los parametros minimos para ser modificado",
      severity: "warning",
    });
    setShowMessage(true);
  } else {
    const idPersona = formDta.idPersona;
    const homoClave =
      dataSend.TIPPER === "2" ? dataSend.RFC.slice(9) : dataSend.RFC.slice(10);
    const body = {
      idPersona: data.tipend === 6 ? null : parseInt(idPersona),
      tipoIdentificador: data.tipend === 12 ? "0" : "1",
      primerNombre: dataSend.TIPPER === "1" ? dataSend.NOMBR1 : null,
      segundoNombre: dataSend.TIPPER === "1" ? dataSend.NOMBR2 : null,
      nombre:
        dataSend.TIPPER === "2"
          ? dataSend.NOMBRE
          : `${dataSend.NOMBR1} ${dataSend.NOMBR2 || ""} ${dataSend.DSAPEL1} ${
              dataSend.DSAPEL2
            }`,
      apPaterno: dataSend.TIPPER === "1" ? dataSend.DSAPEL1 : null,
      apMaterno: dataSend.TIPPER === "1" ? dataSend.DSAPEL2 : null,
      fchNacimineto: dataSend.FECNAC,
      rfc: dataSend.RFC,
      homoClave: homoClave,
      razonSocial:
        dataSend.TIPPER === "1"
          ? "FISICA"
          : dataSend.TIPPER === "2"
          ? "MORAL"
          : "EMPRESA",
      accion: data.tipend === 6 ? "I" : "U",
      fechaNacimiento: false,
      sexo:
        dataSend.TIPPER === "2"
          ? "E"
          : dataSend.SEXO === "M"
          ? "MUJER"
          : "HOMBRE",
    };
    /* si se agrego un domicilio */
    let haveDirection = false;
    if (direction && direction.calle) {
      direction.idPersona = data.tipend === 6 ? null : parseInt(idPersona);
      haveDirection = true;
    }

    /* Enviar la data */
    const submitOneResponse = await endoso.savePerson(body);
    const directionResponse = haveDirection
      ? await endoso.guardarDomicilio({
          ...direction,
          numeroExterior: direction.numeroExterior || dataSend.NUMERO,
          numeroInterior: direction.numeroInterior || dataSend.OTPISO,
          ordenDomicilio: null,
          pais: "MEXICO",
          telefono: dataSend.TELEFO || null,
          tipoDomicilio: dataSend.IDDOMI || null,
        })
      : { data: { codigo: 1 } };

    if (
      submitOneResponse.data?.codigo === 1 &&
      directionResponse.data?.codigo === 1
    ) {
      const requestTwo = {
        nivel: formDta.nivel,
        orden: haveDirection ? 17 : 16,
        solicitud: formDta.noSolicitud,
        llave: formDta.llave,
        valor: haveDirection
          ? directionResponse.data?.idDomicilio
          : "NO GUARDAR",
      };
      const submitTwoResponse = await endoso.saveEndoso(requestTwo);
      if (submitTwoResponse.data?.codigo === 1) {
        /* endoso 2 */
        if (data.tipend === 2 && dataSend.TIPPER !== "2") {
          const bodyCambia = {
            cdperson: parseInt(idPersona),
            solicitud: formDta.noSolicitud,
            sexo: dataSend.SEXO,
            accion: "U", //siempre va a ser U
          };
          await endoso.cambioSexo(bodyCambia);
          await endoso.guardarSituacion({
            solicitud: formDta.noSolicitud,
            situacion: formDta.llave,
          });
          await endoso.diferencia({ solicitud: formDta.noSolicitud });
        }
        setInfoTable(generarInfoTabla(oldData, dataSend, data.tipend));
        setShowButtons({
          ...showButtons,
          infoTable: true,
          domicilio: false,
        });
      } else {
        setMessage({
          text: "SU SOLICITUD NO HA PODIDO SER PROCESADA",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      setMessage({
        text: "SU SOLICITUD NO HA PODIDO SER PROCESADA",
        severity: "warning",
      });
      setShowMessage(true);
    }
  }
};
