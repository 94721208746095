import { generarInfoTabla } from "../";
export const rulesByNineTenEleven = async (
  data,
  oldData,
  dataSend,
  setMessage,
  setShowMessage,
  formDta,
  endoso,
  setInfoTable,
  setShowButtons,
  showButtons
) => {
  const body = {
    llave: formDta.levelCode,
    nivel: formDta.nivel,
    orden:
      data.tipend === 9
        ? 4
        : data.tipend === 10
        ? 3
        : data.tipend === 18
        ? 6
        : data.tipend === 19
        ? 2
        : 5,
    solicitud: formDta.noSolicitud,
    valor:
      data.tipend === 9
        ? dataSend.SERIE
        : data.tipend === 10
        ? dataSend.PLACAS
        : data.tipend === 18
        ? dataSend.DESCRIPC
        : data.tipend === 19
        ? dataSend.MODELO
        : dataSend.MOTOR,
  };
  const currentYear = new Date().getFullYear();
  const createMessage = (text) => {
    setShowMessage(true);
    setMessage({
      text: text,
      severity: "warning",
    });
  };
  const submitData = async () => {
    const guardaResponse = await endoso.saveEndoso(body);
    if (guardaResponse.data?.codigo === 1) {
      await endoso.guardarSituacion({
        solicitud: formDta.noSolicitud,
        situacion: formDta.llave,
      });
      await endoso.diferencia({ solicitud: formDta.noSolicitud });
      await endoso.clausula({ solicitud: formDta.noSolicitud });
      setInfoTable(generarInfoTabla(oldData, dataSend, data.tipend));
      setShowButtons({ ...showButtons, infoTable: true, domicilio: false });
    } else {
      createMessage(
        guardaResponse.data?.mensaje &&
          guardaResponse.data?.mensaje.length < 100
          ? guardaResponse.data?.mensaje
          : "SU SOLICITUD NO HA PODIDO SER PROCESADA"
      );
    }
  };
  if (data.tipend !== 19 && data.tipend !== 9) {
    await submitData();
  } else if (data.tipend === 9) {
    const rules = validateSerieNumber(dataSend.SERIE, oldData.SERIE);
    if (!rules.error) await submitData();
    else createMessage(rules.mensaje);
  } else {
    if (dataSend.MODELO <= currentYear && dataSend.MODELO >= currentYear - 15)
      await submitData();
    else
      createMessage(
        `El año del modelo debe estar entre el ${
          currentYear - 15
        } y ${currentYear}`
      );
  }
};

function validateSerieNumber(number, oldNumber) {
  const newSerie = number.split("");
  const oldSerie = oldNumber.split("");
  if (getChangesIndex(newSerie, oldSerie).length > 3) {
    return {
      error: true,
      mensaje:
        "Solo se pueden modificar 3 digitos y no pueden ser consecutivos",
    };
  } else {
    if (hasConsecutiveChanges(getChangesIndex(newSerie, oldSerie)))
      return {
        error: true,
        mensaje:
          "Solo se pueden modificar 3 digitos y no pueden ser consecutivos",
      };
    else return { error: false };
  }
}

function getChangesIndex(number, oldNumber) {
  const array = number.map((item, index) => {
    if (index < oldNumber.length) {
      return item === oldNumber[index] ? undefined : index;
    } else {
      return undefined;
    }
  });
  return array.filter((item) => item !== null && item !== undefined);
}

function hasConsecutiveChanges(changes) {
  if (changes.length === 0) return false;
  else {
    const arrayChanges = changes.map((change, index) => {
      if (changes.includes(change + 1) || changes.includes(change - 1)) {
        return true;
      } else return false;
    });
    return arrayChanges.find((change) => change) || false;
  }
}
