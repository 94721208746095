import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    height: "94vh",
    columnGap: "32px",
  },
  sections: {
    flex: 0.3,
    "&:last-child": {
      flex: 0.7,
      display: "flex",
      rowGap: "24px",
      flexDirection: "column",
    },
  },
  subSections: {
    flex: 0.6,
    "&:last-child": {
      flex: 0.4,
      display: "flex",
      columnGap: "24px",
    },
  },
  sencondCharts: {
    flex: 0.4,
    "&:last-child": {
      flex: 1,
    },
  },
});

export default useStyles;
