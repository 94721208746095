import { useContext, useState } from "react";
import { AuthContext } from "../../../../../../context/context";
import { DataGrid } from "../../../../../../shared/components";
import { AltaContext, EndosoContext } from "../../../../context";
import { useColumns } from "../hooks";
import { Box } from "@material-ui/core";
import { Filter } from "shared/components/filter";

export const TitularesTable = () => {
  const columns = useColumns();
  const { setTimer } = useContext(AuthContext);
  const { titulares, openModal } = useContext(AltaContext);
  const { createMessage } = useContext(EndosoContext);
  const [filteredTitulares, setFilteredTitulares] = useState([]);

  const handleClick = ({ row }) => {
    setTimer(0);
    if (row.familiares < 9) openModal(row);
    else
      createMessage({
        text: "No se pueden agregar más de 9 asociados por titular",
        variant: "warning",
      });
  };

  const handleSearch = (searchText) => {
    const filteredSet = new Set(searchText);
    const filtered = titulares.filter(
      (titular) =>
        filteredSet.has(titular.titular.toString()) ||
        filteredSet.has(titular.nombre)
    );
    setFilteredTitulares(filtered);
  };

  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <Filter
          placeholder="Consultar..."
          data={titulares?.flatMap((i) => [i.titular?.toString(), i?.nombre, i.titular?.toString() + " " + i?.nombre])}
          search={handleSearch}
        />
      </Box>
      <DataGrid
        columns={columns}
        rows={filteredTitulares}
        id="id"
        onCellClick={handleClick}
      />
    </Box>
  );
};
