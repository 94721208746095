import { useContext, useState } from "react";
import {
  Paper,
  TextField,
  Typography,
  InputLabel,
  Button,
} from "@material-ui/core";
import { endoso } from "../../services";
import { CheckBoxCover } from "../../shared/components";
import useStyles from "./styles";
import { AuthContext } from "../../context/context";

const Comments = () => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  const [types, setType] = useState([
    { label: "Dudas", value: 1, status: false },
    { label: "Quejas", value: 2, status: false },
    { label: "Comentarios", value: 3, status: false },
  ]);

  const [data, setData] = useState({});

  const handleChangeData = (e) => {
    setTimer(0);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submitData = async () => {
    setTimer(0);
    const typeOfMessage = types.filter((type) => type.status);
    data.types = typeOfMessage.map((type) => type.value);
    const body = {
      subject: "PORTAL ENDOSOS. Duda Recibida.",
      from: data.from,
      to: data.to,
      idSolicitudEndoso: 0,
      textoEndoso: data.mesagge,
      mensajeAdicional: " ",
      documentos: [],
      cc: [],
    };
    await endoso.sendComments(body);
  };

  return (
    <Paper className={classes.root} elevation={3}>
      <div className={classes.sections}>
        <Typography variant="h5">
          Envío de Dudas, Quejas y Comentarios
        </Typography>
      </div>
      <div className={classes.sections}>
        <Typography variant="h6">Datos del remitente</Typography>
        <div className={classes.subSections}>
          <div className={classes.formContainer}>
            <InputLabel className={classes.label} htmlFor="from">
              De
            </InputLabel>
            <TextField
              onChange={handleChangeData}
              id="from"
              name="from"
              fullWidth
              size="small"
              variant="outlined"
            />
          </div>
          <div className={classes.formContainer}>
            <InputLabel className={classes.label} htmlFor="to">
              Para
            </InputLabel>
            <TextField
              onChange={handleChangeData}
              id="to"
              name="to"
              fullWidth
              size="small"
              variant="outlined"
            />
          </div>
        </div>
      </div>
      <div className={classes.sections}>
        <Typography variant="h6">Tipo de comentario</Typography>
        <div className={classes.subSections}>
          <CheckBoxCover types={types} setType={setType} />
        </div>
        <div className={classes.subSections}>
          <div className={classes.formContainer}>
            <InputLabel className={classes.label} htmlFor="mesagge">
              Mensaje
            </InputLabel>
            <TextField
              id="mesagge"
              name="mesagge"
              multiline
              minRows={7}
              fullWidth
              onChange={handleChangeData}
              size="small"
              variant="outlined"
            />
          </div>
        </div>
      </div>
      <div className={classes.sections}>
        <Button
          className={classes.submit}
          variant="contained"
          onClick={submitData}
        >
          <strong>Enviar</strong>
        </Button>
      </div>
    </Paper>
  );
};

export default Comments;
