import { EndosoContext } from "pages/endoso/context";
import { useContext, useEffect } from "react";
import { endoso } from "services";

export const useRulesBy23 = () => {
    const { rows23, setShowButtons, showButtons, createMessage, setContinueR23 } = useContext(EndosoContext);
    let error = false;

    useEffect(() => {
        setShowButtons({
            ...showButtons,
            disableTwentyThree: rows23.length === 0,
        });
    }, [rows23]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRows23 = async () => {
        if (rows23.length > 0) {
            let accumulatedErrors = [];
            for (const item of rows23) {
                const res = await endoso.bajaUbicacion23(item);
                if (res?.data?.codigo === 0 || res.error) {
                    accumulatedErrors.push(item.ubicacion);
                    createMessage({
                        text: res?.data?.mensaje || `Ocurrió un error al dar de baja la ubicación: ${item.ubicacion}`,
                        variant: "warning",
                    });
                    error = true;
                }
            }
            if (error) {
                if (accumulatedErrors.length !== rows23.length) {
                    createMessage({
                        text: "Algunas ubicaciones no se dieron de baja correctamente",
                        variant: "warning",
                    });
                }
                setContinueR23(false);
            } else {
                setShowButtons({
                    ...showButtons,
                    infoTable: true,
                });
                setContinueR23(true);
            }
        }
    };

    return {
        handleRows23,
    };
};
