import { useMemo } from "react";
import { Checkbox } from "@material-ui/core";

export const useColumns = () => {
  const columns = useMemo(
    () => [
      {
        field: "seleccion",
        headerName: "Seleccionar",
        flex: 0.25,
        headerClassName: "header",
        headerAlign: "center",
        renderCell: ({ row }) => {
          return <Checkbox color="primary" checked={row.checked} />;
        },
      },
      {
        field: "codigo",
        headerName: "Número",
        flex: 0.4,
        headerClassName: "header",
        headerAlign: "center",
        sortable: false,
      },
      {
        field: "descripcion",
        headerName: "Asegurado",
        flex: 1,
        headerClassName: "header",
        headerAlign: "center",
        sortable: false,
      },
    ],
    []
  );
  return columns;
};
