import { Fragment, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "./styles";
import { EndosoContext } from "../../../../../context";

export const InfoTable = () => {
  const classes = useStyles();
  const { infoTable } = useContext(EndosoContext);
  const columns = [
    {
      field: "campo",
      headerName: "Campo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "valorOriginal",
      headerName: "Valor Original",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "valorNuevo",
      headerName: "Valor Nuevo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];

  return (
    <Fragment>
      <DataGrid
        columns={columns}
        rows={infoTable ?? []}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[4]}
        pageSize={4}
        pagination
        getRowId={(row) => row.id}
      />
    </Fragment>
  );
};
