import { makeStyles } from "@material-ui/core";
import { colors } from "../../utils";

const useStyles = makeStyles({
  label: {
    marginLeft: "16px",
    marginBottom: "6px",
    color: colors.blueDarken,
    fontSize: ".82rem",
  },
  input: {
    fontSize: ".52rem",
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: colors.greyFont,
      },
    },
    "& .textAdorment": {
      fontSize: "12px",
    },
  },
});

export default useStyles;
