import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    marginTop: "16px",
  },
  button: {
    borderRadius: "19px",
  },
});

export default useStyles;
