import { useContext } from "react";
import { EndosoContext } from "../../../context";
import { FormTable, Form, InfoTable } from "./components";

export const ModificacionDescripcion = ({
  showButtons,
  loading,
  setShowButtons,
}) => {
  const { auto, infoTable } = useContext(EndosoContext);
  if (infoTable?.length) return <InfoTable />;
  if (!Object.values(auto).length) return <FormTable />;
  return (
    <Form
      showButtons={showButtons}
      loading={loading}
      setShowButtons={setShowButtons}
    />
  );
};
