import { useContext, useState } from "react";
import { AuthContext } from "../../../../../../context/context";
import { EndosoContext } from "../../../../context";
import { endoso } from "../../../../../../services";

export const usePagination = () => {
  const { setTimer, persons, setPersons } = useContext(AuthContext);
  const {
    bajaRiesgo15,
    setBajaRiesgo15,
    formDta,
    searchWord,
    selected,
    setSelected,
  } = useContext(EndosoContext);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const getPage = (page) => {
    setTimer(0);
    setLoader(true);
    const body = {
      pagina: page + 1,
      solicitud: formDta.noSolicitud,
      nivel: formDta.nivel,
      clave: searchWord || null,
    };
    endoso.nivel902(body).then(({ data }) => {
      if (data) {
        setBajaRiesgo15({
          ...bajaRiesgo15,
          initialData: data,
          niveles: data.niveles,
        });
        setPage(page + 1);
        setLoader(false);
      }
    });
  };
  const handleChangePagination = ({ row }) => {
    setTimer(0);
    const newRows = bajaRiesgo15.niveles.map((nivelRow) =>
      row.codigo === nivelRow.codigo
        ? { ...row, checked: !row.checked }
        : nivelRow
    );
    setBajaRiesgo15({
      ...bajaRiesgo15,
      niveles: newRows,
    });
    if (row.checked) {
      const removeRow = selected.filter((item) => item.codigo !== row.codigo);
      setSelected(removeRow);
      setPersons(removeRow.map((item) => item.codigo));
    } else {
      setSelected([...selected, row]);
      setPersons([...persons, row.codigo]);
    }
  };

  return {
    setLoader,
    loader,
    page,
    getPage,
    handleChangePagination,
    selected,
  };
};
