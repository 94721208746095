export const Cancelacion = {
  idDato: 80,
  type: "select",
  valorActual: null,
  valorAnterior: null,
  descripcionActual: "Submotivo de anulación",
  descripcionAnterior: null,
  caracteresMaximos: 2,
  caracteresMinimos: 0,
  obligatorio: true,
  idSolicitud: "23179",
  catalogo: {
    codigo: 1,
    cantidad: 1,
    catalogo: [
      {
        id: "Error en emision",
        descripcion: "Error en emisión",
      },
      {
        id: "Emision por otra compañia",
        descripcion: "Emisión por otra compañía",
      },
      {
        id: "Falta de pago",
        descripcion: "Falta de pago",
      },
      {
        id: "Reexpedicion",
        descripcion: "Reexpedición",
      },
    ],
  },
  editable: true,
  acronimo: "SMOTANU",
  suplemLogico: null,
  visible: true,
};
