import { EndosoContext } from "pages/endoso/context";
import { FormBenPref } from "./components/formBenPref"
import { useContext } from "react";
import { PdfPreview } from "./components/pdfPreview";


export const BeneficiarioPreferente21 = ({ data }) => {
    const { continue21, finishR21 } = useContext(EndosoContext);
    return (
        <>
            {continue21 ? <PdfPreview data={finishR21} /> : <FormBenPref data={data} />}
        </>
    )
}