import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': 'black !important',
      '-webkit-background-clip': 'content-box !important',
      '-webkit-box-shadow': '0 0 0 -1000px white inset !important',
      'transition': 'background-color 5000s ease-in-out 0s',
      '-webkit-border-radius': '0',
    },
    
    "& svg": {
      zIndex: 1,
    },
    "& .MuiInputBase-input": {
      zIndex: 1,
    },
  },
});

export default useStyles;
