export const Fonts = {
    SuraSansExtranegrita: "SuraSans-Extranegrita",
    SuraSansExtranegritaItalica: "SuraSans-ExtranegritaItalica",
    SuraSansFina: "SuraSans-Fina",
    SuraSansFinaItalica: "SuraSans-FinaItalica",
    SuraSansItalicaVF: "SuraSans-ItalicaVF",
    SuraSansLigera: "SuraSans-Ligera",
    SuraSansLigeraItalica: "SuraSans-LigeraItalica",
    SuraSansNegrita: "SuraSans-Negrita",
    SuraSansNegritaItalica: "SuraSans-NegritaItalica",
    SuraSansRegular: "SuraSans-Regular",
    SuraSansRegularItalica: "SuraSans-RegularItalica",
    SuraSansSeminegrita: "SuraSans-Seminegrita",
    SuraSansSeminegritaItalica: "SuraSans-SeminegritaItalica",
    SuraSansVF: "SuraSansVF",
}