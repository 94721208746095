import { Fragment } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "./styles";

const InfoTableHighRisk = ({ title, rows, type = 1 }) => {
  const classes = useStyles();
  const columns = [
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "categoria",
      headerName: "Categoría",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <span style={{ textAlign: "center", width: "100%" }}>
          {row.categoria === 1
            ? "ALUMNO/ASEGURADO"
            : row.categoria === 2
            ? "DOCENTE/ADMINISTRATIVO"
            : row.categoria}
        </span>
      ),
    },
    {
      field: "sexo",
      headerName: "Sexo",
      flex: 0.83,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "fechainicial",
      headerName: "Fecha de Nacimiento",
      flex: 0.8,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
  /* methods */
  const sortRows = () => {
    const sortedRows = rows.map((row, index) => ({ ...row, id: index }));
    sortedRows.push({
      nombre: "",
      categoria: "Importe póliza Anterior",
      sexo: "Nuevo importe póliza",
      fechainicial: "Diferencia de monto",
      id: 9998,
    });
    sortedRows.push({
      nombre: "",
      categoria: rows[0]?.importeAnterior || "",
      sexo: rows[0]?.importeActual || "",
      fechainicial: rows[0]?.importeDiferencia || 0,
      id: 9999,
    });
    return sortedRows;
  };

  return (
    <Fragment>
      <div className={classes.headerComponent}>
        <Typography variant="h6" className={classes.title} align="left">
          <strong>{title}</strong>
        </Typography>
      </div>
      <DataGrid
        columns={columns}
        rows={sortRows()}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[8]}
        pageSize={8}
        pagination
      />
    </Fragment>
  );
};

export default InfoTableHighRisk;
