import { Fragment, useContext, useState } from "react";
import { Button, InputLabel, TextField } from "@material-ui/core";
import { endoso } from "../../../../../services";
import FormTable from "./formTable";
import DirectionForm from "./directionForm";
import { AuthContext } from "../../../../../context/context";
import useStyles from "./style";
import { Spinner } from "shared/components";

const AddDirection = ({
  dataSend,
  type = 1,
  setShowButtons,
  formDta,
  setFormDta,
}) => {
  const classes = useStyles();
  const { direction, setDirection, setTimer } = useContext(AuthContext);
  const [showElements, setShowElements] = useState(0);
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  /* methods */
  const handleChange = (e) => {
    setTimer(0);
    setDirection({ ...direction, [e.target.name]: e.target.value });
  };
  const onlyNumbers = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9]/, "")
      .replace(/(\..*)\./g, "$1");
  };
  const handleSearchDirection = async (e) => {
    e.preventDefault();
    setLoading(true);
    const directionResponse = await endoso.codigoPostal(direction.codigoPostal);
    if (directionResponse.data?.codigo === 1) {
      setTableData(directionResponse.data || {});
      setShowElements(1);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSearchDirection} className={classes.root}>
      {showElements === 0 && (
        <Fragment>
          <div>
            <InputLabel htmlFor={"codigoPostal"}>Código Postal</InputLabel>
            <TextField
              className={classes.input}
              variant="outlined"
              name="codigoPostal"
              size="small"
              onInput={onlyNumbers}
              onChange={handleChange}
              inputProps={{ maxLength: 250 }}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!direction.codigoPostal}
            type="submit"
          >
            <strong>Buscar</strong>
          </Button>
        </Fragment>
      )}
      {loading ? (
        <Spinner />
      ) : (
        showElements === 1 && (
          <div>
            <FormTable
              rows={tableData}
              setData={setDirection}
              data={direction}
              type={type}
              setShowElements={setShowElements}
              setShowButtons={setShowButtons}
              formDta={formDta}
              setFormDta={setFormDta}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!direction.codigoPostal}
              onClick={() => {
                setShowElements(0);
                setDirection({});
              }}
            >
              <strong>Regresar busqueda</strong>
            </Button>
          </div>
        )
      )}

      {showElements === 2 && (
        <DirectionForm
          data={direction}
          change={handleChange}
          dataSend={dataSend}
        />
      )}
    </form>
  );
};

export default AddDirection;
