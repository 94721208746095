import { EndosoContext } from "pages/endoso/context";
import { useContext } from "react";
import { endoso } from "services";

export const useRulesBy21 = () => {
    const { finishR21, continue21, setContinue21, createMessage, showButtons, setShowButtons } = useContext(EndosoContext);

    const confirmEndoso = async (noSolicitud) => {
        const body = {
            solicitud: noSolicitud,
            clave: finishR21?.seccion,
            nombreBeneficiario: finishR21?.nombreBeneficiario,
            contrato: finishR21?.contrato
        }
        const resp = await endoso.benPref(body);
        if (resp.data.codigo === 1) {
            setContinue21(true);
            setShowButtons({ ...showButtons, infoTable: true });
        } else {
            createMessage({
                Text: resp?.data?.mensaje || "Error al realizar el endoso",
                variant: "warning"
            })
            setContinue21(false);
        }
    }

    return {
        confirmEndoso,
        continue21
    };
};
