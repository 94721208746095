import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Paper,
  Button,
  Typography,
  TextField,
  InputLabel,
  Fade,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import { AuthContext } from "../../../../context/context";
import searchPoliza from "../../../../services/searchPoliza";
import useStyles from "./styles";
import { Spinner } from "../../../../shared/components";
import { searchByPoliza } from "../../../../shared/hooks";
import { endoso } from "../../../../services";

const FormSection = ({ ramos, setMessage, setShowMessage, oficinas }) => {
  const classes = useStyles();

  /* history */
  const history = useHistory();

  /* state */
  const { dispatch, token, setTimer, setSections21, setShowMonthlySalary } =
    useContext(AuthContext);

  const [data, setData] = useState({});
  const [isSearch, setIsSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  //methods
  const handleChangeData = (e) => {
    setTimer(0);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeForm = (type) => {
    setTimer(0);
    if (type === 1) {
      setIsSearch(true);
      setData({});
    } else {
      setIsSearch(false);
      setData({});
    }
  };

  const submitData = async () => {
    setTimer(0);
    setLoading(true);
    setDisabled(true);
    /* type 1 */
    if (!data.n_request) {
      data.usuario = token.cdUsuario;
      data.nombre = "";
      data.agente = "";
      data.pagina = "";
      data.registros = "";
      const response = await searchPoliza.search(data);

      if (response.data?.codigo === 1) {
        const respuesta = response.data.polizas[0];
        setLoading(false);
        setDisabled(false);
        if (respuesta.bloqueo)
          dispatch({
            ...response.data.polizas[0],
            mensajeBloqueo:
              "La póliza se encuetra bloqueada, finalice los endosos pendientes",
          });
        else dispatch(response.data.polizas[0]);
        history.push("/endosos");
      } else if (response.data?.codigo !== 1 || response.error) {
        setLoading(false);
        setDisabled(false);
        setMessage({
          text: response.data?.mensaje || "Ocurrio un error con su solicitud",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      /* type 2 */
      const searchResponse = await endoso.list(data.n_request, token.cdUsuario);
      searchByPoliza(
        searchResponse,
        setLoading,
        dispatch,
        history,
        setMessage,
        setDisabled,
        setShowMessage,
        1,
        token.cdUsuario,
        setSections21,
        setShowMonthlySalary
      );
    }
  };

  const onlyNumbers = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9]/, "")
      .replace(/(\..*)\./g, "$1");
  };

  useEffect(() => {
    if (isSearch) {
      if (data.oficina && data.ramo && data.poliza) setDisabled(false);
      else setDisabled(true);
    } else {
      if (data.n_request) setDisabled(false);
      else setDisabled(true);
    }
    // eslint-disable-next-line
  }, [data]);
  return (
    <Paper elevation={3} className={classes.root}>
      {loading && <Spinner />}
      <form onSubmit={submitData}>
        <div className={classes.section}>
          <Button
            onClick={() => {
              handleChangeForm(1);
            }}
            disableRipple
            variant="text"
            fullWidth
            className={clsx(classes.titleButton, {
              [classes.colorBlue]: isSearch,
              [classes.colorGrey]: !isSearch,
            })}
          >
            <Typography variant="body1" align="left">
              <strong>Buscar póliza</strong>
            </Typography>
            <Fade in={!isSearch} timeout={500}>
              <Typography variant="caption" className={classes.caption}>
                (Oficina, Ramo y Póliza)
              </Typography>
            </Fade>
          </Button>
          <Fade in={isSearch} timeout={500}>
            <div>
              {isSearch && (
                <div className={classes.formContainer}>
                  <div>
                    <InputLabel className={classes.label} htmlFor="oficina">
                      Oficina
                    </InputLabel>
                    <TextField
                      onChange={handleChangeData}
                      id="oficina"
                      name="oficina"
                      fullWidth
                      size="small"
                      variant="outlined"
                      defaultValue={""}
                      select
                    >
                      {oficinas.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.idOficina}>
                            {option.idOficina + " " + option.oficina}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div>
                    <InputLabel className={classes.label} htmlFor="ramo">
                      Ramo
                    </InputLabel>
                    <TextField
                      onChange={handleChangeData}
                      id="ramo"
                      name="ramo"
                      fullWidth
                      size="small"
                      variant="outlined"
                      defaultValue={""}
                      select
                    >
                      {ramos.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.idRamo}>
                            {option.idRamo + " " + option.ramo}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                  <div>
                    <InputLabel className={classes.label} htmlFor="poliza">
                      Póliza
                    </InputLabel>
                    <TextField
                      onChange={handleChangeData}
                      id="poliza"
                      name="poliza"
                      fullWidth
                      onInput={onlyNumbers}
                      size="small"
                      variant="outlined"
                    />
                  </div>
                </div>
              )}
            </div>
          </Fade>
        </div>

        <div className={classes.section}>
          <div style={{ marginTop: "40px" }}>
            <Typography variant="body1" align="left">
              <Button
                onClick={() => {
                  handleChangeForm(2);
                }}
                disableRipple
                variant="text"
                fullWidth
                className={clsx(classes.titleButton, {
                  [classes.colorBlue]: !isSearch,
                  [classes.colorGrey]: isSearch,
                })}
              >
                <strong>Número de solicitud</strong>
              </Button>
            </Typography>
          </div>
          <Fade in={!isSearch} timeout={500}>
            <div>
              {!isSearch && (
                <div className={classes.formContainer}>
                  <div>
                    <InputLabel className={classes.label} htmlFor="n_request">
                      Número de solicitud
                    </InputLabel>
                    <TextField
                      onChange={handleChangeData}
                      id="n_request"
                      name="n_request"
                      fullWidth
                      size="small"
                      onInput={onlyNumbers}
                      variant="outlined"
                    />
                  </div>
                </div>
              )}
            </div>
          </Fade>
        </div>

        <div className={classes.section}>
          <Button
            variant="contained"
            className={classes.submitBottom}
            fullWidth
            onClick={submitData}
            disabled={disabled}
            type="submit"
          >
            <strong>Buscar</strong>
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default FormSection;
