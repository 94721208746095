import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "./styles";
import { RenderCellExpand } from "../../../../../../../shared/components";
import { Typography } from "@material-ui/core";

export const FailedTableInfo = ({ rows }) => {
  const classes = useStyles();

  const columns = [
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "error",
      headerName: "Error",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: RenderCellExpand,
    },
  ];

  return (
    <Fragment>
      <Typography variant="h6" color="primary">
        Errores en el archivo
      </Typography>
      <DataGrid
        columns={columns}
        rows={rows ?? []}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[8]}
        getRowId={(row) => row.nombre}
        pageSize={8}
        pagination
      />
    </Fragment>
  );
};
