import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils/colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    rowGap: "32px",
    boxSizing: "border-box",
    padding: "24px",
  },
  title: {
    color: colors.blueDarken,
  },
  information: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& h6": { color: colors.blueDarken, paddingLeft: "8px" },
    "& span": {
      color: colors.greyLighten,
      borderTop: colors.greyLighten + " solid 1px",
      paddingLeft: "8px",
    },
  },
  fisrtInfo: {
    maxWidth: "70%",
  },
  status: {
    borderRadius: "15px 0 0 15px",
    textAlign: "center",
    position: "absolute",
    right: 0,
    padding: "8px 24px",
  },
  good: {
    background: colors.blueSoft,
    "& h6": { color: colors.greyFont },
    "& span": {
      color: colors.greyLighten,
    },
  },
  special: {
    background: colors.redSpecial,
    "& h6": { color: colors.white },
    "& span": {
      color: colors.white,
    },
  },
  blocked: {
    background: colors.greyBlocked,
    "& h6": { color: colors.white },
    "& span": {
      color: colors.white,
    },
  },
  sections: {
    display: "flex",
    columnGap: "8px",
  },
});

export default useStyles;
