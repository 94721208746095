import { Grid, InputLabel } from "@material-ui/core";
import {
  DatePicker as NativeDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "../styles";

export const DatePicker = ({ label, name, onChange, required, value }) => {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <InputLabel className={classes.label}>
        {label + (required ? " (*)" : "")}
      </InputLabel>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <NativeDatePicker
          className={classes.input}
          name={name}
          size="small"
          fullWidth
          inputVariant="outlined"
          maxDate={new Date()}
          value={value || null}
          onChange={(e) => {
            onChange({
              target: { name: name, value: e },
            });
          }}
          format={"dd-MM-yyyy"}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
