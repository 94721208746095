import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    padding: "4px 8px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  icon: {
    color: "#B7070D",
    fontSize: "18px",
  },
});

export default useStyles;
