import { Paper, Typography } from "@material-ui/core";
import { AreaChart, ColorBox } from "../../../../shared/components";
import { colors } from "../../../../shared/utils";
import useStyles from "./styles";

const BigChartSection = ({ data }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={3}>
      <div className={classes.sections}>
        <Typography variant="h5" className={classes.title}>
          Endosos solicitados / terminados
        </Typography>
        <div className={classes.subSections}>
          <ColorBox color={colors.yellow} legend="Endosos terminados" />
          <ColorBox color={colors.redSpecial} legend="Endosos solicitados" />
        </div>
      </div>
      <div className={classes.sections}>
        <AreaChart
          data={data}
          palette={colors.endososOverPallete}
          size={{ height: 250 }}
        />
      </div>
    </Paper>
  );
};

export default BigChartSection;
