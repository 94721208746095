import { formatPerentesco, formatSex } from "../../../../hooks";

export const useResultColumns = (file) => {
  return [
    {
      field: "titular",
      headerName: "Código",
      flex: file ? 0.2 : 0.1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 0.4,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "sexo",
      headerName: "Sexo",
      flex: 0.2,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const isCompleted = !!row.sexo && row.sexo !== "M" && row.sexo !== "F";
        return (
          <div style={{ width: "100%" }}>
            {isCompleted ? row.sexo : formatSex(row.sexo)}
          </div>
        );
      },
    },
    {
      field: "parentesco",
      headerName: "Tipo De Asegurado",
      flex: 0.2,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <div style={{ width: "100%" }}>{formatPerentesco(row.parentesco)}</div>
      ),
    },
  ];
};
