import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    borderRadius: "19px",
    overflow: "hidden",
    minWidth: "500px",
    minHeight: "300px",
    "& .MuiDataGrid-iconButtonContainer": {
      display: "none",
    },
    "& .header": {
      background: colors.blueLighten,
      color: colors.white,
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-cell": {
      textAlign: "center",
    },
    "& .MuiDataGrid-columnsContainer": {
      background: colors.blueLighten,
    },
  },
  headerComponent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "24px",
  },
  title: {
    color: colors.blueDarken,
  },
});

export default useStyles;
