import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    columnGap: "16px",
    boxSizing: "border-box",
    padding: "16px",
  },
  sections: {
    flex: 0.3,
    "&:first-child": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "&:last-child": {
      flex: 0.7,
    },
  },
  subSections: {
    display: "flex",
    columnGap: "24px",
    alignItems: "center",
    height: "80%",
  },
  title: { color: colors.blueDarken },
});

export default useStyles;
