import { useContext } from "react";
import { Button, Typography } from "@material-ui/core";
import { AuthContext } from "../../../../../../../context/context";
import { InputFile } from "../fields";
import useStyles from "./styles";
import { EndosoContext } from "../../../../../context";
import { FilesViewer } from "../filesViewer";
export const UploadExcel = ({ returntoMain }) => {
  const classes = useStyles();
  const { setTimer, setPersons, persons } = useContext(AuthContext);
  const { filesResponse, spinner } = useContext(EndosoContext);

  const handleChange = (files) => {
    setTimer(0);
    setPersons(files[0]);
  };
  if (filesResponse.length) return <FilesViewer />;

  if (spinner) return <></>;

  return (
    <div>
      <Typography className={classes.title} variant="h6">
        Agregar archivo de asegurados
      </Typography>
      <InputFile
        required
        label="Archivo de asegurados"
        onChange={handleChange}
        name={persons?.name}
      />
      <Typography className={classes.caption} variant="caption">
        Recuerda que solo se permiten documentos excel (.xlsx)
      </Typography>
      <Button
        className={classes.backButton}
        variant="text"
        onClick={() => {
          setTimer(0);
          returntoMain();
        }}
      >
        Regresar
      </Button>
    </div>
  );
};
