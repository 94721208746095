import { generarInfoTabla } from "..";
export const rulesByFiveSeven = async (
  data,
  oldData,
  dataSend,
  setMessage,
  setShowMessage,
  formDta,
  endoso,
  setInfoTable,
  setShowButtons,
  showButtons
) => {
  const guardar = async () => {
    const body = {
      nivel: formDta.nivel,
      orden: 1,
      solicitud: formDta.noSolicitud,
      valor: data.tipend === 5 ? dataSend.MOTANU : dataSend.PERPAG,
    };
    const guardaResponse = await endoso.saveEndoso(body);
    if (guardaResponse.data?.codigo === 1) {
      setInfoTable(generarInfoTabla(oldData, dataSend, data.tipend, formDta));
      setShowButtons({ ...showButtons, infoTable: true, domicilio: false });
    } else {
      setMessage({
        text: "SU SOLICITUD NO HA PODIDO SER PROCESADA",
        severity: "warning",
      });
      setShowMessage(true);
    }
  };

  if (data.tipend === 5) {
    if (dataSend.SMOTANU && dataSend.MOTANU) await guardar();
    else {
      setMessage({
        text: "Por favor complete los campos requeridos para continuar",
        severity: "warning",
      });
      setShowMessage(true);
    }
  } else await guardar();
};
