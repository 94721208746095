import { DataGrid as NativeDataGrid, GridOverlay } from "@mui/x-data-grid";
import useStyles from "./styles";
const DataGrid = ({ columns, rows, onCellClick, id }) => {
  const classes = useStyles();
  const getIdentifider = (row) => row[id];
  return (
    <NativeDataGrid
      columns={columns}
      rows={rows}
      className={classes.root}
      onCellClick={onCellClick ?? null}
      disableColumnMenu
      disableSelectionOnClick
      rowsPerPageOptions={[4]}
      pageSize={4}
      pagination
      components={{
        NoRowsOverlay: () => (
          <GridOverlay>No se encontro información</GridOverlay>
        ),
      }}
      getRowId={getIdentifider}
    />
  );
};

export default DataGrid;
