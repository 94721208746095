import { Button, InputLabel, MenuItem, TextField } from "@material-ui/core";
import useStyles from "./styles";
import { useSteps } from "../../hooks";
import { useEffect } from "react";
import { Spinner } from "shared/components";

export const FormCP = (props) => {
    const classes = useStyles();
    const { cp, cpValidate, step, setStep, coloniaOptions, selectedColonia, setSelectedColonia, calle, setCalle, numero, setNumero, sigla, setSigla, piso, setPiso, agrupador, setAgrupador,
        siglaOptions, agrupadorOptions, handleSubmitCP, handleSubmitColonia, handleFinalSubmit, loading, disabledButton, inputValidate } = useSteps(props);

    useEffect(() => {
        if (step === 3 && agrupadorOptions.length === 1) {
            setAgrupador(agrupadorOptions[0].cdagrupa);
        }
    }, [step, agrupadorOptions, setAgrupador]); // eslint-disable-line

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (step) {
            case 1:
                handleSubmitCP();
                break;
            case 2:
                handleSubmitColonia();
                break;
            case 3:
                handleFinalSubmit();
                break;
            default:
                break;
        }
    };

    return (
        <div>
            {loading ? <Spinner /> : (
                <div>
                    {step === 1 && (
                        <form className={classes.root} onSubmit={handleSubmit}>
                            <div>
                                <InputLabel htmlFor="codigoPostal">Código Postal (*)</InputLabel>
                                <TextField
                                    value={cp}
                                    variant="outlined"
                                    name="codigoPostal"
                                    size="small"
                                    className={classes.input}
                                    onChange={cpValidate}
                                    fullWidth
                                    inputProps={{ maxLength: 250 }}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.containerButtons}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.submit}
                                    type="submit"
                                    disabled={loading || disabledButton}
                                >
                                    Continuar
                                </Button>
                            </div>
                        </form>
                    )}
                    {step === 2 && (
                        <form className={classes.root} onSubmit={handleSubmit}>
                            <div>
                                <InputLabel htmlFor="codigoPostal">Código Postal (*)</InputLabel>
                                <TextField value={cp} disabled fullWidth />
                            </div>
                            <div>
                                <InputLabel className={classes.label}>Colonia (*)</InputLabel>
                                <TextField
                                    select
                                    value={selectedColonia}
                                    onChange={(e) => setSelectedColonia(e.target.value)}
                                    fullWidth
                                    disabled={loading}
                                >
                                    {coloniaOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.cdColonia}>
                                            {option.dsColonia}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div>
                                <InputLabel htmlFor="numero">Número (*)</InputLabel>
                                <TextField
                                    value={numero}
                                    onChange={(e) => { inputValidate(e, setNumero, 10) }}
                                    fullWidth
                                    className={classes.input}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 250 }}
                                    disabled={loading}
                                />
                            </div>
                            <div>
                                <InputLabel htmlFor="calle">Calle (*)</InputLabel>
                                <TextField
                                    value={calle}
                                    onChange={(e) => { inputValidate(e, setCalle, 50, false, true) }}
                                    fullWidth
                                    className={classes.input}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 250 }}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.containerButtons}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.back}
                                    onClick={() => setStep(step - 1)}
                                    disabled={loading}
                                >
                                    Regresar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.submit}
                                    type="submit"
                                    disabled={loading || disabledButton}
                                >
                                    Continuar
                                </Button>
                            </div>
                        </form>
                    )}
                    {step === 3 && (
                        <form className={classes.root} onSubmit={handleSubmit}>
                            <div>
                                <InputLabel htmlFor="codigoPostal">Código Postal (*)</InputLabel>
                                <TextField value={cp} disabled fullWidth />
                            </div>
                            <div>
                                <InputLabel htmlFor="colonia">Colonia (*)</InputLabel>
                                <TextField select value={selectedColonia} disabled fullWidth>
                                    {coloniaOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.cdColonia}>
                                            {option.dsColonia}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div>
                                <InputLabel htmlFor="sigla">Sigla (*)</InputLabel>
                                <TextField
                                    select
                                    value={sigla}
                                    onChange={(e) => setSigla(e.target.value)}
                                    fullWidth
                                    disabled={loading}
                                >
                                    {siglaOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.codigo}>
                                            {option.descripcion}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div>
                                <InputLabel htmlFor="calle">Calle (*)</InputLabel>
                                <TextField
                                    value={calle}
                                    onChange={(e) => setCalle(e.target.value)}
                                    fullWidth
                                    disabled
                                />
                            </div>
                            <div>
                                <InputLabel htmlFor="numero">Número (*)</InputLabel>
                                <TextField
                                    value={numero}
                                    onChange={(e) => setNumero(e.target.value)}
                                    fullWidth
                                    disabled
                                />
                            </div>
                            <div>
                                <InputLabel htmlFor="piso">Piso</InputLabel>
                                <TextField
                                    value={piso}
                                    onChange={(e) => { inputValidate(e, setPiso, 20) }}
                                    fullWidth
                                    className={classes.input}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 250 }}
                                    disabled={loading}
                                />
                            </div>
                            <div>
                                <InputLabel className={classes.label}>Agrupador (*)</InputLabel>
                                <TextField
                                    select
                                    value={agrupador}
                                    onChange={(e) => setAgrupador(e.target.value)}
                                    fullWidth
                                    disabled={loading}
                                >
                                    {agrupadorOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.cdagrupa}>
                                            {option.cdagrupa} - {option.nombre}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className={classes.containerButtons}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.back}
                                    onClick={() => setStep(step - 1)}
                                    disabled={loading}
                                >
                                    Regresar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.submit}
                                    type="submit"
                                    disabled={loading || disabledButton}
                                >
                                    Continuar
                                </Button>
                            </div>
                        </form>
                    )}
                </div>
            )}
        </div>
    );
};
