import { useContext, useEffect, useState } from "react";
import { LowLocation23 } from "./components/lowLocation"
import { endoso } from "services";
import { EndosoContext } from "pages/endoso/context";
import { InfoTable } from "./components/infoTable";
import { Spinner } from "shared/components";


export const BajaUbicacion23 = ({ noSolicitud, data }) => {
    const { continueR23 } = useContext(EndosoContext);
    const [bajas, setBajas] = useState([]);

    useEffect(() => {
        (async () => {
            const resp = await endoso.bajasUbicacion(noSolicitud);
            if (resp) {
                setBajas(resp);
            }
        }
        )();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {bajas.data ?
                continueR23 ?
                    <InfoTable numeroSituacion={{noSolicitud}} />
                    :
                    <LowLocation23 data={data} info={bajas} />
                :
                <Spinner />}
        </div>
    )
}