import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: { position: "relative" },
  button: {
    borderRadius: "19px",
    position: "absolute",
    color: "white",
    left: 16,
    top: 65,
  },
  container: {
    width: "100%",
    textAlign: "center",
    height: "100%",
    alignContent: "center",
  },
});
