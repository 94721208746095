import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useStyles from "./style";
import { useContext } from "react";
import { AuthContext } from "context/context";
import { AlertModal } from "shared/components";

const SnackBar = ({ text, severity, show, HandleShow, time, action }) => {

  const { setHandleContinue, setShowContinue } = useContext(AuthContext)
  const classes = useStyles();
  return (
    <>
      {!action ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={show}
          autoHideDuration={action ? undefined : time}
          onClose={action ? undefined : () => {
            HandleShow(false);
          }}
          className={classes.alert}
        >
          <Alert severity={severity}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <span style={{ marginRight: '10px' }}>{text}</span>
            </div>
          </Alert>

        </Snackbar>
      ) : (
        <AlertModal
          open={show}
          SuccessText={"Continuar"}
          close={() => { }}
          message={text}
          title={"Atención"}
          icon
          success={() => {
            setHandleContinue(true);
            HandleShow(false);
            setShowContinue(false);
          }}
          type={2}
        />
      )}
    </>
  );
};

export default SnackBar;
