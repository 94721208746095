import {
  Paper,
  TextField,
  Typography,
  InputLabel,
  Button,
  MenuItem,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "./styles";

const ReportData = ({
  oficces,
  ramos,
  change,
  data,
  submit,
  disabled,
  generateReport,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={3}>
      <Typography variant="h5" className={classes.title}>
        Datos del reporte
      </Typography>

      <div className={classes.sections}>
        <div>
          <InputLabel className={classes.label} htmlFor="oficce">
            Oficina
          </InputLabel>
          <TextField
            id="oficce"
            name="oficce"
            variant="outlined"
            size="small"
            select
            fullWidth
            onChange={change}
            defaultValue={""}
          >
            {oficces.map((option, index) => {
              return (
                <MenuItem key={index} value={option.idOficina}>
                  {option.idOficina + " " + option.oficina}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div>
          <InputLabel className={classes.label} htmlFor="ramo">
            Ramo
          </InputLabel>
          <TextField
            id="ramo"
            name="ramo"
            variant="outlined"
            size="small"
            select
            onChange={change}
            fullWidth
            defaultValue={""}
          >
            {ramos.map((option, index) => {
              return (
                <MenuItem key={index} value={option.idRamo}>
                  {option.idRamo + " " + option.ramo}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div>
          <InputLabel htmlFor="dateStart" className={classes.label}>
            Fecha inicio
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="dateStart"
              size="small"
              inputVariant="outlined"
              format={"dd-MM-yyyy"}
              value={data.dateStart || null}
              onChange={(e) => {
                change({ target: { name: "dateStart", value: e } });
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <InputLabel htmlFor="dateEnd" className={classes.label}>
            Fecha fin
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="dateEnd"
              size="small"
              inputVariant="outlined"
              format={"dd-MM-yyyy"}
              value={data.dateEnd || null}
              onChange={(e) => {
                change({ target: { name: "dateEnd", value: e } });
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <div className={classes.sections}>
        <Button
          className={classes.submit}
          variant="contained"
          onClick={submit}
          disabled={disabled}
        >
          <strong>Generar reporte</strong>
        </Button>
        <Button
          className={classes.submit}
          variant="contained"
          onClick={generateReport}
          disabled={disabled}
        >
          <strong>Exportar datos</strong>
        </Button>
      </div>
    </Paper>
  );
};

export default ReportData;
