import { Fragment, useContext } from "react";
import { Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../../../../../context/context";
import useStyles from "./styles";

const TableChecked = ({ title }) => {
  const classes = useStyles();
  const columns = [
    {
      field: "garantia",
      headerName: "Garantia",
      flex: 0.4,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "descripcion",
      headerName: "Descripcion",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
  const { coberturas, setCoberturas } = useContext(AuthContext);
  /* methods */
  const handleChageCobertura = (e) => {
    setCoberturas({ ...coberturas, values: e });
  };
  return (
    <Fragment>
      <div className={classes.headerComponent}>
        <Typography variant="h6" className={classes.title} align="left">
          <strong>{title}</strong>
        </Typography>
      </div>
      <DataGrid
        columns={columns}
        rows={coberturas.coberturas || []}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[4]}
        pageSize={4}
        pagination
        checkboxSelection
        getRowId={(row) => row.garantia}
        onSelectionModelChange={handleChageCobertura}
      />
    </Fragment>
  );
};

export default TableChecked;
