import { endoso } from "../../../../services";

export const rulesBySeventeen = async (
  data,
  setMessage,
  setShowMessage,
  formDta,
  setShowButtons,
  showButtons,
  persons,
  setPersons,
  setAlertData,
  setCheckedPersons
) => {
  const messages = {
    emptly: "Necesita seleccionar al menos una persona para dar de baja",
    maxExceded: "Solo puede dar de baja a un maximo de 10 personas",
    error: "No se pudo procesar su solicitud",
    requestError: "OCURRIO UN PROBLEMA PROCESANDO SU SOLICITUD",
    modal:
      "Los siguientes asegurados son titulares y tienen familiares asociados que seran dados de baja esten seleccionados o no. ¿Desea continuar con las bajas?",
  };
  /* methods */
  const getPersonsWhithFamilyForSend = async (persons) => {
    const personsChecked = await checkPersons();
    const personsWithoutFamily = personsChecked.filter(
      (person) => person.codigo === 1
    );
    const reducedPersonsForSend = personsWithoutFamily.map(
      (person) => person.idPersona
    );
    await handleSubmitPersons(reducedPersonsForSend.concat(persons));
  };
  const createMessage = (text) => {
    setShowMessage(true);
    setMessage({
      text: text,
      severity: "warning",
    });
  };

  const checkPersons = async () => {
    const personsArray = persons.map(async (person) => {
      const body = {
        codigo: null,
        codMensaje: null,
        idPersona: parseInt(person),
        mensaje: null,
        oficina: data.oficina,
        poliza: data.poliza,
        ramo: data.ramo,
      };
      const checkResponse = await endoso.checkeo(body);
      if (checkResponse.data) {
        return checkResponse.data;
      }
    });
    return await Promise.all(personsArray).then((person) => person);
  };

  const handleSubmitPersons = async (persons) => {
    const siniestros = [];

    /* save persons */
    const savePersons = persons.map(async (person) => {
      const body = {
        accion: null,
        codMensaje: null,
        idPersona: person,
        mensaje: null,
        oficina: data.oficina,
        poliza: data.poliza,
        ramo: data.ramo,
      };
      const personaBaja = await endoso.situacionBaja(body);
      if (personaBaja.data?.codigo === 1 || personaBaja.data?.codigo === null) {
        return 1;
      } else {
        createMessage(personaBaja.data?.mensaje || messages.requestError);
        siniestros.push(person);
        return 0;
      }
    });

    const responseSaves = await Promise.all(savePersons).then((id) => id);
    if (responseSaves.filter((save) => save === 0).length > 0) {
      console.log("error");
    } else {
      const personsFiltered = persons.filter(
        (person) => !siniestros.includes(person)
      );
      const parsedPersons = personsFiltered.map((person) => person.toString());
      setPersons(parsedPersons);
      const clausulaResponse = await endoso.clausula({
        solicitud: formDta.noSolicitud,
      });
      if (clausulaResponse.data?.codigo === "1") {
        setShowButtons({
          ...showButtons,
          multiTable: true,
          formTable: false,
        });
      } else {
        createMessage(messages.requestError);
      }
    }
  };
  if (persons && persons.length > 0) {
    if (persons.length > 10) createMessage(messages.maxExceded);
    else {
      const personsChecked = await checkPersons();
      const personsWithFamily = personsChecked.filter(
        (person) => person.codigo === 0
      );
      const reducedPersonsForSend = personsChecked.map(
        (person) => person.idPersona
      );

      if (personsWithFamily.length > 0) {
        setCheckedPersons(personsWithFamily);
        setAlertData({
          message: messages.modal,
          checkSubmit: getPersonsWhithFamilyForSend,
        });
      } else await handleSubmitPersons(reducedPersonsForSend);
    }
  } else createMessage(messages.emptly);
};
