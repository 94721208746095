import { Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";

const CardMobile = ({ data = [], titles, submit }) => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.root} onClick={() => submit(data)}>
      <div className={classes.item}>
        <Typography variant="body1">
          <strong>{data.noSolicitud}</strong>
        </Typography>
        <Typography variant="caption">{titles[0].headerName}</Typography>
      </div>
      <div className={classes.item}>
        <Typography variant="body1">
          <strong>{data.descEstatus}</strong>
        </Typography>
        <Typography variant="caption">{titles[1].headerName}</Typography>
      </div>
      <div className={classes.item}>
        <Typography variant="body1">
          <strong>{data.descOficina}</strong>
        </Typography>
        <Typography variant="caption">{titles[2].headerName}</Typography>
      </div>
      <div className={classes.item}>
        <Typography variant="body1">
          <strong>{data.ramo}</strong>
        </Typography>
        <Typography variant="caption">{titles[3].headerName}</Typography>
      </div>
    </Paper>
  );
};

export default CardMobile;
