import { Typography } from "@material-ui/core";
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body2" color="textSecondary" align="center">
        Sura 2021 @Todos los derechos reservados
      </Typography>
    </div>
  );
};

export default Footer;
