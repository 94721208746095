import { useContext } from "react";
import { AuthContext } from "../../../../../context/context";
import { InfoTableHighRisk, SectionSelector } from "./components";

export const AltaRiesgo14 = ({ setMessage, setShowMessage, showButtons }) => {
  const { persons } = useContext(AuthContext);
  if (!showButtons.multiTable)
    return (
      <SectionSelector
        setMessage={setMessage}
        setShowMessage={setShowMessage}
      />
    );

  return <InfoTableHighRisk rows={persons} title="Asegurados Guardados" />;
};
