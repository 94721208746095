import { InputLabel, MenuItem, TextField } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import useStyles from "./styles";
import { EndosoContext } from "pages/endoso/context";
import { AuthContext } from "context/context";

export const FormBenPref = ({ data }) => {
    const classes = useStyles();
    const [seccion, setSeccion] = useState('');
    const [nombreBeneficiario, setNombreBeneficiario] = useState('');
    const contrato = data.oficina + '-' + data.ramo + '-' + data.poliza;
    const { setFinishR21 } = useContext(EndosoContext);
    const { sections21 } = useContext(AuthContext);
    useEffect(() => {
        if (seccion && nombreBeneficiario && contrato) {
            setFinishR21({
                seccion,
                nombreBeneficiario,
                contrato,
                dsSeccion: sections21.find((s) => s.clave === seccion)?.descripcion
            }
            );
        } else {
            setFinishR21({});
        }
    }, [seccion, nombreBeneficiario, contrato, setFinishR21]); // eslint-disable-line

    const handleFormatInput = (e) => {
        if(e.target.value === ' ') return
        let value = e.target.value;
        value = value.replace(/\s{2,}/g, ' ');
        const regex = /^[a-zA-Z\s]*$/;
        if (regex.test(value)) {
            value = value.substring(0, 50);
            value = value.toUpperCase();
            setNombreBeneficiario(value);
        }
    };
    
    return (
        <div className={classes.root}>
            <div>
                <InputLabel className={classes.label} htmlFor="seccion">Sección (*)</InputLabel>
                <TextField
                    select
                    value={seccion}
                    onChange={(e) => setSeccion(e.target.value)}
                    fullWidth
                >
                    {sections21?.map((option) => (
                        <MenuItem key={option.clave} value={option.clave}>
                            {option.descripcion}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div>
                <InputLabel htmlFor="nombreBeneficiario">Nombre del Beneficiario Preferente (*)</InputLabel>
                <TextField
                    value={nombreBeneficiario}
                    onChange={(e) => handleFormatInput(e)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="nombreBeneficiario"
                    className={classes.input}
                />
            </div>
            <div>
                <InputLabel htmlFor="contrato">Contrato (*)</InputLabel>
                <TextField
                    value={contrato}
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="contrato"
                    disabled={true}
                    className={classes.input}
                />
            </div>
        </div>
    );
};
