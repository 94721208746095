import { makeStyles } from "@material-ui/core";
import { colors } from "../../utils";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    columnGap: "16px",
  },
  checkBox: {
    flex: 1,
    display: "flex",
    padding: "4px 12px",
    borderRadius: "19px",
    alignItems: "center",
    "&:hover": { cursor: "pointer" },
    transition: ".3s",
  },
  disabled: {
    "& #icon": { color: colors.disabled, fontSize: "30px" },
    "& #label": { color: colors.blueDarken, fontSize: "15px" },
  },
  active: {
    background: colors.blueLighten,
    "& #icon": { color: colors.disabled, fontSize: "30px" },
    "& #label": { color: colors.white, fontSize: "15px" },
  },
});

export default useStyles;
