import { useContext } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { AltaContext } from "../../../../../context";
import { TitularesTable, ModalForm, ResultTable } from "../../components";
import { useStyles } from "./styles";
import { GroupAddRounded } from "@material-ui/icons";
export const TraditionalSave = () => {
  const classes = useStyles();
  const { openModal, dataLoaded, savedPersons } = useContext(AltaContext);

  if (!dataLoaded)
    return (
      <Box className={classes.container}>
        <Typography variant="h6" color="primary">
          No se cargó la información
        </Typography>
      </Box>
    );
  if (Boolean(savedPersons)) return <ResultTable />;
  return (
    <div className={classes.root}>
      <TitularesTable />

      <IconButton
        variant="contained"
        className={classes.button}
        size="small"
        onClick={() => openModal(undefined)}
      >
        <GroupAddRounded />
      </IconButton>

      <ModalForm />
    </div>
  );
};
