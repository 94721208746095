import { generarInfoTabla, dateToString } from "..";

export const rulesBySix = async (
  data,
  oldData,
  dataSend,
  setMessage,
  setShowMessage,
  formDta,
  endoso,
  setInfoTable,
  setShowButtons,
  showButtons
) => {
  if (dataSend.IDPERSON) {
    const body = {
      nivel: formDta.nivel,
      orden: 16,
      solicitud: formDta.noSolicitud,
      valor: dataSend.IDPERSON,
    };
    const guardaResponse = await endoso.saveEndoso(body);
    if (guardaResponse.data?.codigo === 1) {
      const body = {
        nivel: formDta.nivel,
        orden: 17,
        solicitud: formDta.noSolicitud,
      };
      const saveResponse = await endoso.saveEndoso(body);
      if (saveResponse.data?.codigo === 1) {
        setInfoTable(generarInfoTabla(oldData, dataSend, data.tipend));
        setShowButtons({
          ...showButtons,
          infoTable: true,
          domicilio: false,
        });
      } else {
        setMessage({
          text:
            saveResponse.data?.mensaje ||
            "SU SOLICITUD NO HA PODIDO SER PROCESADA",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      setMessage({
        text:
          guardaResponse.data?.mensaje ||
          "SU SOLICITUD NO HA PODIDO SER PROCESADA",
        severity: "warning",
      });
      setShowMessage(true);
    }
  } else {
    if (dataSend.TIPPER) {
      const camposVacios = { ...dataSend };

      if (dataSend.TIPPER === "2") {
        delete camposVacios.NOMBR1;
        delete camposVacios.NOMBR2;
        delete camposVacios.DSAPEL1;
        delete camposVacios.DSAPEL2;
      } else {
        delete camposVacios.NOMBRE;
        delete camposVacios.NOMBR2;
      }

      if (Object.values(camposVacios).filter((e) => !e).length > 0) {
        setMessage({
          text: "TODOS LOS CAMPOS SON REQUERIDOS",
          severity: "warning",
        });
        setShowMessage(true);
      } else if (dataSend.RFC.length < 9) {
        setMessage({
          text: "FORMATO DE RFC INVALIDO",
          severity: "warning",
        });
        setShowMessage(true);
      } else if (dataSend.HCLAVE.length < 3) {
        setMessage({
          text: "LA HOMOCLAVE DEBE TENER 3 CARACTERES",
          severity: "warning",
        });
        setShowMessage(true);
      } else {
        const body = {
          idPersona: null,
          tipoIdentificador: data.tipend === 12 ? "0" : "1",
          primerNombre: dataSend.TIPPER === "1" ? dataSend.NOMBR1 : null,
          segundoNombre: dataSend.TIPPER === "1" ? dataSend.NOMBR2 : null,
          nombre:
            dataSend.TIPPER === "1"
              ? `${dataSend.NOMBR1} ${dataSend.NOMBR2 || ""} ${
                  dataSend.DSAPEL1
                } ${dataSend.DSAPEL2}`
              : dataSend.NOMBRE,
          apPaterno: dataSend.TIPPER === "1" ? dataSend.DSAPEL1 : null,
          apMaterno: dataSend.TIPPER === "1" ? dataSend.DSAPEL2 : null,
          fchNacimineto: dateToString(dataSend.FECNAC),
          fechaNacimiento: false,
          rfc: dataSend.RFC + dataSend.HCLAVE,
          homoClave: dataSend.HCLAVE,
          razonSocial:
            dataSend.TIPPER === "1"
              ? "FISICA"
              : dataSend.TIPPER === "2"
              ? "MORAL"
              : "EMPRESA",
          accion: "I",
          sexo:
            dataSend.TIPPER === "2"
              ? "E"
              : dataSend.SEXO === "M"
              ? "MUJER"
              : "HOMBRE",
        };

        const guardaPersona = await endoso.savePerson(body);
        if (guardaPersona.data?.codigo === 1) {
          const adressBody = {
            accion: "I",
            calle: dataSend.DOMIC,
            ciudad: dataSend.OTPOB,
            codigoPostal: dataSend.CP,
            colonia: dataSend.COLONI,
            estado: dataSend.PROVIN,
            idCiudad: formDta.datosEndoso.find(
              (dato) => dato.acronimo === "OTPOB"
            )?.valorAnterior,
            idColonia: formDta.datosEndoso.find(
              (dato) => dato.acronimo === "COLONI"
            )?.valorAnterior,
            idEstado: formDta.datosEndoso.find(
              (dato) => dato.acronimo === "PROVIN"
            )?.valorAnterior,
            idPais: formDta.datosEndoso.find((dato) => dato.acronimo === "PAIS")
              ?.valorAnterior,
            idPersona: guardaPersona.data?.idPersona,
            municipio: dataSend.OTPOB,
            numeroExterior: dataSend.NUMERO,
            numeroInterior: dataSend.OTPISO,
            ordenDomicilio: null,
            pais: dataSend.PAIS,
            telefono: dataSend.TELEFO,
            tipoDomicilio: "1",
          };
          const guardaDomicilio = await endoso.guardarDomicilio(adressBody);
          if (guardaDomicilio.data?.codigo === 1) {
            const requestTwo = {
              nivel: formDta.nivel,
              orden: 16,
              solicitud: formDta.noSolicitud,
              valor: guardaPersona.data?.idPersona,
            };
            const submitTwoResponse = await endoso.saveEndoso(requestTwo);
            if (submitTwoResponse.data?.codigo === 1) {
              const requestTwo = {
                nivel: formDta.nivel,
                orden: 17,
                solicitud: formDta.noSolicitud,
                valor: guardaDomicilio.data?.idDomicilio,
              };
              const submitTwoResponse = await endoso.saveEndoso(requestTwo);
              if (submitTwoResponse.data?.codigo === 1) {
                setInfoTable(generarInfoTabla(oldData, dataSend, data.tipend));
                setShowButtons({
                  ...showButtons,
                  infoTable: true,
                  domicilio: false,
                });
              }
            }
          }
        }
      }
    } else {
      setMessage({
        text: "TODOS LOS CAMPOS SON REQUERIDOS",
        severity: "warning",
      });
      setShowMessage(true);
    }
  }
};
