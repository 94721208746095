import { Fragment } from "react";
import { InputLabel, TextField } from "@material-ui/core";
import useStyles from "../styles";

export const Input = ({
  label,
  name,
  onChange,
  required,
  maxLenght,
  defaultValue,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <InputLabel>{label + (required ? " (*)" : "")}</InputLabel>
      <TextField
        {...rest}
        className={classes.input}
        variant="outlined"
        name={name}
        size="small"
        onChange={onChange}
        fullWidth
        value={defaultValue ?? ""}
        inputProps={{
          ...Boolean(maxLenght && { maxLenght: maxLenght }),
          style: { textTransform: "uppercase" },
        }}
      />
    </Fragment>
  );
};
