import { Fragment, useContext, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { EndosoContext } from "../../../../../context";
import useStyles from "./styles";

export const SuccessFileTable = () => {
  const classes = useStyles();
  const { filesResponse } = useContext(EndosoContext);
  const rowsToShow = useMemo(() => {
    const successRows = filesResponse.filter((row) => row.codigo);
    return successRows;
  }, [filesResponse]);
  const columns = [
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "categoria",
      headerName: "Categoría",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <span style={{ textAlign: "center", width: "100%" }}>
          {row.categoria === "001"
            ? "ALUMNO/ASEGURADO"
            : row.categoria === "001"
            ? "DOCENTE/ADMINISTRATIVO"
            : row.categoria}
        </span>
      ),
    },
    {
      field: "sexo",
      headerName: "Sexo",
      flex: 0.83,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "fchNacimineto",
      headerName: "Fecha de Nacimiento",
      flex: 0.8,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
  /* methods */
  const sortRows = () => {
    const sortedRows = rowsToShow.map((row, index) => ({ ...row, id: index }));
    sortedRows.push({
      nombre: "",
      categoria: "Importe póliza Anterior",
      sexo: "Nuevo importe póliza",
      fchNacimineto: "Diferencia de monto",
      id: 9998,
    });
    sortedRows.push({
      nombre: "",
      categoria: rowsToShow[0]?.importeAnterior || "",
      sexo: rowsToShow[0]?.importeActual || "",
      fchNacimineto: rowsToShow[0]?.importeDiferencia || 0,
      id: 9999,
    });
    return sortedRows;
  };

  return (
    <Fragment>
      <DataGrid
        columns={columns}
        rows={sortRows()}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[8]}
        pageSize={8}
        pagination
      />
    </Fragment>
  );
};
