import { makeStyles } from "@material-ui/core";
import { colors } from "../../utils";

const useStyles = makeStyles({
  root: {
    background: colors.white,
    borderRadius: "20px",
    color: colors.blueLighten,
    alignItems: "center",
    minHeight: "38px",
    minWidth: "10px",
    width: "180px",
    padding: "0px 1px",
  },
  tabIndicator: {
    background: colors.blueLighten,
    borderRadius: "20px",
    height: "30px",
  },
  tabs: {
    marginLeft: "4px",
    padding: "0px 4px",
    minHeight: "30px",
    zIndex: 2,
    minWidth: "10px",
    background: "none",
    borderRadius: "20px",
    textTransform: "none",
    "&.Mui-selected": {
      color: colors.white,
      padding: "0px 9px",
    },
  },
});

export default useStyles;
