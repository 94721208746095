import { Fragment, useContext, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContext } from "../context/context";
import { Comments, DashBoard, Endosos, Reports } from "../pages";
import { AlertModal, Header, SnackBar } from "../shared/components";
import { EndosoProvider } from "../pages/endoso/context";

const isInterno = () => {
  const url = window.location.href;
  return url.includes("interno");
};

const authToken = (token, setToken, timerCount) => {
  const normalToken = process.env.REACT_APP_SECRET;
  const internalToken = process.env.REACT_APP_SECRET_INTERNO;

  const normalUrl = process.env.REACT_APP_HOME_URL;
  const internalUrl = process.env.REACT_APP_HOME_URL_INTERNO;
  const interno = isInterno();
  jwt.verify(token, interno ? internalToken : normalToken, (error, decode) => {
    if (error) {
      console.log(error);
      window.location.replace(interno ? internalUrl : normalUrl);
    } else {
      const body = { ...decode, token: token, isInterno: interno };
      localStorage.setItem("token", JSON.stringify(body));
      setToken(body);
      timerCount();
      window.location = "/";
    }
  });
};

const verifyToken = (token, interno) => {
  if (token) {
    if (typeof interno === "boolean") {
      const normalToken = process.env.REACT_APP_HOME_URL;
      const internalToken = process.env.REACT_APP_HOME_URL_INTERNO;
      const normalSecret = process.env.REACT_APP_SECRET;
      const internalSecret = process.env.REACT_APP_SECRET_INTERNO;
      const validation =
        jwt.verify(
          token.token,
          interno ? internalSecret : normalSecret,
          (error) => {
            if (error) {
              window.location.replace(interno ? internalToken : normalToken);
            } else {
              return true;
            }
          }
        ) || false;
      return validation;
    } else if (Object.values(token) === 0) {
      window.location.replace(process.env.REACT_APP_HOME_URL);
    }
  } else {
    window.location.replace(process.env.REACT_APP_HOME_URL);
  }
};

const Routes = () => {
  const { token, setToken, timer, setTimer, isInterno, setIsInterno } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);
  const [timerStart, setTimerStart] = useState();
  const windowUrl = window.location.href;
  const url = new URL(windowUrl);
  const tokenUrl = url.searchParams.get("token");

  const removeToken = () => {
    localStorage.removeItem("token");
    setToken({});
  };
  useEffect(() => {
    if (tokenUrl) {
      removeToken();
      authToken(tokenUrl, setToken, timerCount);
    }
    setLoading(false);

    if (token && Object.values(token).length > 0) {
      timerCount();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (timer === 300) {
      setOpenModal(true);
    }
    if (timer === 330 && openModal) {
      logOut();
    }
    if (Object.values(token ?? {}).length > 0) setIsInterno(token.isInterno);
    // eslint-disable-next-line
  }, [timer]);

  /* methods */
  const stopAllTimer = () => {
    clearInterval(timerStart);
    setStopTimer(true);
  };
  const logOut = () => {
    stopAllTimer();
    clearInterval(timerStart);
    setStopTimer(true);
    setOpenModal(false);
    localStorage.removeItem("token");
    setToken({});
    verifyToken(token, isInterno);
  };
  const timerCount = () => {
    setTimerStart(
      setInterval(
        (setTimer) => {
          setTimer((prev) => prev + 1);
        },
        1000,
        setTimer
      )
    );
  };
  if (!loading && !tokenUrl) {
    return (
      <Fragment>
        <Router>
          {verifyToken(token || {}, isInterno) && (
            <Fragment>
              <Header stopTimer={stopAllTimer} />
              <Switch>
                <Route exact path="/" component={DashBoard} />
                <Route exact path="/comentarios" component={Comments} />
                <Route exact path="/reportes" component={Reports} />
                <EndosoProvider>
                  <Route exact path="/endosos" component={Endosos} />
                </EndosoProvider>
                {/*  <Route exact path="/login" component={Login} /> */}
              </Switch>
            </Fragment>
          )}
        </Router>
        <AlertModal
          open={openModal}
          close={() => {
            logOut();
            setOpenModal(false);
          }}
          title={"Atención"}
          message="No se ha detectado actividad en la pagina por 5 minutos, desea continuar?"
          SuccessText="Si"
          success={() => {
            setOpenModal(false);
            setTimer(0);
          }}
        />
        <SnackBar
          HandleShow={setLoading}
          show={stopTimer}
          severity={"warning"}
          text={"Por favor vuelve a iniciar sesion"}
          time={2000}
        />
      </Fragment>
    );
  } else {
    return "";
  }
};

export default Routes;
