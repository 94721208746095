import React, { useContext, useEffect } from 'react';
import useStyles from './styles';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { EndosoContext } from 'pages/endoso/context';
import { Tooltip } from '@material-ui/core';

export const LowLocation23 = ({ data, info }) => {
    const classes = useStyles();
    const { setRows23, setInfoTable23, createMessage } = useContext(EndosoContext);

    const columns = [
        {
            field: "ubicacion",
            headerName: "Ubicación",
            flex: 0.5,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.ubicacion || ""}>
                    <span className={classes.tableCellTrucate} >
                        {row.ubicacion || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "dsnombre",
            headerName: "Nombre Completo",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.dsnombre || ""}>
                    <span className={classes.tableCellTrucate} >
                        {row.dsnombre || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "rfc",
            headerName: "RFC",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.rfc || ""}>
                    <span className={classes.tableCellTrucate} >
                        {row.rfc || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "calle",
            headerName: "Calle",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.calle || ""}>
                    <span className={classes.tableCellTrucate} >
                        {row.calle || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "poblacion",
            headerName: "Población",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.poblacion || ""}>
                    <span className={classes.tableCellTrucate} >
                        {row.poblacion || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "codigoPostal",
            headerName: "Código Postal",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
            renderCell: ({ row }) => (
                <Tooltip title={row.codigoPostal || ""}>
                    <span className={classes.tableCellTrucate} >
                        {row.codigoPostal || "No disponible"}
                    </span>
                </Tooltip>
            ),
        },
    ];

    const rows = info?.data?.asegurados?.map((asegurado, index) => ({
        id: index + 1,
        ubicacion: asegurado.ubicacion,
        dsnombre: asegurado.nombre,
        rfc: asegurado.rfc,
        calle: asegurado.calle,
        poblacion: asegurado.poblacion,
        codigoPostal: asegurado.codigoPostal,
    }));

    useEffect(() => {
        setInfoTable23(rows)
        setRows23([]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectedRows = (selectedRows) => {
        if (selectedRows.length === 0) {
            setRows23([]);
        } else if (selectedRows.length === rows.length) {
            createMessage({
                text: "No puedes dar de baja todas las ubicaciones.",
                variant: "warning",
            });
            setRows23([]);
        } else {
            setRows23(
                selectedRows.map((selectedRow) => ({
                    oficina: data.oficina,
                    estado: 'M',
                    ramo: data.ramo,
                    poliza: data.poliza,
                    situacion: selectedRow,
                }))
            );
        }
    };


    return (
        <DataGrid
            columns={columns}
            rows={rows || []}
            className={classes.root}
            disableSelectionOnClick
            disableColumnMenu
            rowsPerPageOptions={[4]}
            pageSize={4}
            pagination
            checkboxSelection
            getRowId={(row) => row.ubicacion}
            onSelectionModelChange={handleSelectedRows}
            components={{
                NoRowsOverlay: () => (
                    <GridOverlay>No se encontró información</GridOverlay>
                ),
            }}
        />
    );
};
