import { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router";
import { Typography, Hidden, Tooltip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { AuthContext } from "../../../../context/context";
import { CardMobile, SearchBar, Spinner } from "../../../../shared/components";
import useStyles from "./styles";
import { searchByPoliza } from "../../../../shared/hooks";
import { differenceInCalendarDays, parse } from "date-fns";
import { capitalize } from "shared/utils";

const TableSections = ({
  title,
  rows = [],
  setMessage,
  setShowMessage,
  type = 1,
}) => {
  const classes = useStyles();
  /* state */
  const { dispatch, token, setTimer, setSections21, setShowMonthlySalary } =
    useContext(AuthContext);
  /* history */
  const history = useHistory();
  const columns = [
    {
      field: "noSolicitud",
      headerName: "No. Solicitud",
      flex: 1.2,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "descEstatus",
      headerName: "Estatus",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Tooltip title={capitalize(row.descEstatus) || ""}>
          <span className={classes.tableCellTrucate}>
            {capitalize(row.descEstatus) || "No disponible"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "oficina",
      headerName: "Oficina",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Tooltip title={row.descOficina || ""}>
          <span className={classes.tableCellTrucate}>
            {row.oficina || "No disponible"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "ramo",
      headerName: "Ramo",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "poliza",
      headerName: "No. de póliza",
      flex: 1.2,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "contratante",
      headerName: "Contratante",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Tooltip title={capitalize(row.contratante) || ""}>
          <span className={classes.tableCellTrucate}>
            {capitalize(row.contratante) || "No disponible"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "descTipoEndoso",
      headerName: "Tipo Endoso",
      flex: 1.2,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Tooltip title={row.descTipoEndoso || ""}>
          <span
            className={classes.tableCellTrucate}
            style={{ textAlign: "left" }}
          >
            {row.descTipoEndoso || "No disponible"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "fchEmision",
      headerName: "Fecha",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <span className={classes.tableCellTrucate}>
          {row.fchEmision || "No disponible"}
        </span>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  /* search bar */
  const [search, setSearch] = useState("");
  /* pagination mobile */
  const [pagination, setPagination] = useState(0);
  const cardsPerPage = 2;
  const handleChangePagination = (e, value) => {
    setTimer(0);
    setPagination(value - 1);
  };

  /* methods */

  const handleSearch = (e) => {
    setTimer(0);
    setSearch(e.target.value);
  };

  const submitData = async (row) => {
    if (row.descEstatus !== "CANCELADA") {
      setTimer(0);
      setLoading(true);
      searchByPoliza(
        row,
        setLoading,
        dispatch,
        history,
        setMessage,
        "",
        setShowMessage,
        2,
        token.cdUsuario,
        setSections21,
        setShowMonthlySalary
      );
    }
  };

  const filterData = (rows) => {
    return rows.filter(
      (item) =>
        item.noSolicitud
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.descEstatus?.toLowerCase().includes(search.toLowerCase()) ||
        item.descOficina?.toLowerCase().includes(search.toLowerCase()) ||
        item.ramo?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.poliza?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.contratante?.toLowerCase().includes(search.toLowerCase()) ||
        item.descTipoEndoso?.toLowerCase().includes(search.toLowerCase()) ||
        item.fchEndoso?.toString().toLowerCase().includes(search.toLowerCase())
    );
  };

  const numberOfPages = Math.ceil(filterData(rows).length / 2);

  return (
    <Fragment>
      {loading && <Spinner />}

      <div className={classes.headerComponent}>
        <Typography variant="h5" className={classes.title} align="left">
          <strong>{title}</strong>
        </Typography>
        <SearchBar change={handleSearch} search={search} />
      </div>
      <Hidden smDown>
        <DataGrid
          columns={columns}
          rows={filterData(rows)}
          className={classes.root}
          onCellClick={(e) => {
            const emissionDate = parse(
              e.row.fchEmision,
              "dd/MM/yyyy",
              new Date()
            );
            const diffDays = differenceInCalendarDays(new Date(), emissionDate);
            if (
              type === 1 &&
              e.row.descEstatus !== "CANCELADA" &&
              diffDays <= 30
            ) {
              submitData(e.row);
            }
          }}
          disableColumnMenu
          disableSelectionOnClick
          rowsPerPageOptions={[5]}
          pageSize={5}
          pagination
          getRowClassName={(params) => {
            const { descEstatus, fchEmision } = params.row;
            if (descEstatus === "CANCELADA") {
              return classes.disabledRow;
            }
            if (descEstatus !== "PENDIENTE") {
              const emissionDate = parse(fchEmision, "dd/MM/yyyy", new Date());
              const diffDays = differenceInCalendarDays(
                new Date(),
                emissionDate
              );
              if (diffDays > 30) {
                return classes.exceed30DaysRow;
              }
            }
            return "";
          }}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay>No se encontró información</GridOverlay>
            ),
          }}
          getRowId={(row) => row.noSolicitud}
        />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.rowsMobile}>
          {filterData(rows)
            .slice(
              pagination * cardsPerPage,
              pagination * cardsPerPage + cardsPerPage
            )
            .map((row, index) => (
              <CardMobile
                titles={columns}
                data={row}
                key={index}
                submit={submitData}
              />
            ))}
        </div>

        <div className={classes.pagination}>
          <Pagination
            shape="rounded"
            count={numberOfPages}
            onChange={handleChangePagination}
            id="pagination"
          />
        </div>
      </Hidden>
    </Fragment>
  );
};

export default TableSections;
