import { Grid, InputLabel, TextField } from "@material-ui/core";
import useStyles from "../styles";

export const Input = ({ label, name, onChange, required, onlyLetters }) => {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <InputLabel>{label + (required ? " (*)" : "")}</InputLabel>
      <TextField
        className={classes.input}
        variant="outlined"
        name={name}
        size="small"
        onChange={onChange}
        fullWidth
        inputProps={{
          style: { textTransform: "uppercase" },
        }}
        onInput={(e) => {
          if (onlyLetters)
            e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
        }}
      />
    </Grid>
  );
};
