import { generarInfoTabla } from "..";
export const rulesByeight = async (
  data,
  setMessage,
  setShowMessage,
  formDta,
  endoso,
  setInfoTable,
  setShowButtons,
  showButtons,
  coberturas,
  rowsTable
) => {
  if (coberturas.values && coberturas.values.length > 0) {
    const body = {
      garantia: coberturas.values[0],
      oficina: data.oficina,
      poliza: data.poliza,
      ramo: data.ramo,
      solicitud: formDta.noSolicitud,
    };
    const coberturaResponse = await endoso.guardarCobertura(body);
    if (coberturaResponse.data?.codigo === 1) {
      const body = {
        situacion: rowsTable.codigo?.toString(),
        solicitud: formDta.noSolicitud,
      };
      const situacionResponse = await endoso.guardarSituacion(body);
      if (situacionResponse.data?.codigo === 1) {
        const diferenciaResponse = await endoso.diferencia({
          solicitud: formDta.noSolicitud,
        });

        if (diferenciaResponse.data?.codigo === "1") {
          const clausulaResponse = await endoso.clausula({
            solicitud: formDta.noSolicitud,
          });
          if (clausulaResponse.data?.codigo === "1") {
            setMessage({
              text: "SE HA GUARDADO EL BORRADOR EXITOSAMENTE",
              severity: "success",
            });
            setShowMessage(true);
            setInfoTable(
              generarInfoTabla(
                diferenciaResponse.data?.importeAnterior || "",
                diferenciaResponse.data?.importeActual || "",
                data.tipend
              )
            );
            setShowButtons({
              ...showButtons,
              infoTable: true,
              domicilio: false,
            });
          } else {
            setMessage({
              text:
                clausulaResponse.data?.mensaje ||
                "SU SOLICITUD NO HA PODIDO SER PROCESADA",
              severity: "warning",
            });
            setShowMessage(true);
          }
        } else {
          setMessage({
            text:
              diferenciaResponse.data?.mensaje ||
              "SU SOLICITUD NO HA PODIDO SER PROCESADA",
            severity: "warning",
          });
          setShowMessage(true);
        }
      } else {
        setMessage({
          text:
            situacionResponse.data?.mensaje ||
            "SU SOLICITUD NO HA PODIDO SER PROCESADA",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      setMessage({
        text:
          coberturaResponse.data?.mensaje ||
          "SU SOLICITUD NO HA PODIDO SER PROCESADA",
        severity: "warning",
      });
      setShowMessage(true);
    }
  }
};
