import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils";
import theme from "styles/theme";

const useStyles = makeStyles({
  root: {
    background: colors.whiteDark,
    "& .MuiDataGrid-iconButtonContainer": {
      display: "none",
    },
    "& .header": {
      background: colors.blueLighten,
      color: colors.white,
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      background: colors.blueLighten,
    },
    "& .MuiDataGrid-cell": {
      textAlign: "center",
      "&:first-child": { color: colors.blueLighten },
      cursor: "pointer",
    },
  },
  tableCellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  headerComponent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "24px",
  },
  title: {
    margin: "16px 0 16px 24px",
    color: colors.blueDarken,
  },
  rowsMobile: { display: "flex", flexDirection: "column", rowGap: "24px" },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },
  exceed30DaysRow: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    "& .MuiDataGrid-cell": {
      "&:first-child": { color: theme.palette.action.disabled },
    },
    "&.MuiDataGrid-row:hover": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },
  disabledRow: {
    backgroundColor: "#ff000033",
    color: "#ff000088",
    "& .MuiDataGrid-cell": {
      "&:first-child": { color: "#ff000088" },
    },
    "&.MuiDataGrid-row:hover": {
      backgroundColor: "#ff000033",
      color: "#ff000088",
    },
  },
});

export default useStyles;
