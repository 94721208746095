import { Animation, ArgumentScale, Palette } from "@devexpress/dx-react-chart";
import { scaleBand } from "@devexpress/dx-chart-core";
import {
  AreaSeries,
  ArgumentAxis,
  Chart,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { curveCatmullRom, area } from "d3-shape";

const Area = (props) => (
  <AreaSeries.Path
    {...props}
    path={area()
      .x(({ arg }) => arg)
      .y1(({ val }) => val)
      .y0(({ startVal }) => startVal)
      .curve(curveCatmullRom)}
  />
);
const AreaChart = ({ data, palette, size = {} }) => {
  return (
    <div>
      <Chart data={data} height={size.height}>
        <Palette scheme={palette} />
        <ArgumentScale factory={scaleBand} />

        <ArgumentAxis />
        <ValueAxis />
        <AreaSeries
          valueField="value"
          argumentField="date"
          seriesComponent={Area}
        />
        <AreaSeries
          valueField="value2"
          argumentField="date"
          seriesComponent={Area}
        />

        <Animation />
      </Chart>
    </div>
  );
};

export default AreaChart;
