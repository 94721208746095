import { InputLabel } from "@material-ui/core";
import { Fragment } from "react";
import InputFiles from "react-input-files";
import useStyles from "./styles";

export const InputFile = ({ label, name, onChange, required }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <InputLabel>{label + (required ? " (*)" : "")}</InputLabel>
      <InputFiles accept=".xlsx" onChange={onChange}>
        <button className={classes.root}>{name ?? ""}</button>
      </InputFiles>
    </Fragment>
  );
};
