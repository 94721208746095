import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, Box, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./styles";

export const Filter = ({ data, search, ...props }) => {
  const classes = useStyles();
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    search(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchClick = (e) => {
    e.preventDefault();
    if (query.trim() === "") {
      search(data);
      return;
    }

    if (data) {
      const filteredData = data.filter((item) => {
        const itemWords = item.toLowerCase().split(" ");
        const queryWords = query.toLowerCase().split(" ");

        if (queryWords.length > itemWords.length) {
          return false;
        }

        for (let i = 0; i <= itemWords.length - queryWords.length; i++) {
          let match = true;
          for (let j = 0; j < queryWords.length; j++) {
            if (itemWords[i + j] !== queryWords[j]) {
              match = false;
              break;
            }
          }
          if (match) {
            return true;
          }
        }
        return false;
      });

      const parts = filteredData[0]?.split(' ')
      const firstPart = parts && parts[0]
      const secondPart = parts && parts.slice(1).join(' ')
      if (!isNaN(firstPart) && secondPart) {
        if (data.map((item) => item === firstPart && item + 1 === secondPart)) {
          search([secondPart])
        }
      }
      else {
        search(filteredData);

      };
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(event);
    }
  };

  return (
    <form onSubmit={handleSearchClick}>
      <Box className={classes.container}>
        <TextField
          className={classes.root}
          {...props}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" color="primary" variant="contained">
          Buscar
        </Button>
      </Box>
    </form>
  );
};
