const apiUrl = process.env.REACT_APP_BASE_URL;
async function GET(url) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

async function GETFILE(url, action) {
  try {
    fetch(apiUrl + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        action?.();
        var file = new Blob([blob], { type: "application/xlsx" });
        var fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.setAttribute("href", fileURL);
        a.download = "layout.xlsx";
        a.click();
        window.URL.revokeObjectURL(fileURL);
      });
    return { data: null, error: "" };
  } catch (error) {
    return { data: null, error: error };
  }
}

async function POST(url, body, type_data = false) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: type_data ? body : JSON.stringify(body),
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

async function POSTMEDIA(url, body, type_data = false) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "POST",
      headers: {
        /* "Content-Type": "multipart/form-data" */
      },
      body: body,
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

async function PUT(url, body) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: body };
  }
}

async function DELETE(url) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

export { GET, POST, PUT, DELETE, POSTMEDIA, GETFILE };
