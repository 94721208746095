import { createTheme } from "@material-ui/core/styles";
import { colors } from "../shared/utils";
import '../assets/fonts/fonts.css';
import { Fonts } from "./fonts";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blueDarken,
    },
  },
  typography: {
    fontFamily: Fonts.SuraSansRegular,
    h1: {
      fontFamily: Fonts.SuraSansBold,
    },
    h2: {
      fontFamily: Fonts.SuraSansBold,
    },
    h3: {
      fontFamily: Fonts.SuraSansBold,
    },
    h4: {
      fontFamily: Fonts.SuraSansBold,
    },
    h5: {
      fontFamily: Fonts.SuraSansBold,
    },
    h6: {
      fontFamily: Fonts.SuraSansBold,
    },
    subtitle1: {
      fontFamily: Fonts.SuraSansRegular,
    },
    subtitle2: {
      fontFamily: Fonts.SuraSansRegular,
    },
    body1: {
      fontFamily: Fonts.SuraSansRegular,
    },
    body2: {
      fontFamily: Fonts.SuraSansRegular,
    },
    button: {
      fontFamily: Fonts.SuraSansRegular,
    },
    caption: {
      fontFamily: Fonts.SuraSansRegular,
    },
    overline: {
      fontFamily: Fonts.SuraSansRegular,
    },
  },
  props: {
    /*  MuiButton: {
      variant: "contained",
      color: "primary",
    }, */
    MuiTextField: {
      variant: "outlined",
      size: "small",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        marginLeft: "16px",
        marginBottom: "6px",
        color: colors.blueDarken,
        fontSize: ".82rem",
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderRadius: "19px",
          },
          "&.MuiInputBase-root": {
            borderRadius: "19px",
            fontFamily: Fonts.SuraSansRegular,
          },
          "&.Mui-focused fieldset": {
            borderWidth: "1px",
          },
          "& input": {
            fontSize: ".75rem",
            fontFamily: Fonts.SuraSansRegular,
          },
          "& .MuiSelect-root": {
            fontSize: ".75rem",
            padding: "8.2px",
            fontFamily: Fonts.SuraSansRegular,
          },
          "& .MuiSelect-select:focus": {
            borderRadius: "19px",
            fontFamily: Fonts.SuraSansRegular,
          },
        },
      },
    },
    MuiPaper: {
      root: {
        "&.MuiPaper-root": {
          borderRadius: "19px",
          fontFamily: Fonts.SuraSansRegular,
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.blueLighten,
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: colors.blueLighten,
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: colors.blueLighten,
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiButton: {
      contained: {
        borderRadius: "20px",
        fontFamily: Fonts.SuraSansRegular,
      },
      textPrimary: {
        color: colors.blueLighten,
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    // quiero que a los demas componentes de mui se les aplique la fuente SuraSansRegular pero solo quiero que agregue el fontFamily a los componentes que no tengan el fontFamily definido
    MuiTypography: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiFormControl: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiOutlinedInput: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiSelect: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTable: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableBody: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableContainer: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableFooter: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableHead: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableRow: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTablePagination: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTableSortLabel: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTabs: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTab: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiToggleButton: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiToggleButtonGroup: {
      root: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: Fonts.SuraSansRegular,
      },
    },
  },
});

export default theme;
