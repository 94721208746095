import useStyles from "./styles";
import { Button } from "@material-ui/core";
import { ColorBox } from "../../../../shared/components";
import { colors } from "../../../../shared/utils";
import { endoso } from "../../../../services";
import { useHistory } from "react-router";
import { Fragment, useContext } from "react";
import { AuthContext } from "../../../../context/context";
import { EndosoContext } from "pages/endoso/context";
import CryptoJS from "crypto-js";

const FooterSection = ({
  submit,
  setSpinner,
  showButtons,
  spinner,
  formDta,
  setShowButtons,
  setMessage,
  setShowMessage,
  data,
  dataSend,
  setDataContractor,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { finishR21 } = useContext(EndosoContext);
  const { setPersons, confirmData, setConfirmData, setTimer, showContinue, setHandleContinue } = useContext(AuthContext);
  const key =  process.env.REACT_APP_URL_SECRET_KEY; 
  /* methods */
  const handleCancelRequest = async () => {
    setTimer(0);
    setSpinner(true);
    await endoso.cancelRequest(formDta.noSolicitud);
    setSpinner(false);
    setPersons([]);
    history.push("/");
  };
  const recoverData = async () => {
    setTimer(0);
    setSpinner(true);
    const configBody = {
      solicitud: formDta.noSolicitud,
      nivel: formDta.nivel,
      llave: formDta.llave ?? null,
      idPersona: null,
      idDomicilio: null,
    };
    const searchResponse = await endoso.configuration(configBody);
    if (searchResponse.data?.codigo === 1) {
      formDta.datosEndoso = searchResponse.data.datosEndoso;
      setShowButtons({ ...showButtons, infoTable: false });
    } else {
      setMessage({
        text: "SU SOLICITUD NO HA PODIDO SER PROCESADA",
        severity: "warning",
      });
      setShowMessage(true);
    }
    setSpinner(false);
  };

  const encryptUrl = (url) => {
    return CryptoJS.AES.encrypt(
      url, 
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    ).toString();
  };
  
  const encryptedDocuments = confirmData.documents?.map((doc) => ({
    ...doc,
    url: encryptUrl(doc.url), 
  }));

  const confirmRequest = async () => {
    setTimer(0);
    setSpinner(true);
    setHandleContinue(false)
    if (showButtons.infoTable) {
      if (data.tipend === 8) {
        const tarifaResponse = await endoso.tarifa({
          solicitud: formDta.noSolicitud,
        });
        if (tarifaResponse.data?.codigo === 1) {
          const body = {
            idSolicitud: formDta.noSolicitud,
            oficina: data.oficina,
            poliza: data.poliza,
            ramo: data.ramo,
            suplemento: formDta.suplemLogico,
            cdTipo: data.tipend >= 21 && data.tipend <= 23 ? 'P' : 'PE',
          };

          const confirmResponse = await endoso.confirma(body);
          if (dataSend) {
            setDataContractor(dataSend);
          }
          if (confirmResponse.data?.codigo === 1) {
            setMessage({
              text: "SE HA REALIZADO SU SOLICITUD SATISFACTORIAMENTE",
              severity: "success",
            });
            setShowMessage(true);
            setConfirmData(confirmResponse.data || {});
            setShowButtons({ ...showButtons, sendEmail: true });
          } else {
            setMessage({
              text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
              severity: "warning",
            });
            setShowMessage(true);
            const timer = setTimeout(() => history.push("/"), 2000);
            return () => clearTimeout(timer);
          }
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
        }
      } else {
        const body = {
          idSolicitud: formDta.noSolicitud,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
          suplemento: formDta.suplemLogico,
          cdTipo: data.tipend >= 21 && data.tipend <= 23 ? 'P' : 'PE',
        };

        const confirmResponse = await endoso.confirma(body);
        if (dataSend) {
          setDataContractor(dataSend);
        }
        setSpinner(true);
        if (confirmResponse.data?.codigo === 1) {
          if (data.tipend === 5) {
            await endoso.motivo({
              solicitud: formDta.noSolicitud,
              motivo: dataSend.SMOTANU,
            });
          }
          setMessage({
            text: "SE HA REALIZADO SU SOLICITUD SATISFACTORIAMENTE",
            severity: "success",
          });
          setShowMessage(true);
          setConfirmData(confirmResponse.data || {});
          setShowButtons({ ...showButtons, sendEmail: true });
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
          const timer = setTimeout(() => history.push("/"), 2000);
          return () => clearTimeout(timer);
        }
      }
      setSpinner(false);
    } else if (showButtons.multiTable) {
      const tarifaResponse = await endoso.tarifa({
        solicitud: formDta.noSolicitud,
      });
      if (tarifaResponse.data?.codigo === 1) {
        const body = {
          idSolicitud: formDta.noSolicitud,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
          suplemento: formDta.suplemLogico,
          cdTipo: data.tipend >= 21 && data.tipend <= 23 ? 'P' : 'PE',
        };

        const confirmResponse = await endoso.confirma(body);
        setSpinner(true);
        if (confirmResponse.data?.codigo === 1) {
          setMessage({
            text: "SE HA REALIZADO SU SOLICITUD SATISFACTORIAMENTE",
            severity: "success",
          });
          setShowMessage(true);
          setConfirmData(confirmResponse.data || {});
          setShowButtons({ ...showButtons, sendEmail: true });
        } else {
          setMessage({
            text: "OCURRIO UN PROBLEMA CON SU SOLICITUD",
            severity: "warning",
          });
          setShowMessage(true);
          const timer = setTimeout(() => history.push("/"), 2000);
          return () => clearTimeout(timer);
        }
        setPersons([]);
        setSpinner(false);
      } else {
        setMessage({
          text:
            tarifaResponse.data?.mensaje ||
            "OCURRIO UN PROBLEMA CON SU SOLICITUD",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      await submit();
    }
  };
  const handleSendEmail = async () => {
    setTimer(0);
    if (showButtons.formEmail) {
      setSpinner(true);
      const body = {
        subject: "DOCUMENTOS DE ENDOSOS SURA",
        from: "documentacion@segurossura.com.mx",
        to: confirmData.emailData?.emailOne,
        cc: [confirmData.emailData?.emailTwo || ""],
        idSolicitudEndoso: formDta.noSolicitud,
        textoEndoso: confirmData.emailData?.mensaje || null,
        mensajeAdicional: confirmData.emailData?.mensaje || null,
        documentos: encryptedDocuments,
      };
      const sendDocuments = await endoso.sendDocuments(body);

      if (sendDocuments.data?.codigo === 1) {
        setMessage({
          text: "SE HA REALIZADO SU SOLICITUD SATISFACTORIAMENTE",
          severity: "success",
        });
        setShowMessage(true);
        const timer = setTimeout(() => history.push("/"), 2000);
        return () => clearTimeout(timer);
      } else {
        setMessage({
          text:
            sendDocuments.data?.mensaje ||
            "OCURRIO UN PROBLEMA CON SU SOLICITUD",
          severity: "warning",
        });
        setShowMessage(true);
      }

      setSpinner(false);
    } else {
      if (confirmData.documents && confirmData.documents.length > 0) {
        setShowButtons({ ...showButtons, formEmail: true });
      } else {
        setMessage({
          text: "DEBE SELECCIONAR AL MENOS UN DOCUMENTO",
          severity: "warning",
        });
        setShowMessage(true);
      }
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <ColorBox color={colors.blueSoft} legend="Vigente" />

        <ColorBox color={colors.redSpecial} legend="Especial" />
      </div>
      <div className={classes.actions}>
        {!showButtons.sendEmail ? (
          <Fragment>
            {showButtons.infoTable &&
              data.tipend !== 8 &&
              data.tipend !== 6 &&
              data.tipend !== 7 &&
              data.tipend !== 16 &&
              data.tipend !== 20 &&
              data.tipend !== 21 &&
              data.tipend !== 22 &&
              data.tipend !== 23 && (
                <Button
                  variant="text"
                  className={classes.button}
                  onClick={recoverData}
                  disabled={spinner}
                >
                  <strong>Modificar</strong>
                </Button>
              )}
            <Button
              variant="text"
              className={classes.button}
              onClick={handleCancelRequest}
              disabled={spinner || !formDta.nivel}
            >
              {/*Documentacion: aca debo quitar condicioales para poder eliminar*/}
              <strong>Cancelar</strong>
            </Button>
            {showButtons.guardarEndoso && !formDta.mensaje && (
              <Button
                variant="text"
                className={classes.button}
                onClick={confirmRequest}
                disabled={
                  spinner ||
                  !formDta.nivel ||
                  (showContinue && (data.tipend === 16 || data.tipend === 14)) ||
                  (data.tipend === 6 && showButtons.disableSix) ||
                  (data.tipend === 21 && Object.keys(finishR21).length === 0) ||
                  (data.tipend === 22 && showButtons.disableTwentyTwo) ||
                  (data.tipend === 23 && showButtons.disableTwentyThree)
                }
              >
                {showButtons.infoTable || showButtons.multiTable ? (
                  <strong>Confirmar Endoso</strong>
                ) : (
                  <strong>Guardar Borrador</strong>
                )}
              </Button>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {showButtons.formEmail && (
              <Button
                variant="text"
                className={classes.button}
                onClick={() => {
                  setTimer(0);
                  setShowButtons({
                    ...showButtons,
                    formEmail: false,
                    disableSend: false,
                  });
                  setConfirmData({ ...confirmData, documents: [] });
                }}
                disabled={spinner || !formDta.nivel}
              >
                <strong>Modificar</strong>
              </Button>
            )}

            <Button
              variant="text"
              className={classes.button}
              disabled={spinner || !formDta.nivel}
              onClick={() => {
                setTimer(0);
                setConfirmData({});
                history.push("/");
              }}
            >
              <strong>Cancelar</strong>
            </Button>
            <Button
              variant="text"
              className={classes.button}
              onClick={handleSendEmail}
              disabled={showButtons.disableSend || spinner}
            >
              <strong>{showButtons.formEmail ? "Enviar" : "Notificar"}</strong>
            </Button>
          </Fragment>
        )}
      </div>
    </div >
  );
};

export default FooterSection;
