import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "8px",
  },
  checkBox: {
    display: "flex",
    justifyContent: "center",
  },
});

export default useStyles;
