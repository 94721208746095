import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../../../shared/utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
    background: colors.white,
    borderRadius: "16px",
    overflow: "hidden",
  },
  description: {
    background: colors.blueLighten,
    color: colors.white,
    borderBottom: `1px solid ${colors.white}`,
  },
  information: {
    borderBottom: `1px solid ${colors.greyLighten}`,
    "&:last-child": { border: "none" },
  },
}));
