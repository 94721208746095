import { Fragment, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { AccountCircleTwoTone, ArrowBack } from "@material-ui/icons";
import { images } from "../../utils";
import { AuthContext } from "../../../context/context";
import { SnackBar } from "../";
import useStyles from "./styles";

const Header = ({ stopTimer }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [anchor, setAnchor] = useState(null);
  const {
    setPersons,
    setTimer,
    showMessage,
    message,
    setSnackMessage,
    setToken,
    isInterno,
  } = useContext(AuthContext);
  const handleClick = (e) => {
    setTimer(0);
    setAnchor(e.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const normalToken = process.env.REACT_APP_HOME_URL;
  const internalToken = process.env.REACT_APP_HOME_URL_INTERNO;
  const options = [
    {
      text: "Cerrar sesion",
      action: () => {
        stopTimer();
        localStorage.removeItem("token");
        setToken({});
        window.location.replace(isInterno ? internalToken : normalToken);
      },
    },
  ];
  return (
    <Fragment>
      <SnackBar
        HandleShow={setSnackMessage}
        show={showMessage}
        severity={message.severity}
        text={message.text}
        time={2000}
        action={message.action}
      />
      <div className={classes.container}>
        <div className={classes.root}>
          <div className={classes.sections}>
            <img
              src={images.LogoSura}
              height={539.63 * 0.12}
              width={948.8 * 0.12}
              alt="logo"
            />

            <div className={classes.linkContainer}>
              <Button
                variant="text"
                className={classes.buttons}
                disableRipple
                component={Link}
                onClick={() => {
                  setTimer(0);
                }}
                to="/reportes"
              >
                Reportes
              </Button>
              <Button
                variant="text"
                className={classes.buttons}
                disableRipple
                component={Link}
                onClick={() => {
                  setTimer(0);
                }}
                to="/comentarios"
              >
                Comentarios
              </Button>
            </div>
          </div>
          <div className={classes.sections}>
            <IconButton
              id="profile"
              aria-label="user"
              onClick={handleClick}
              className={classes.position}
            >
              <AccountCircleTwoTone fontSize="large" />
            </IconButton>

            <Menu
              id="profile"
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {options.map((option, index) => {
                return (
                  <MenuItem onClick={option.action} key={index}>
                    <ListItemText secondary={option.text} />
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>

        {pathname !== "/" && (
          <div className={classes.back}>
            <Button
              variant="text"
              color="primary"
              startIcon={<ArrowBack />}
              component={Link}
              to="/"
              onClick={() => {
                setTimer(0);
                setPersons([]);
              }}
            >
              Volver
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Header;
