import { Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";

const CardName = ({ data }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant="subtitle1" color="initial" align="center">
        <strong>{data.nombre}</strong>
      </Typography>
    </Paper>
  );
};

export default CardName;
