export const useColumns = () => {
  return [
    {
      field: "titular",
      headerName: "Código",
      flex: 0.3,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <div style={{ width: "100%" }}>{row.titular ?? "Por confirmar"}</div>
      ),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 0.5,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "familiares",
      headerName: "N° Familiares",
      flex: 0.2,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
};
