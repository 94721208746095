import BigChartSection from "./sections/bigChartSection";
import CircleChartSection from "./sections/circleChartSection";
import ReportData from "./sections/reportData";
import { endoso } from "../../services";
import useStyles from "./styles";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "../../shared/components";
import { Fade } from "@material-ui/core";
import { AuthContext } from "../../context/context";
import { format } from "date-fns";

const formatDate = (date) => {
  const parts = date.split("/");
  return `${parts[1]}-${parts[0]}-${parts[2]}`;
};

const Reports = () => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  /* data */
  const [oficce, setOffice] = useState([]);
  const [ramo, setRamo] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [endosos, setEndosos] = useState({});
  const [chartsData, setChartsData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const circleChartData = chartsData.sencondChart || [];
  const areaChartData = chartsData.firstChart || [];
  /* methods */
  const handleChangeData = (e) => {
    setTimer(0);
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmitData = async () => {
    setTimer(0);
    setIsSubmit(true);
    const reportResponse = await endoso.getReport(data);
    setEndosos(reportResponse.data || {});
    setIsSubmit(false);
  };
  const sortChartsData = () => {
    if (endosos && Object.values(endosos).length > 0) {
      const inicio = new Date(formatDate(endosos.fchInicio));
      const fin = new Date(formatDate(endosos.fchFin));
      const medio = new Date((inicio.getTime() + fin.getTime()) / 2);

      const segundo = new Date((inicio.getTime() + medio.getTime()) / 2);
      const tercero = new Date((medio.getTime() + fin.getTime()) / 2);

      let reportes = endosos.reporte;
      const terminados = reportes.filter(
        (reporte) => reporte.statusEndoso === "M"
      );
      const solicitados = reportes.filter(
        (reporte) => reporte.statusEndoso === "W"
      );

      const getReportsByDates = (reports, intialDate, endDate) => {
        const dateReports = reports.filter((report) => {
          const reportDate = new Date(formatDate(report.fchInicio));
          if (reportDate >= intialDate && reportDate < endDate) {
            return report;
          }
          return undefined;
        });
        return dateReports.length;
      };

      getReportsByDates(terminados, medio, fin);
      setChartsData({
        firstChart: [
          {
            date: format(inicio, "dd/MM/yyyy"),
            value: getReportsByDates(terminados, inicio, segundo),
            value2: getReportsByDates(solicitados, inicio, segundo),
          },
          {
            date: format(segundo, "dd/MM/yyyy"),
            value: getReportsByDates(terminados, segundo, medio),
            value2: getReportsByDates(solicitados, segundo, medio),
          },
          {
            date: format(medio, "dd/MM/yyyy"),
            value: getReportsByDates(terminados, medio, tercero),
            value2: getReportsByDates(solicitados, medio, tercero),
          },
          {
            date: format(tercero, "dd/MM/yyyy"),
            value: getReportsByDates(terminados, tercero, fin),
            value2: getReportsByDates(solicitados, tercero, fin),
          },
          {
            date: format(fin, "dd/MM/yyyy"),
            value: getReportsByDates(terminados, fin, new Date()),
            value2: getReportsByDates(solicitados, fin, new Date()),
          },
        ],
        sencondChart: [
          { name: "Terminados", value: terminados.length },
          { name: "Pendientes", value: solicitados.length },
        ],
      });
    }
  };

  const generateReport = async () => {
    setTimer(0);
    endoso.generateReport(data);
  };
  /* fetch */
  const getSelects = async () => {
    const oficceResponse = await endoso.getOficce();
    const ramoResponse = await endoso.getRamo();
    setOffice(oficceResponse.data?.oficinas || []);
    setRamo(ramoResponse.data?.ramos || []);
    setLoading(false);
  };

  useEffect(() => {
    getSelects();
    sortChartsData();

    // eslint-disable-next-line
  }, []);
  useEffect(sortChartsData, [endosos]);
  useEffect(() => {
    if (data.dateStart && data.dateEnd && data.oficce && data.ramo)
      setDisabled(false);
    else setDisabled(true);
  }, [data]);
  if (loading) {
    return <Spinner />;
  } else {
    return (
      <Fade in={!loading} timeout={600}>
        <div className={classes.root}>
          {isSubmit && <Spinner />}
          <div className={classes.sections}>
            <ReportData
              oficces={oficce}
              ramos={ramo}
              change={handleChangeData}
              data={data}
              submit={handleSubmitData}
              disabled={disabled}
              generateReport={generateReport}
            />
          </div>
          <div className={classes.sections}>
            <div className={classes.subSections}>
              <BigChartSection data={areaChartData} />
            </div>

            <div className={classes.subSections}>
              {/*  <div className={classes.sencondCharts}></div> */}
              <div className={classes.sencondCharts}>
                <CircleChartSection data={circleChartData} />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
};

export default Reports;
