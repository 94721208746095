import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: { display: "flex", flexDirection: "column", rowGap: "16px" },
  input: { width: "50%" },

  submit: {
    marginTop: "16px",
    borderRadius: "19px",
    width: "25%",
  },
});

export default useStyles;
