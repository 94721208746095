import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils/colors";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    boxSizing: "border-box",
    padding: "16px",
  },
  sections: {
    flex: 0.11,
    "&:first-child": {
      display: "flex",
      justifyContent: "space-between",
      alingItems: "center",
      "& h5": { marginTop: "4px", color: colors.blueDarken },
    },
    "&:last-child": { flex: 0.89 },
  },
  subSections: { display: "flex", columnGap: "16px" },
});

export default useStyles;
