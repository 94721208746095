import { Fragment } from "react";
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "./styles";
import { rfcRules } from "./rules";

const DinamicForm = ({ inputs = [], handlechange, dataSend, typeEnd }) => {
  const classes = useStyles();
  return (
    <Fragment>
      {inputs.map((input, index) => {
        if (input.type === "text" && input.visible) {
          if (input.acronimo === "SERIE") {
            return (
              <div key={input.idDato}>
                <InputLabel htmlFor={input.label}>
                  {input.descripcionActual + (input.obligatorio ? " (*)" : "")}
                </InputLabel>

                <TextField
                  className={classes.input}
                  variant="outlined"
                  name={input.acronimo}
                  defaultValue={input.valorActual}
                  size="small"
                  fullWidth
                  onChange={handlechange}
                  disabled={!input.editable}
                  inputProps={{
                    maxLength: 40,
                    style: { textTransform: "uppercase" },
                  }}
                  /*  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/([^A-Za-z0-9]|[ioqIOQ])/, "")
                      .replace(/(\..*)\./g, "$1");
                  }} */
                />
              </div>
            );
          } else if (input.acronimo === "RFC" && typeEnd === 3) {
            const getTypePerson = () => {
              let typePerson;
              if (
                !inputs.find((input) => input.acronimo === "TIPPER")?.catalogo
              ) {
                typePerson = inputs.find(
                  (input) => input.acronimo === "TIPPER"
                )?.descripcionAnterior;
              } else
                typePerson =
                  inputs.find((input) => input.acronimo === "TIPPER")?.input
                    ?.valorActual ?? "";
              return typePerson;
            };
            const typePerson = getTypePerson();
            const getValue = (rfc) => {
              if (rfc) {
                const typePerson = getTypePerson();
                const maxLength = typePerson === "MORAL" ? 9 : 10;

                if (typePerson === "MORAL" && rfc.length > maxLength)
                  return { rest: rfc.slice(0, 9), value: rfc.slice(9, 12) };
                else if (!typePerson === "MORAL" && rfc.length > maxLength)
                  return { rest: rfc.slice(0, 10), value: rfc.slice(10, 13) };
                else return { rest: rfc.slice(0, 10), value: "" };
              }
              return { rest: "", value: "" };
            };
            const disableRfc = () => {
              if (
                getTypePerson() === "MORAL" &&
                input?.valorActual.length < 9
              ) {
                return true;
              } else if (
                getTypePerson() !== "MORAL" &&
                input?.valorActual.length < 10
              ) {
                return true;
              }
              return false;
            };

            const { rest, value } = getValue(input.valorActual);

            return (
              <div key={input.idDato}>
                <InputLabel htmlFor={input.label}>
                  {input.descripcionActual + (input.obligatorio ? " (*)" : "")}
                </InputLabel>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  name={input.acronimo}
                  defaultValue={
                    typePerson !== "MORAL" ? value : input?.valorActual
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const data = {
                      target: {
                        name: input.acronimo,
                        value: rest + e.target.value,
                      },
                    };
                    handlechange(typePerson !== "MORAL" ? data : e);
                  }}
                  disabled={!input.editable || disableRfc()}
                  inputProps={{
                    maxLength: typePerson !== "MORAL" ? 3 : 12,
                    style: { textTransform: "uppercase" },
                  }}
                  InputProps={
                    typePerson !== "MORAL"
                      ? {
                          startAdornment: (
                            <span className="textAdorment">{rest}</span>
                          ),
                        }
                      : null
                  }
                  onInput={(e) =>
                    (e.target.value = rfcRules(typePerson, e.target.value))
                  }
                  onPaste={(e) => e.preventDefault()}
                />
              </div>
            );
          } else
            return (
              <div key={input.idDato}>
                <InputLabel htmlFor={input.label}>
                  {input.descripcionActual +
                    (!input.obligatorio || (typeEnd === 6 && input.idDato === 4)
                      ? ""
                      : " (*)")}
                </InputLabel>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  name={input.acronimo}
                  defaultValue={
                    input.idDato === 13 ||
                    input.idDato === 14 ||
                    input.idDato === 15 ||
                    input.idDato === 20
                      ? input.descripcionAnterior
                      : input.valorActual
                  }
                  size="small"
                  fullWidth
                  onChange={handlechange}
                  disabled={!input.editable}
                  inputProps={{
                    maxLength:
                      input.acronimo === "HCLAVE"
                        ? 3
                        : input.acronimo === "RFC"
                        ? 10
                        : input.acronimo === "MODELO"
                        ? 4
                        : 250,
                    style: { textTransform: "uppercase" },
                  }}
                  onInput={
                    input.acronimo === "MODELO"
                      ? (e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/, "")
                            .replace(/(\..*)\./g, "$1");
                        }
                      : null
                  }
                />
              </div>
            );
        }
        if (input.type === "date" && input.visible) {
          return (
            <div key={input.idDato}>
              <InputLabel htmlFor={input.label} className={classes.label}>
                {input.descripcionActual + (input.obligatorio ? " (*)" : "")}
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={classes.input}
                  name={input.acronimo}
                  value={input.valorActual || dataSend[input.acronimo] || null}
                  size="small"
                  fullWidth
                  inputVariant="outlined"
                  maxDate={new Date()}
                  onChange={(e) => {
                    handlechange({
                      target: { name: input.acronimo, value: e },
                    });
                  }}
                  format={"dd-MM-yyyy"}
                  disabled={!input.editable}
                />
              </MuiPickersUtilsProvider>
            </div>
          );
        }
        if (input.type === "select" && input.visible) {
          if (input.catalogo && input.catalogo?.catalogo.length > 0) {
            return (
              <div key={input.idDato}>
                <InputLabel className={classes.label}>
                  {input.descripcionActual + (input.obligatorio ? " (*)" : "")}
                </InputLabel>
                <TextField
                  className={classes.input}
                  id={input.label}
                  name={input.acronimo}
                  variant="outlined"
                  size="small"
                  select
                  fullWidth
                  disabled={!input.editable}
                  onChange={handlechange}
                  defaultValue={input.valorActual || ""}
                >
                  {input.catalogo?.catalogo.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.id}>
                        {option.descripcion}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            );
          } else {
            return (
              <div key={input.idDato}>
                <InputLabel htmlFor={input.label}>
                  {input.descripcionActual + (input.obligatorio ? " (*)" : "")}
                </InputLabel>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  name={input.acronimo}
                  defaultValue={input.descripcionAnterior}
                  size="small"
                  fullWidth
                  onChange={handlechange}
                  disabled={!input.editable}
                  placeholder={
                    input.acronimo === "TIPPER" && input.valorActual === "1"
                      ? "FISICA"
                      : input.acronimo === "TIPPER" && input.valorActual === "2"
                      ? "MORAL"
                      : input.acronimo === "SEXO" && input.valorActual === "H"
                      ? "HOMBRE"
                      : input.acronimo === "SEXO" && input.valorActual === "M"
                      ? "MUJER"
                      : input.acronimo === "SEXO" && input.valorActual === "E"
                      ? "EMPRESA"
                      : ""
                  }
                  inputProps={{
                    maxLength: 250,
                    style: { textTransform: "uppercase" },
                  }}
                />
              </div>
            );
          }
        }
        if (input.type === "radio" && input.visible) {
          return (
            <div key={input.idDato}>
              <RadioGroup
                defaultValue={input.valorActual || ""}
                name={input.acronimo}
                onChange={(e) => {
                  handlechange({
                    target: { name: input.acronimo, value: e.target.value },
                  });
                }}
                style={{ flexDirection: "row" }}
              >
                {input.catalogo?.catalogo.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={option.id}
                      control={<Radio color="primary" />}
                      label={option.descripcion}
                      disabled={!input.editable}
                    />
                  );
                })}
              </RadioGroup>
            </div>
          );
        }

        return "";
      })}
    </Fragment>
  );
};

export default DinamicForm;
