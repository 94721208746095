import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { colors } from "../../utils";
import useStyles from "./styles";

const SearchBar = ({ search, change }) => {
  const classes = useStyles();
  return (
    <TextField
      name="search"
      variant="outlined"
      value={search}
      onChange={change}
      size="small"
      className={classes.root}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search style={{ color: colors.blueDarken }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
