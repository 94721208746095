import { useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { DataGrid } from "../../../../../../shared/components";
import { AltaContext } from "../../../../context";
import { useResultColumns } from "../hooks";
import { FailedTableInfo } from "./failedTableInfo";

export const ResultTable = () => {
  const columns = useResultColumns();
  const { savedPersons, diferencia, badPersons } = useContext(AltaContext);
  return (
    <>
      {!!badPersons.length && <FailedTableInfo rows={badPersons} />}

      {diferencia ? (
        <Box>
          <Typography variant="h6" color="primary">
            Asegurados Guardados
          </Typography>
          <DataGrid columns={columns} rows={savedPersons} id="titular" />
          <Grid container spacing={2}>
            <Grid item>
              <Typography>
                Importe Actual: {diferencia.importeActual}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Importe Anterior: {diferencia.importeAnterior}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Diferencia: {diferencia.importeDiferencia}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            height: "100%",
            alignContent: "center",
          }}
        >
          <Typography variant="h6" color="primary">
            No hay asegurados guardados
          </Typography>
        </Box>
      )
      }
    </>
  );
};
