import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,

} from "@material-ui/core";
import { ReportProblemOutlined, InfoOutlined } from "@material-ui/icons";
import useStyles from "./style";
const AlertModal = ({
  open,
  close,
  title,
  message,
  SuccessText,
  success,
  children,
  type = 1,
  icon = false
}) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (type === 1) {
      if (reason && reason === "backdropClick") return;
      close();
    } else close();
  };
  return (
    <Dialog open={open} onClose={handleClose} className={classes.root}>
      <DialogTitle className={classes.header} id="alert-dialog-title">
        {icon ? <InfoOutlined className={classes.infoIcon} /> : <ReportProblemOutlined className={classes.icon} />}
        {title}
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {children}
      </DialogContent>

      <Divider />

      <DialogActions>
        {type === 1 && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.cancel}
            size="small"
            onClick={close}
          >
            No
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          size="small"
          onClick={() => {
            success();
          }}
        >
          {SuccessText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
