import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { generarInfoTabla } from "..";
import { endoso } from "../../../../services";
import { EndosoContext } from "../../context";

export const useRulesBy18 = () => {
  const {
    formDta,
    auto,
    descripcion,
    createMessage,
    showButtons,
    setShowButtons,
    setInfoTable,
  } = useContext(EndosoContext);

  const history = useHistory();

  const getValues = () => {
    const values = descripcion.split("-");
    return { amis: values[0], descripcion: values[1] };
  };

  const requestFailed = (response) =>
    createMessage({
      text: response.data?.mensaje ?? "Ocurrio un error con su solicitud",
      variant: "warning",
      time: 4000,
    });

  const requestSuccess = async () => {
    const response = await endoso.checkBaja605(formDta.noSolicitud);
    if (response.data?.codigo === "1") {
      setInfoTable(
        generarInfoTabla(auto.descactual, descripcion, formDta.tipoEndoso)
      );
      setShowButtons({ ...showButtons, infoTable: true, domicilio: false });
    } else {
      requestFailed(response);
      setTimeout(() => history.push("/"), 4000);
    }
  };
  const handleSubmit18 = async (data) => {
    if (!!descripcion) {
      const { amis, descripcion } = getValues();
      const body = {
        oficina: data.oficina,
        ramo: data.ramo,
        poliza: data.poliza,
        situacion: auto.nmsituac,
        amis: amis,
        descripcion: descripcion,
      };
      const response = await endoso.saveDescription(body);
      if (response.data?.codigo === "1") {
        await endoso.guardarSituacion({
          solicitud: formDta.noSolicitud,
          situacion: auto.nmsituac,
        });
        await endoso.diferencia({ solicitud: formDta.noSolicitud });
        await endoso.clausula({ solicitud: formDta.noSolicitud });
        await requestSuccess();
      } else requestFailed(response);
    } else
      createMessage({
        text: "Debe ingresar una descripción",
        variant: "warning",
      });
  };
  return { handleSubmit18 };
};
