import { createContext, useContext, useState, useMemo } from "react";
import { AuthContext } from "../../../context/context";
import { toUppercase } from "../hooks";

export const AltaContext = createContext({
  badPersons: [],
  savedPersons: [],
  diferencia: {},
  setBadPersons: () => {},
});

export const AltasTitularProvider = ({ children }) => {
  const { setTimer } = useContext(AuthContext);
  const [initialData, setInitialData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [situacion, setSituacion] = useState({ antiguedad: new Date() });
  const [titulares, setTitulares] = useState([]);
  const [familiares, setFamiliares] = useState([]);
  const [currentTitular, setCurrentTitular] = useState();
  const [savedPersons, setSavedPersons] = useState();
  const [badPersons, setBadPersons] = useState([]);
  const [diferencia, setDiferencia] = useState();
  const [selected, setSelected] = useState();
  const [file, setFile] = useState();
  const comboOptions = {
    agrupadores: initialData.agrupadores,
    categorias: initialData.categorias,
  };

  /* methods */
  const openModal = (titular) => {
    if (titular && titular.familiares >= 9) setShowModal(false);
    else {
      setShowModal(true);
      setCurrentTitular(titular);
      if (titular) setSituacion({ ...situacion, parentId: titular.id });
    }
  };
  const closeModal = () => {
    setCurrentTitular();
    setSituacion({ antiguedad: new Date() });
    setShowModal(false);
  };
  const addPersonsSaved = (values, diferences) => {
    setSavedPersons(values);
    setDiferencia(diferences);
  };
  const handleChange = (e) => {
    setTimer(0);
    setSituacion({
      ...situacion,
      [e.target.name]: toUppercase(e.target.value),
    });
  };

  const removeDependencies = () =>
    setSituacion({ ...situacion, regla: undefined, tregla: undefined });

  const dataLoaded = useMemo(() => {
    if (Object.values(initialData).length === 0) return false;
    else {
      setTitulares(
        initialData.situaciones?.map((e, i) => ({ ...e, id: i + 1 })) || []
      );
      return true;
    }
  }, [initialData]);
  const data = {
    comboOptions,
    titulares,
    setTitulares,
    familiares,
    setFamiliares,
    showModal,
    openModal,
    closeModal,
    currentTitular,
    handleChange,
    situacion,
    removeDependencies,
    setInitialData,
    dataLoaded,
    addPersonsSaved,
    savedPersons,
    diferencia,
    selected,
    setSelected,
    file,
    setFile,
    badPersons,
    setBadPersons,
  };
  return <AltaContext.Provider value={data}>{children}</AltaContext.Provider>;
};
