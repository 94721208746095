import { makeStyles } from "@material-ui/core";
import { colors } from "../../utils";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
  colorBox: {
    borderRadius: "4px",
  },
  small: {
    width: "30px",
    height: "20px",
  },
  medium: {
    width: "40px",
    height: "22px",
  },
  large: {
    width: "60px",
    height: "30px",
  },
  font: { color: colors.greyFont },
});

export default useStyles;
