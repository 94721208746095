import { useState } from "react";
import { AltasTitularProvider, EndosoContext } from ".";

export const EndosoProvider = ({ children }) => {
  const [alertData, setAlertData] = useState();
  const [checkedPersons, setCheckedPersons] = useState();
  const [showButtons, setShowButtons] = useState({});
  const [formDta, setFormDta] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({});
  const [infoTable, setInfoTable] = useState([]);
  const [spinner, setSpinner] = useState(false);

  /* CargaB */
  const [cargaB, setCargaB] = useState({});
  const [cargaBData, setCargaBData] = useState({});

  /* Bajas 15 */
  const [bajaRiesgo15, setBajaRiesgo15] = useState();
  const [searchWord, setSearchWord] = useState("");
  const [selected, setSelected] = useState([]);

  /* descripcion 18 */
  const [autos, setAutos] = useState([]);
  const [auto, setAuto] = useState({});
  const [descripcion, setDescripcion] = useState();

  /* Bajas 23 */
  const [rows23, setRows23] = useState([]);
  const [continueR23, setContinueR23] = useState(false);
  const [infoTable23, setInfoTable23] = useState([]);

  /* beneficiario preferente 21 */
  const [finishR21, setFinishR21] = useState({});
  const [continue21, setContinue21] = useState(false);

  /* Alta 22 */
  const [dataR22, setDataR22] = useState([]);
  const [continueR22, setContinueR22] = useState(false);
  const [listErrors22, setListErrors22] = useState([]);
  const [finishR22, setFinishR22] = useState(false);
  const [numeroSituacion, setNumeroSituacion] = useState(1);

  /* endoso archivos */
  const [filesResponse, setFilesResponse] = useState([]);

  const hideElement = (name, value) => {
    setShowButtons({ ...showButtons, [name]: value });
  };
  const createMessage = ({ text, variant, action }) => {
    setShowMessage(true);
    setMessage({
      text: text,
      severity: variant,
      action: action,
    });
  };
  const values = {
    alertData: alertData,
    setAlertData: setAlertData,
    checkedPersons: checkedPersons,
    setCheckedPersons: setCheckedPersons,
    showButtons,
    setShowButtons,
    formDta,
    setFormDta,
    showMessage,
    setShowMessage,
    message,
    setMessage,
    hideElement,
    createMessage,
    cargaB,
    setCargaB,
    cargaBData,
    setCargaBData,
    bajaRiesgo15,
    setBajaRiesgo15,
    searchWord,
    setSearchWord,
    selected,
    setSelected,
    autos,
    setAutos,
    auto,
    setAuto,
    descripcion,
    setDescripcion,
    infoTable,
    setInfoTable,
    filesResponse,
    setFilesResponse,
    spinner,
    setSpinner,
    finishR21,
    setFinishR21,
    continue21,
    setContinue21,
    dataR22,
    setDataR22,
    continueR22,
    setContinueR22,
    listErrors22,
    setListErrors22,
    finishR22,
    setFinishR22,
    rows23,
    setRows23,
    continueR23,
    setContinueR23,
    infoTable23,
    setInfoTable23,
    numeroSituacion,
    setNumeroSituacion,
  };

  return (
    <EndosoContext.Provider value={values}>
      <AltasTitularProvider>{children}</AltasTitularProvider>
    </EndosoContext.Provider>
  );
};
