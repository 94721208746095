import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../../../shared/utils";

const useStyles = makeStyles((theme) => ({
  title: { marginBottom: "16px" },
  caption: {
    color: colors.greyFont,
    display: "block",
    marginLeft: "8px",
  },
  backButton: {
    position: "absolute",
    bottom: 15,
    right: 15,
    color: colors.blueDarken,
  },
}));

export default useStyles;
