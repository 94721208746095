import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    rowGap: "16px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    boxSizing: "border-box",
    padding: "16px 40px",
    columnGap: "30%",
    "&:hover": { cursor: "pointer" },
  },
  item: {},
});

export default useStyles;
