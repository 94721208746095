import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: "16px",
  },
});

export default useStyles;
