import { useContext, useMemo } from "react";
import { getDifferenceInDays } from "../../../../../../shared/utils";
import { AltaContext } from "../../../../context";

export const usePersonValidation = () => {
  const { situacion } = useContext(AltaContext);

  const isValid = useMemo(() => {
    if (situacion && Object.values(situacion).length >= 11) {
      const { parentesco, fchNacimineto } = situacion;
      const age = getDifferenceInDays(fchNacimineto);
      if (parentesco === "T" || parentesco === "C") {
        if (age > 23741 || age < 6574)
          return {
            isValid: false,
            text: "La persona debe tener minimo 18 y maximo 65 años de edad",
          };
        else {
          return { isValid: true, text: "" };
        }
      } else if (parentesco === "H") {
        if (age > 12784)
          return {
            isValid: false,
            text: "Edad fuera del rango permitido",
          };
        else return { isValid: true, text: "" };
      } else return { isValid: true, text: "" };
    }
    return { isValid: true, text: "" };
  }, [situacion]);

  return isValid;
};
