import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
  },
  sections: {
    flex: 1,
  },
  image: { width: "100%", height: "100%", objectFit: "cover" },
});

export default useStyles;
