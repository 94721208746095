import { Fragment, useContext } from "react";
import { Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "./styles";
import { AuthContext } from "../../../../../../context/context";

const FormTable = ({
  title,
  type = 1,
  rows,
  setData,
  data,
  setShowElements,
  setShowButtons,
  formDta,
  setFormDta,
}) => {
  const { setTimer } = useContext(AuthContext);
  const classes = useStyles();
  const columns = [
    {
      field: "colonia",
      headerName: rows.titulo1 || "Colonia",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "municipio",
      headerName: rows.titulo2 || "Ciudad",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "estado",
      headerName: rows.titulo2 || "Estado",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];
  /* methods */
  const submitData = (row) => {
    setTimer(0);
    setData({
      ...data,
      colonia: row.colonia,
      idColonia: row.idColonia,
      estado: row.estado,
      idEstado: row.idEstado,
      ciudad: row.municipio,
      idCiudad: row.idMunicipio,
      idPais: row.idPais,
      accion: "I",
      municipio: row.municipio,
      calle: "centro",
    });

    if (type === 3) {
      const datos = [
        {
          idDato: 7,
          type: "select",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Tipo persona",
          descripcionAnterior: "FISICA",
          caracteresMaximos: 1,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: {
            codigo: 1,
            cantidad: 2,
            catalogo: [
              {
                id: "1",
                descripcion: "FISICA",
              },
              {
                id: "2",
                descripcion: "MORAL",
              },
            ],
          },
          editable: true,
          acronimo: "TIPPER",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 21,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "R.F.C",
          descripcionAnterior: null,
          caracteresMaximos: 3,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "RFC",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 1,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Homoclave",
          descripcionAnterior: null,
          caracteresMaximos: 3,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "HCLAVE",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 2,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Nombre",
          descripcionAnterior: null,
          caracteresMaximos: 70,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "NOMBRE",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 3,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Primer Nombre",
          descripcionAnterior: null,
          caracteresMaximos: 20,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "NOMBR1",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 4,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Segundo Nombre",
          descripcionAnterior: null,
          caracteresMaximos: 20,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "NOMBR2",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 5,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Apellido Paterno",
          descripcionAnterior: null,
          caracteresMaximos: 20,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "DSAPEL1",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 6,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Apellido Materno",
          descripcionAnterior: null,
          caracteresMaximos: 20,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "DSAPEL2",
          suplemLogico: null,
          visible: true,
        },

        {
          idDato: 8,
          type: "select",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Sexo",
          descripcionAnterior: "MUJER",
          caracteresMaximos: 1,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: {
            codigo: 1,
            cantidad: 3,
            catalogo: [
              {
                id: "E",
                descripcion: "EMPRESA",
              },
              {
                id: "H",
                descripcion: "HOMBRE",
              },
              {
                id: "M",
                descripcion: "MUJER",
              },
            ],
          },
          editable: true,
          acronimo: "SEXO",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 9,
          type: "date",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Fecha de nacimiento",
          descripcionAnterior: null,
          caracteresMaximos: 0,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "FECNAC",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 10,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Calle",
          descripcionAnterior: null,
          caracteresMaximos: 50,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "DOMIC",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 11,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Número Exterior",
          descripcionAnterior: null,
          caracteresMaximos: 10,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "NUMERO",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 12,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Número Interior",
          descripcionAnterior: null,
          caracteresMaximos: 20,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "OTPISO",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 13,
          type: "text",
          valorActual: row.colonia,
          valorAnterior: row.idColonia,
          descripcionActual: "Colonia",
          caracteresMaximos: 5,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "COLONI",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 14,
          type: "text",
          valorActual: row.codigoPostal,
          valorAnterior: row.idMunicipio,
          descripcionActual: "Delegación",
          descripcionAnterior: null,
          caracteresMaximos: 25,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "OTPOB",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 15,
          type: "text",
          valorActual: row.estado,
          valorAnterior: row.idEstado,
          descripcionActual: "Estado",
          descripcionAnterior: "",
          caracteresMaximos: 2,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "PROVIN",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 18,
          type: "text",
          valorActual: row.idCodigoPostal,
          valorAnterior: "",
          descripcionActual: "Código Postal",
          descripcionAnterior: null,
          caracteresMaximos: 6,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: false,
          acronimo: "CP",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 19,
          type: "text",
          valorActual: null,
          valorAnterior: null,
          descripcionActual: "Teléfono",
          descripcionAnterior: null,
          caracteresMaximos: 15,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "TELEFO",
          suplemLogico: null,
          visible: true,
        },
        {
          idDato: 20,
          type: "text",
          valorActual: row.pais,
          valorAnterior: row.idPais,
          descripcionActual: "País",
          descripcionAnterior: "MEXICO",
          caracteresMaximos: 3,
          caracteresMinimos: 0,
          obligatorio: true,
          catalogo: null,
          editable: true,
          acronimo: "PAIS",
          suplemLogico: null,
          visible: true,
        },
      ];

      setFormDta({ ...formDta, datosEndoso: datos, mensaje: null });
    } else setShowElements(2);
  };
  return (
    <Fragment>
      <div className={classes.headerComponent}>
        <Typography variant="h6" className={classes.title} align="left">
          <strong>{title}</strong>
        </Typography>
      </div>
      <DataGrid
        columns={columns}
        rows={rows.codigoPostal || []}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        rowsPerPageOptions={[4]}
        pageSize={4}
        onCellClick={(e) => submitData(e.row)}
        pagination
        checkboxSelection={type === 2 ? true : false}
        getRowId={(row) => row.colonia}
      />
    </Fragment>
  );
};

export default FormTable;
