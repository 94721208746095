import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
    boxSizing: "border-box",
    padding: "24px 48px",
  },

  sections: {
    flex: 1,
    boxSizing: "border-box",
    padding: "0 96px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    "&:first-child": { padding: "0" },
  },
  subSections: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "16px",
    "&:first-child": { justifyContent: "center" },
  },

  logo: {
    width: "130px",
    height: "100%",
  },
  title: {
    color: colors.blueDarken,
    margin: "0 auto",
  },
  passwordIcon: {
    "&:hover": { background: "none" },
  },
  loginBtn: {
    borderRadius: "19px",
  },
  registerBtn: {
    borderRadius: "19px",
  },
});

export default useStyles;
