import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { Fragment, useContext, useState } from "react";
import { AuthContext } from "../../../../../context/context";
import { AlertModal } from "../../../../../shared/components";
import { EndosoContext } from "../../../context";
import FormTable from "../../formsSection/formTable";
import InfoTableForRisk from "../../formsSection/InfoTableForRisk";
import useStyles from "./styles";

export const EndosoBajaRiesgo = ({ rows, showButtons, loading }) => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  const { alertData, setAlertData, checkedPersons } = useContext(EndosoContext);
  const [persons, setPersons] = useState([]);

  /* methods */
  const handleChangePersons = (e) => {
    setTimer(0);
    if (persons.find((person) => person === e.target.name)) {
      const personsfiltered = persons.filter(
        (person) => person !== e.target.name
      );
      setPersons(personsfiltered);
    } else setPersons([...persons, e.target.name]);
  };

  const handleSubmit = async () => {
    setTimer(0);
    const parsedPersons = persons.map((person) => parseInt(person));
    await alertData?.checkSubmit(parsedPersons);
    setAlertData(undefined);
  };

  /* save view */
  if (!showButtons.formTable && showButtons.multiTable && !loading)
    return <InfoTableForRisk rows={rows} />;

  /* main view */
  return (
    <Fragment>
      <FormTable rows={rows} check={true} />
      <AlertModal
        open={Boolean(alertData)}
        close={() => {
          setAlertData(undefined);
        }}
        SuccessText={"Continuar"}
        message={alertData?.message}
        title={"Atención"}
        success={handleSubmit}
        type={2}
      >
        <FormGroup className={classes.form}>
          {checkedPersons?.map((person) => (
            <FormControlLabel
              key={person.idPersona}
              className={classes.checkBox}
              control={
                <Checkbox
                  value={person.idPersona}
                  onChange={handleChangePersons}
                  name={person.idPersona}
                  color="primary"
                />
              }
              label={person.idPersona}
            />
          ))}
        </FormGroup>
      </AlertModal>
    </Fragment>
  );
};
