export const rfcRules = (type, value) => {
  if (type === "MORAL") {
    return moralRfcRules(value);
  }
  return fisicaRfcRules(value);
};

function moralRfcRules(value) {
  if (value.length <= 3) {
    return replaceRules(value, /([^ÑA-Zña-z&])/);
  } else if (value.length > 3 && value.length <= 9) {
    const valueReg = value.slice(3);
    return `${value.slice(0, 3)}${replaceRules(valueReg, /([^0-9])/)}`;
  } else if (value.length > 9) {
    const valueReg = value.slice(9);
    return `${value.slice(0, 9)}${replaceRules(valueReg, /([^ña-zÑA-Z0-9])/)}`;
  } else return value;
}

function fisicaRfcRules(value) {
  return replaceRules(value, /([^ña-zÑA-Z0-9])/);
}

function replaceRules(value, regex) {
  return value.replace(regex, "").replace(/(\..*)\./g, "$1");
}
