import { Typography } from "@material-ui/core";
import { Fragment } from "react";
import { asyncForEach } from "..";

const ErrorComponent = ({ errors }) => {
  return (
    <Fragment>
      <Typography>
        Las siguientes personas no pudieron ser agregadas:
      </Typography>
      {errors.map((person, index) => (
        <div key={index}>
          {person.segundoNombre ? (
            <Typography>{`${person.primerNombre ?? ''} ${person.segundoNombre ?? ''} ${
              person.apellidoPaterno ?? ""
            } ${person.apellidoMaterno ?? ''}: ${person?.error.replace(
              /null\s/g,
              ""
            )}`}</Typography>
          ) : (
            <Typography>{`${person.primerNombre ?? ''} ${person.apellidoPaterno ?? ''} ${
              person.apellidoMaterno ?? ""
            }: ${person?.error.replace(/null\s/g, "")}`}</Typography>
          )}

          <br />
        </div>
      ))}
    </Fragment>
  );
};

export const rulesByTwelve = async (
  data,
  setMessage,
  setShowMessage,
  formDta,
  endoso,
  setShowButtons,
  showButtons,
  persons,
  setPersons,
  setAlertData
) => {
  /* methods */
  const saveEndoso = async (changeType = false) => {
    const removedPersons = [];
    const personsCopy = [...persons];

    const altasFunction = async (person, index) => {
      const body = {
        accion: "I",
        apMaterno: person.apellidoMaterno,
        apPaterno: person.apellidoPaterno,
        fchNacimineto: person.fechainicial,
        fechaNacimiento: true,
        homoclave: null,
        idPersona: null,
        nombre: person.nombre,
        primerNombre: person.primerNombre,
        razonSocial: "FISICA",
        rfc: null,
        segundoNombre: person.segundoNombre || null,
        sexo: person.sexo,
        tipoIdentificador: "0",
      };
      const savePerson = await endoso.savePerson(body);
      if (savePerson.data?.codigo === 1) {
        const body = {
          categoria: `00${
            changeType && person.categoria === 2 ? 3 : person.categoria
          }`,
          codigo: null,
          idPersona: savePerson.data.idPersona,
          mensaje: null,
          oficina: data.oficina,
          poliza: data.poliza,
          ramo: data.ramo,
          solicitud: formDta.noSolicitud,
        };
        const checkResp = await endoso.check605(body);
        if (checkResp?.data?.codigo === 1) {
          const situacionResponse = await endoso.situacionAlta(body);
          if (situacionResponse.data?.codigo === 1) {
            const situacionResponse = await endoso.guardarSituacion({
              situacion: savePerson.data?.idPersona,
              solicitud: formDta.noSolicitud,
            });
            if (situacionResponse.data?.codigo === 1) {
              return 1;
            }
          } else {
            removedPersons.push({
              ...persons[index],
              pos: index,
              error: situacionResponse.data?.mensaje,
            });
            return 0;
          }
        } else {
          removedPersons.push({
            ...persons[index],
            pos: index,
            error: checkResp.data?.mensaje,
          });
          return 0;
        }
      } else {
        return 0;
      }
    };

    const responseSaves = await asyncForEach(personsCopy, altasFunction).then(
      (e) => e
    );

    const filteredPersons = persons.filter(
      (person, index) => !removedPersons.find((item) => item?.pos === index)
    );
    setPersons(filteredPersons);

    if (responseSaves.find((save) => save === 1)) {
      const diferenciaResponse = await endoso.diferencia({
        solicitud: formDta.noSolicitud,
      });
      if (removedPersons.length > 0) {
        setMessage({
          text: <ErrorComponent errors={removedPersons} />,
          severity: "warning",
        });
        setShowMessage(true);
      }
      if (diferenciaResponse.data?.codigo === "1") {
        const filteredPersons = persons.filter(
          (person, index) => !removedPersons.find((item) => item?.pos === index)
        );
        setPersons(
          filteredPersons.map((person) => ({
            ...person,
            importeActual: diferenciaResponse?.data.importeActual,
            importeAnterior: diferenciaResponse?.data.importeAnterior,
            importeDiferencia: diferenciaResponse?.data.importeDiferencia,
          }))
        );
        const clausulaResponse = await endoso.clausula({
          solicitud: formDta.noSolicitud,
        });
        if (clausulaResponse.data?.codigo === "1") {
          setShowButtons({ ...showButtons, multiTable: true });
        } else {
          setMessage({
            text:
              clausulaResponse.data?.mensaje || "Ocurrio un error de conexion",
            severity: "warning",
          });
          setShowMessage(true);
        }
      } else {
        setMessage({
          text:
            diferenciaResponse.data?.mensaje || "Ocurrio un error de conexion",
          severity: "warning",
        });
        setShowMessage(true);
      }
    } else {
      setMessage({
        text: <ErrorComponent errors={removedPersons} />,
        severity: "warning",
      });
      setShowMessage(true);
    }
  };

  const getNumberOfPersons = () => {
    const numberOfStudents = persons.filter(
      (person) => person.categoria === 1
    ).length;
    const numberOfTeachers = persons.filter(
      (person) => person.categoria === 2
    ).length;
    return {
      alumnos: numberOfStudents,
      docentes: numberOfTeachers,
    };
  };
  const body = {
    oficina: data.oficina,
    poliza: data.poliza,
    ramo: data.ramo,
    ...getNumberOfPersons(),
  };
  const checkResponse = await endoso.check605(body);

  const saveWithNewType = async () => await saveEndoso(true);

  if (checkResponse.data?.codigo === 1) {
    await saveEndoso();
  } else {
    setAlertData({
      message: checkResponse.data?.mensaje,
      action: saveWithNewType,
    });
  }
};
