import { useContext } from "react";
import { Box } from "@material-ui/core";
import { EndosoContext } from "../../../../../context";
import { FailedTableInfo } from "../failedTableInfo";
import { SuccessFileTable } from "../successTableFile";

export const FilesViewer = () => {
  const { filesResponse } = useContext(EndosoContext);

  const hasBadFiles = filesResponse.some((file) => !file.codigo);

  return (
    <>
      {hasBadFiles && (
        <>
          <FailedTableInfo />
          <Box mt={2} />
        </>
      )}
      <SuccessFileTable />
    </>
  );
};
