import { makeStyles } from "@material-ui/core";
import { colors } from "shared/utils";


const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "24px",
    rowGap: "24px",
  },
  label: {
    marginLeft: "16px",
    marginBottom: "6px",
    color: colors.blueDarken,
    fontSize: ".82rem",
  },
  input: { fontSize: ".52rem" },
  containerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: "25px",
    right: '30px',
    gap: '20px',
  },
  back: {
    borderRadius: "20px",
  }, 
  submit: {
    borderRadius: "20px",
    minWidth: "120px",
  },


});

export default useStyles;
