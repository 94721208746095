import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    marginBottom: "16px",
    justifyContent: "center",
  },
  sections: {
    "&:first-child": {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    "&:last-child": {
      marginLeft: "80px",
    },
  },
  buttons: {
    color: colors.blueDarken,
    textTransform: "none",
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  back: { marginBottom: "16px", "& button": { textTransform: "none" } },
});

export default useStyles;
