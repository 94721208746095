import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      background: "#F9F9FB",
      zIndex: 0,
      border: "#D8D8D8 solid 1px",
    },
    "& svg": {
      zIndex: 1,
    },
    "& .MuiInputBase-input": { zIndex: 1,
      textTransform: "uppercase",
     },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "16px",
  },
});

export default useStyles;
