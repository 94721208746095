import { endoso } from "../../../../services";

export const rulesByTwenty = async (
  dataSend,
  data,
  formDta,
  setMessage,
  setShowMessage,
  showButtons,
  setShowButtons
) => {
  const mensajes = {
    empty: "Debe seleccionar una situación y un tipo de carga",
    error: "Error al enviar la información",
  };
  /* methods */
  const createMessage = ({ text, variant }) => {
    setShowMessage(true);
    setMessage({ text: text, severity: variant });
  };
  if (!Boolean(dataSend.situacion) || !Boolean(dataSend.mercancia)) {
    createMessage({ text: mensajes.empty, variant: "warning" });
  } else {
    const body = {
      ...dataSend,
      solicitud: formDta.noSolicitud,
    };
    const response = await endoso.saveCargaB(body);

    if (response?.data?.codigo === "1") {
      const body = { solicitud: formDta.noSolicitud };
      const bodyRequest = {
        solicitud: formDta.noSolicitud,
        situacion: dataSend.situacion,
      };
      await endoso.guardarSituacion(bodyRequest);
      await endoso.diferencia(body);
      const clausula = await endoso.clausula(body);
      if (clausula?.data?.codigo === "1")
        setShowButtons({ ...showButtons, infoTable: true });
      else
        createMessage({
          text: clausula?.data?.mensaje ?? mensajes.error,
          variant: "error",
        });
    } else {
      createMessage({
        text: response?.data?.mensaje ?? mensajes.error,
        variant: "warning",
      });
    }
  }
};
