import { InputLabel, TextField } from "@material-ui/core";
import useStyles from "./styles";

const DirectionForm = ({ data, change, dataSend }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <InputLabel htmlFor={"calle"}>{"Calle (*)"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"calle"}
          size="small"
          fullWidth
          onChange={change}
          inputProps={{ maxLength: 250 }}
        />
      </div>
      <div>
        <InputLabel htmlFor={"nExterior"}>{"Número Exterior"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"numeroExterior"}
          size="small"
          fullWidth
          onChange={change}
        />
      </div>
      <div>
        <InputLabel htmlFor={"nInterior"}>{"Número Interior"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"numeroInterior"}
          size="small"
          fullWidth
          onChange={change}
        />
      </div>
      <div>
        <InputLabel htmlFor={"colonia"}>{"Colonia (*)"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"colonia"}
          defaultValue={data.colonia}
          size="small"
          fullWidth
          onChange={change}
          disabled
          inputProps={{ maxLength: 250 }}
        />
      </div>
      <div>
        <InputLabel htmlFor={"municipio"}>
          {"Delegacion/Municipio (*)"}
        </InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"municipio"}
          defaultValue={data.municipio}
          size="small"
          fullWidth
          onChange={change}
          disabled
          inputProps={{ maxLength: 250 }}
        />
      </div>
      <div>
        <InputLabel htmlFor={"estado"}>{"Estado (*)"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"estado"}
          defaultValue={data.estado}
          size="small"
          fullWidth
          onChange={change}
          disabled
          inputProps={{ maxLength: 250 }}
        />
      </div>
      <div>
        <InputLabel htmlFor={"codigoPostal"}>{"Código Postal (*)"}</InputLabel>
        <TextField
          className={classes.input}
          variant="outlined"
          name={"codigoPostal"}
          defaultValue={data.codigoPostal}
          size="small"
          fullWidth
          onChange={change}
          disabled
          inputProps={{ maxLength: 250 }}
        />
      </div>
    </div>
  );
};

export default DirectionForm;
