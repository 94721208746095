import { Fragment, useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../../../../../../../context/context";
import useStyles from "./styles";
import { EndosoContext } from "../../../../../context";

export const FormTable = () => {
  const classes = useStyles();
  const { setTimer } = useContext(AuthContext);
  const { autos, setAuto } = useContext(EndosoContext);
  const columns = [
    {
      field: "nmsituac",
      headerName: "Número",
      flex: 0.4,
      headerClassName: "header",
      headerAlign: "center",
    },
    {
      field: "marca",
      headerName: "Marca",
      flex: 1,
      headerClassName: "header",
      headerAlign: "center",
    },
  ];

  /* methods */
  const submitData = async (row) => {
    setTimer(0);
    setAuto(row);
  };

  useEffect(() => {
    if (autos?.length === 1) submitData(autos[0]); // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <DataGrid
        columns={columns}
        rows={autos ?? []}
        className={classes.root}
        disableSelectionOnClick
        disableColumnMenu
        rowsPerPageOptions={[4]}
        pageSize={4}
        onCellClick={(e) => submitData(e.row)}
        pagination
        getRowId={(row) => row.nmsituac}
      />
    </Fragment>
  );
};
