import React, { useEffect, useState } from 'react';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export const PdfPreview = ({ data }) => {
    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        const createPdf = async () => {
            const pdfDoc = await PDFDocument.create();
            const page = pdfDoc.addPage([600, 600]);

            const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
            const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

            const title = 'Beneficiario Preferente';
            const titleSize = 20;
            const titleWidth = boldFont.widthOfTextAtSize(title, titleSize);
            const titlePositionX = (page.getWidth() - titleWidth) / 2;

            page.drawText(title, {
                x: titlePositionX,
                y: 500,
                size: titleSize,
                font: boldFont,
                color: rgb(0, 0, 0),
            });

            const fontSize = 14;
            const lineHeight = 16;
            const startX = 50;
            let startY = 450;

            const paragraphs = [
                `Mediante este endoso se da de alta la presente cláusula de beneficiario preferente.`,
                `En caso de ocurrir algún siniestro que causará pérdidas y/o daños materiales totales a los bienes amparados bajo la sección ${data?.dsSeccion}, la indemnización será pagadera preferente e irrevocablemente a favor de: ${data.nombreBeneficiario}, sin perjuicio del interés que como asegurado mantiene sobre pérdidas y/o daños parciales, que puedan ser reparables por el mismo asegurado, salvo pacto en contrario.`,
                `Hasta por el interés que le corresponda a la fecha del siniestro, en relación con el contrato de ${data.contrato} (arrendamiento, crédito con garantía, prendario, hipotecario, etc.) celebrado entre el asegurado y el beneficiario preferente, queda entendido que la presente cláusula solo podrá ser cancelada mediante previa autorización, por escrito, de los beneficiarios y que la responsabilidad de la compañía nunca será mayor a la contratada en esta póliza.`,
                `Así mismo, queda entendido y convenido que cualquier aumento en el monto a indemnizar como consecuencia de la demora en la reconstrucción de los bienes motivada por la aplicación de esta cláusula, correrá exclusivamente a cargo del asegurado.`,
            ];

            paragraphs.forEach((paragraph) => {
                const words = paragraph.split(' ');
                let line = '';
                let lineWidth = 0;

                words.forEach((word, index) => {
                    const testLine = line + word + ' ';
                    const testLineWidth = timesRomanFont.widthOfTextAtSize(testLine, fontSize);

                    if (testLineWidth > page.getWidth() - 2 * startX) {
                        if (line.trim()) {
                            const spaceCount = line.trim().split(' ').length - 1;
                            const extraSpace = (page.getWidth() - 2 * startX - lineWidth) / (spaceCount || 1);

                            let xPosition = startX;
                            line.trim().split(' ').forEach((part, i) => {
                                page.drawText(part, {
                                    x: xPosition,
                                    y: startY,
                                    size: fontSize,
                                    font: timesRomanFont,
                                    color: rgb(0, 0, 0),
                                });
                                xPosition += timesRomanFont.widthOfTextAtSize(part + ' ', fontSize) + extraSpace;
                            });
                        }
                        line = word + ' ';
                        lineWidth = timesRomanFont.widthOfTextAtSize(word + ' ', fontSize);
                        startY -= lineHeight;
                    } else {
                        line = testLine;
                        lineWidth = testLineWidth;
                    }
                });

                if (line.trim()) {
                    page.drawText(line.trim(), {
                        x: startX,
                        y: startY,
                        size: fontSize,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                    startY -= lineHeight;
                }

                startY -= lineHeight;
            });

            page.drawText('Previo de endoso', {
                x: 90,
                y: 145,
                size: 76,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
                opacity: 0.1,
                rotate: degrees(35),
            });

            const pdfBytes = await pdfDoc.save();
            setPdfData(URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' })));
        };

        createPdf();
    }, [data]);

    return (
        <div>
            {pdfData && <embed src={pdfData} type="application/pdf" width="100%" height="600px" style={{
                borderRadius: '10px', border: 'none'
            }} />}
        </div>
    );
};
