import { Box, Typography } from "@material-ui/core";
import { Apps } from "@material-ui/icons";
import clsx from "clsx";
import useStyles from "./style";

const CheckBoxCover = ({ types, setType }) => {
  const classes = useStyles();
  const handleStatus = (item) => {
    const statusChanged = types.map((type) => {
      if (type.value === item.value) {
        return { ...item, status: !item.status };
      } else {
        return type;
      }
    });
    setType(statusChanged);
  };
  return (
    <div className={classes.root}>
      {types.map((type) => (
        <Box
          className={clsx(classes.checkBox, {
            [classes.disabled]: !type.status,
            [classes.active]: type.status,
          })}
          onClick={() => {
            handleStatus(type);
          }}
          key={type.value}
        >
          <Apps id="icon" />
          <Typography id="label" variant="body1">
            {type.label}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default CheckBoxCover;
