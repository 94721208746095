import { GET, POST, PUT, DELETE } from "../shared/utils/crud";

const list = async () => {
  const res = await GET("poliza/busqueda");
  return res;
};

const documents = async (idSolicitud, CdTipo, oficina, ramo, poliza, suplemento) => {
  const queryParams = new URLSearchParams({
    CdTipo,
    oficina,
    ramo,
    poliza,
    suplemento
  }).toString();
  const url = `endoso/${idSolicitud}/documentos?${queryParams}`;
  const res = await GET(url);
  return res;
}


const create = async (body) => {
  const res = await POST("poliza/busqueda", body);
  return res;
};

const edit = async (body) => {
  const { id, update } = body;

  const res = await PUT(`poliza/busqueda/${id}`, update);
  return res;
};

const destroy = async (id) => {
  const res = await DELETE(`poliza/busqueda/${id}`);
  return res;
};

const search = async (body) => {
  const res = await POST("poliza/busqueda", body);
  return res;
};

const bloquea = async (body) => {
  const res = await POST("poliza/bloquea", body);
  return res;
};



const searchPoliza = {
  list: list,
  create: create,
  edit: edit,
  destroy: destroy,
  search: search,
  bloquea: bloquea,
  documents: documents,
};
export default searchPoliza;
