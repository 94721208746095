import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils";

const useStyles = makeStyles({
  root: {
    padding: "8px",
    background: colors.blueLighten,
    color: colors.white,
    borderRadius: "4px",
  },
});

export default useStyles;
