import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";

/* sizes: small, medium, large
  separation: number * 8
*/
const ColorBox = ({ color, size = "medium", separation = 1, legend }) => {
  const classes = useStyles();

  const spacing = () => {
    return separation > 0 ? separation * 8 : 8;
  };
  const fontSize = () => {
    if (size === "small") return "caption";
    if (size === "medium") return "body1";
    if (size === "large") return "h6";
  };
  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.colorBox, {
          [classes.small]: size === "small",
          [classes.medium]: size === "medium",
          [classes.large]: size === "large",
        })}
        style={{ background: color, marginRight: `${spacing()}px` }}
      />
      <Typography className={classes.font} variant={fontSize()}>
        {legend}
      </Typography>
    </div>
  );
};

export default ColorBox;
