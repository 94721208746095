import { InputLabel, MenuItem, TextField } from "@material-ui/core";
import { Fragment } from "react";
import useStyles from "../styles";
export const Select = ({
  label,
  name,
  onChange,
  required,
  disabled,
  optionsProps,
  defaultValue,
}) => {
  const {
    options,
    value = "value",
    text = "text",
    unique = false,
  } = optionsProps;
  const classes = useStyles();
  return (
    <Fragment>
      <InputLabel className={classes.label}>
        {label + (required ? " (*)" : "")}
      </InputLabel>
      <TextField
        className={classes.input}
        name={name}
        variant="outlined"
        size="small"
        select
        fullWidth
        disabled={disabled}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={unique ? option : option[value]}>
              {unique ? option : option[text]}
            </MenuItem>
          );
        })}
      </TextField>
    </Fragment>
  );
};
