import { useContext, useEffect, useMemo } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { EndosoContext } from "../../../../../context";
import { useColumns, usePagination } from "../../hooks";
import useStyles from "./styles";
import { SearchBar } from "../searchbar";

export const ServerSideTable = () => {
  const { bajaRiesgo15, setBajaRiesgo15 } = useContext(EndosoContext);
  const { getPage, handleChangePagination, loader, page, selected, setLoader } =
    usePagination();
  const columns = useColumns();
  const rowData = useMemo(() => bajaRiesgo15?.niveles, [bajaRiesgo15]);
  const classes = useStyles();

  useEffect(() => {
    const newRows = bajaRiesgo15?.niveles.map((row) => ({
      ...row,
      checked: selected.some(({ codigo }) => codigo === row.codigo),
    }));
    setBajaRiesgo15({
      ...bajaRiesgo15,
      niveles: newRows,
    }); // eslint-disable-next-line
  }, [page]);
  return (
    <>
      <SearchBar setLoader={setLoader} />
      <DataGrid
        paginationMode="server"
        rowCount={bajaRiesgo15?.cantidad}
        className={classes.root}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnMenu
        columns={columns}
        rows={!loader ? rowData : []}
        getRowId={(row) => row.codigo}
        onPageChange={(page) => getPage(page)}
        loading={loader}
        onCellClick={handleChangePagination}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay>No se encontro información</GridOverlay>
          ),
        }}
      />
    </>
  );
};
