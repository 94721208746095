import { Tab, Tabs } from "@material-ui/core";
import useStyles from "./styles";
const TabGraphic = ({ value = 1, handleChange }) => {
  const classes = useStyles();
  return (
    <Tabs
      className={classes.root}
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{ className: classes.tabIndicator }}
    >
      <Tab className={classes.tabs} label="Actividades" />
      <Tab className={classes.tabs} label="Productos" />
    </Tabs>
  );
};

export default TabGraphic;
