import { Fragment, useContext, useEffect, useState } from "react";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import useStyles from "./styles";
import { EndosoContext } from "../../../../../context";
import { Grid, Typography } from "@material-ui/core";
import { endoso } from "services";
import { Spinner } from "shared/components";

export const InfoTable = ({ setData }) => {
    const classes = useStyles();
    const { dataR22 } = useContext(EndosoContext);
    const [diferencia, setDiferencia] = useState({});
    const [loading, setLoading] = useState(true);
    
    const formatCurrency = (value) => {
        const cleanedValue = value.toString();

        if (cleanedValue.includes('.')) {
            const number = parseFloat(cleanedValue);
            return isNaN(number) ? '0.00' : number.toLocaleString('es-MX', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }

        const number = parseFloat(cleanedValue);

        return isNaN(number) ? '0.00' : number.toLocaleString('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const formatRows = dataR22.accumulatedData?.map((row) => {
        return {
            id: row.id,
            garantia: row.dsGarantia,
            codigoGarantia: row.cdGarantia,
            cdCapita: row.cdCapita,
            sumaAsegurada: typeof row.sumaAsegurada === 'string' ? row.sumaAsegurada : (row.sumaAsegurada / 100).toLocaleString('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
        };
    });


    useEffect(() => {
        (async () => {
            setData(formatRows);
            const { data: resp, error } = await endoso.diferencia({ solicitud: dataR22.accumulatedData[0].idSolicitud })
            if (resp) setDiferencia(resp);
            if (error || resp) setLoading(false);
        }
        )();
    }, []); // eslint-disable-line


    const columns = [
        {
            field: "garantia",
            headerName: "Garantía",
            flex: 2,
            headerClassName: "header",
            headerAlign: "center",
        },
        {
            field: "sumaAsegurada",
            headerName: "Suma Asegurada",
            flex: 1,
            headerClassName: "header",
            headerAlign: "center",
        },
    ];

    return (
        <Fragment>
            {loading ? <Spinner /> : (
                <>
                    <Typography variant="h6" color="primary">
                        Coberturas de la Ubicación
                    </Typography>
                    <DataGrid
                        columns={columns}
                        rows={formatRows || []}
                        className={classes.root}
                        disableSelectionOnClick
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        rowsPerPageOptions={[4]}
                        pageSize={4}
                        pagination
                        getRowId={(row) => row.id}
                        components={{
                            NoRowsOverlay: () => (
                                <GridOverlay>No se encontró información</GridOverlay>
                            ),
                        }}
                    />
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography>Importe Actual: {'$' + formatCurrency(diferencia?.importeActual || 0)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Importe Anterior: {'$' + formatCurrency(diferencia?.importeAnterior || 0)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Diferencia: {'$' + formatCurrency(diferencia?.importeDiferencia || 0)}</Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </Fragment>
    );
};
