import { makeStyles } from "@material-ui/core";
import { colors } from "../../utils/colors";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "40%",
    },
  },
  header: {
    "& h2": {
      display: "flex",
      alignItems: "center",
    },
  },
  icon: {
    color: colors.warning,
    fontSize: "35px",
    marginRight: "8px",
  },
  infoIcon: {
    color: colors.blueDarken,
    fontSize: "35px",
    marginRight: "8px",
  },
  cancel: {
    color: colors.disabled,
    border: colors.disabled + " solid 1px",
    borderRadius: "19px",
    textTransform: "none",
    "&:hover": {
      color: colors.blueDarken,
    },
    transition: ".2s",
  },
  submit: {
    borderRadius: "19px",
    textTransform: "none",
  },
});

export default useStyles;
