import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../shared/utils";

const useStyles = makeStyles({
  root: {
    background: colors.blueLighten,
    display: "flex",
    height: "100%",
  },
  sections: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:last-child": {
      flex: 1,
      justifyContent: "flex-start",
      padding: "0 8px",
    },
  },
  tabContainer: {
    marginTop: "16px",
  },
  legendContainer: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    color: colors.white,
  },
  legend: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
    "&:first-child": { marginTop: 0 },
    "& #colorBox": {
      marginLeft: "4px",
      width: "30px",
      height: "20px",
      borderRadius: "4px",
      marginRight: "8px",
    },
  },
});

export default useStyles;
