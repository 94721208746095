import { createContext } from "react";

export const EndosoContext = createContext({
  alertData: undefined,
  setAlertData: undefined,
  checkedPersons: undefined,
  setCheckedPersons: undefined,
  showButtons: undefined,
  setShowButtons: undefined,
  formDta: undefined,
  setFormDta: undefined,
  showMessage: undefined,
  setShowMessage: undefined,
  message: undefined,
  setMessage: undefined,
  hideElement: undefined,
  createMessage: undefined,
  cargaB: undefined,
  setCargaB: undefined,
  cargaBData: undefined,
  setCargaBData: undefined,
  bajaRiesgo15: undefined,
  setBajaRiesgo15: undefined,
  searchWord: undefined,
  setSearchWord: undefined,
  selected: undefined,
  setSelected: undefined,
  autos: undefined,
  setAutos: undefined,
  auto: undefined,
  setAuto: undefined,
  descripcion: undefined,
  setDescripcion: undefined,
  infoTable: undefined,
  setInfoTable: undefined,
  filesResponse: undefined,
  setFilesResponse: undefined,
  spinner: undefined,
  setSpinner: undefined,
  finishR21: undefined,
  setFinishR21: undefined,
  continue21: undefined,
  setContinue21: undefined,
  dataR22: undefined,
  setDataR22: undefined,
  continueR22: undefined,
  setContinueR22: undefined,
  finishR22: undefined,
  setFinishR22: undefined,
  listErrors22: undefined,
  setListErrors22: undefined,
  rows23: undefined,
  setRows23: undefined,
  continueR23: undefined,
  setContinueR23: undefined,
  infoTable23: undefined,
  setInfoTable23: undefined,
  numeroSituacion: undefined,
  setNumeroSituacion: undefined,
});
