import { useContext, useEffect, useMemo } from "react";
import { AltaContext } from "../../../../context";

const getUnique = (array, key) => {
  if (array) {
    return array.filter(
      (v, i, a) => a.findIndex((t) => t[key] === v[key]) === i
    );
  }
  return [];
};

const getDependingCategories = (array, key, fields) => {
  if (fields?.categoria) {
    const values = array.filter(
      (categoria) => categoria.categoria === fields.categoria
    );
    return getUnique(values, key).map((value) => ({
      label: value[key],
      value: value[key],
    }));
  }
  return [];
};

export const useOptions = () => {
  const {
    comboOptions,
    situacion,
    removeDependencies,
    titulares,
    currentTitular,
  } = useContext(AltaContext);
  const uniqueCategories = getUnique(comboOptions.categorias, "categoria");

  const categoriaOptions = uniqueCategories.map((category) => ({
    label: category.categoria,
    value: category.categoria,
  }));
  const reglaOptions = useMemo(
    () => getDependingCategories(comboOptions.categorias, "regla", situacion),
    [comboOptions, situacion]
  );
  const tReglaOptions = useMemo(
    () => getDependingCategories(comboOptions.categorias, "tregla", situacion),
    [comboOptions, situacion]
  );
  const agrupadoresOptions = comboOptions.agrupadores.map((agrupador) => ({
    label: agrupador.cdagrupa + " - " + agrupador.nombre,
    value: agrupador.cdagrupa,
  }));
  const asociadoOptions =
    titulares?.filter((titular) => titular.familiares < 9) || [];
  const sexOptions = [
    { label: "Hombre", value: "H" },
    { label: "Mujer", value: "M" },
  ];
  const relationOptions = [
    { label: "Hijo", value: "H" },
    { label: "Conyugue", value: "C" },
    // { label: "Otros", value: "O" },
  ];
  if (!currentTitular) relationOptions.push({ label: "Titular", value: "T" });
  // eslint-disable-next-line
  useEffect(() => removeDependencies(), [situacion?.categoria]);
  return {
    sexOptions,
    relationOptions,
    agrupadoresOptions,
    categoriaOptions,
    reglaOptions,
    tReglaOptions,
    asociadoOptions,
  };
};
