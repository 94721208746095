import { useContext, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { EndosoContext } from "../../../../../context";
import { Input, Select } from "../fields";

export const Form = ({ showButtons, loading, setShowButtons }) => {
  const { auto, setDescripcion, descripcion } = useContext(EndosoContext);
  const options = useMemo(() => {
    const options = auto.descripciones;
    return options?.map((option) => ({ value: option, label: option }));
  }, [auto]);

  const handleChange = (e) => {
    setDescripcion(e.target.value);
  };

  useEffect(() => {
    setShowButtons({ ...showButtons, guardarEndoso: true }); // eslint-disable-next-line
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Input
          label="Marca"
          required
          disabled
          defaultValue={auto.marca ?? ""}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Modelo"
          required
          disabled
          defaultValue={auto.modelo ?? ""}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Placas"
          required
          disabled
          defaultValue={auto.placa ?? ""}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Número de serie"
          required
          disabled
          defaultValue={auto.serie ?? ""}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          label="Descripción"
          required
          defaultValue={descripcion ?? auto.descactual ?? ""}
          optionsProps={{ options: options }}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};
