import {
  Paper,
  Button,
  Typography,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/context";
import { endoso } from "../../../../services";
import { DinamicForm, Spinner } from "../../../../shared/components";
import { Cancelacion } from "../../../../shared/utils/cancelacionInput";

import {
  EndosoBajaRiesgo,
  AltaRiesgo16,
  AltaRiesgo14,
  CargaB20,
  BajaEndoso15,
  ModificacionDescripcion,
  BeneficiarioPreferente21,
  AltaUbicacion22,
} from "../tiposEndosos";
import AddDirection from "./addDirection";
import FormEmail from "./formEmail";
import FormTable from "./formTable";
import HighRiskForm from "./highRiskForm";
import InfoTable from "./InfoTable";
import InfoTableForRisk from "./InfoTableForRisk";
import InfoTableHighRisk from "./InfoTableHighRisk";
import useStyles from "./styles";
import TableChecked from "./tableChecked";
import { BajaUbicacion23 } from "../tiposEndosos/bajaUbicacion23";

const validateSexWhithTypeOfPerson = (data, datasend) => {
  const genders = data.find((item) => item.acronimo === "SEXO");
  if (datasend.TIPPER === "1") {
    return (
      genders.catalogo?.catalogo?.filter((option) => option.id !== "E") || []
    );
  } else {
    return (
      genders.catalogo?.catalogo?.filter((option) => option.id === "E") || []
    );
  }
};

const customizeDatosEndoso = (dataEndoso, datasend) => {
  const fixedSex = dataEndoso.map((data) => {
    if (data.acronimo === "SEXO") {
      const newCatalogo = validateSexWhithTypeOfPerson(dataEndoso, datasend);
      return {
        ...data,
        catalogo: {
          ...data.catalogo,
          catalogo: newCatalogo,
        },
      };
    } else return data;
  });
  const customData = fixedSex.filter((fields) => {
    if (datasend.TIPPER === "2") {
      if (
        fields.acronimo !== "NOMBR1" &&
        fields.acronimo !== "NOMBR2" &&
        fields.acronimo !== "DSAPEL1" &&
        fields.acronimo !== "DSAPEL2"
      ) {
        if (fields.acronimo === "NOMBRE") {
          return { ...fields, descripcionActual: "Razon social" };
        } else return fields;
      } else return null;
    } else {
      if (fields.acronimo !== "NOMBRE") return fields;
      else return null;
    }
  });
  return customData;
};

const FormsSection = ({
  loading,
  formDta = {},
  rows,
  setFormDta,
  spinner,
  setSpinner,
  setMessage,
  setShowMessage,
  dataSend,
  setData,
  showButtons,
  setOldDat,
  infoTable,
  data,
  setShowButtons,
  setRows,
  errorsSelect,
}) => {
  const classes = useStyles();
  const { persons, confirmData, setTimer } = useContext(AuthContext);
  const [showinput, setShowInput] = useState(false);
  const [getDirecction, setDirecction] = useState(false);
  const [search, setSearch] = useState("");
  const [rfcResponse, setRfcResponse] = useState([]);
  const [cliente, setCliente] = useState({});
  const handleChangeData = (e) => {
    setTimer(0);
    setData({
      ...dataSend,
      [e.target.name]:
        typeof e.target.value === "string"
          ? e.target.value.toUpperCase()
          : e.target.value,
    });
  };
  useEffect(() => {
    const setValues = () => {
      let values = {};
      formDta.datosEndoso.map(
        (item) => (values = { ...values, [item.acronimo]: item.valorActual })
      );
      setData(values);
      setOldDat(values);
    };
    if (formDta.datosEndoso) {
      setValues();
    } // eslint-disable-next-line
  }, [formDta]);
  const handleSubmitData = () => {
    setTimer(0);
    setShowButtons({ ...showButtons, domicilio: true });
  };
  /* methods */
  const handleChange = (e) => {
    setTimer(0);
    setSearch(e.target.value.toUpperCase());
  };
  const handleSearchPerson = async () => {
    setShowButtons({ ...showButtons, disableSix: true });
    setSpinner(true);

    const busqueda = await endoso.buscarPersona({
      nombre: null,
      rfc: search,
    });
    if (busqueda.data?.codigo === 1 && !busqueda.data?.mensaje) {
      setRfcResponse(busqueda.data?.personas);
    } else {
      setMessage({
        text: busqueda.data?.mensaje || "RFC INVALIDO",
        severity: "warning",
      });
      setShowMessage(true);
    }
    setSpinner(false);
  };
  const handleSearchDomicilios = async (cliente) => {
    setCliente(cliente);
    const datos = [
      {
        idDato: 7,
        type: "select",
        valorActual: cliente.razonSocial === "FISICA" ? "1" : "2",
        valorAnterior: null,
        descripcionActual: "Tipo persona",
        descripcionAnterior: "FISICA",
        caracteresMaximos: 1,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: {
          codigo: 1,
          cantidad: 2,
          catalogo: [
            {
              id: "1",
              descripcion: "FISICA",
            },
            {
              id: "2",
              descripcion: "MORAL",
            },
          ],
        },
        editable: false,
        acronimo: "TIPPER",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 21,
        type: "text",
        valorActual: cliente.rfc,
        valorAnterior: null,
        descripcionActual: "R.F.C",
        descripcionAnterior: null,
        caracteresMaximos: 3,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "RFC",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 1,
        type: "text",
        valorActual: cliente.homoclave,
        valorAnterior: null,
        descripcionActual: "Homoclave",
        descripcionAnterior: null,
        caracteresMaximos: 3,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "HCLAVE",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 2,
        type: "text",
        valorActual: cliente.nombre,
        valorAnterior: null,
        descripcionActual: "Nombre",
        descripcionAnterior: null,
        caracteresMaximos: 70,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "NOMBRE",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 3,
        type: "text",
        valorActual: cliente.primerNombre,
        valorAnterior: null,
        descripcionActual: "Primer Nombre",
        descripcionAnterior: null,
        caracteresMaximos: 20,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "NOMBR1",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 4,
        type: "text",
        valorActual: cliente.segundoNombre,
        valorAnterior: null,
        descripcionActual: "Segundo Nombre",
        descripcionAnterior: null,
        caracteresMaximos: 20,
        caracteresMinimos: 0,
        obligatorio: false,
        catalogo: null,
        editable: false,
        acronimo: "NOMBR2",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 5,
        type: "text",
        valorActual: cliente.apPaterno,
        valorAnterior: null,
        descripcionActual: "Apellido Paterno",
        descripcionAnterior: null,
        caracteresMaximos: 20,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "DSAPEL1",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 6,
        type: "text",
        valorActual: cliente.apMaterno,
        valorAnterior: null,
        descripcionActual: "Apellido Materno",
        descripcionAnterior: null,
        caracteresMaximos: 20,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "DSAPEL2",
        suplemLogico: null,
        visible: true,
      },

      {
        idDato: 8,
        type: "select",
        valorActual:
          cliente.sexo === "EMPRESA"
            ? "E"
            : cliente.sexo === "HOMBRE"
              ? "H"
              : "M",
        valorAnterior: null,
        descripcionActual: "Sexo",
        descripcionAnterior: "MUJER",
        caracteresMaximos: 1,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: {
          codigo: 1,
          cantidad: 3,
          catalogo: [
            {
              id: "E",
              descripcion: "EMPRESA",
            },
            {
              id: "H",
              descripcion: "HOMBRE",
            },
            {
              id: "M",
              descripcion: "MUJER",
            },
          ],
        },
        editable: false,
        acronimo: "SEXO",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 9,
        type: "text",
        valorActual: cliente.fchNacimineto,
        valorAnterior: null,
        descripcionActual: "Fecha de nacimiento",
        descripcionAnterior: null,
        caracteresMaximos: 0,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "FECNAC",
        suplemLogico: null,
        visible: true,
      },
      {
        idDato: 16,
        type: "text",
        valorActual: cliente.idPersona,
        valorAnterior: null,
        descripcionActual: "ID Persona",
        descripcionAnterior: null,
        caracteresMaximos: 9,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: null,
        editable: false,
        acronimo: "IDPERSON",
        suplemLogico: null,
        visible: false,
      },
      {
        idDato: 22,
        type: "select",
        valorActual: null,
        valorAnterior: null,
        descripcionActual: "Seleccionar Domicilio",
        descripcionAnterior: null,
        caracteresMaximos: 1,
        caracteresMinimos: 0,
        obligatorio: true,
        catalogo: {
          codigo: 1,
          cantidad: 3,
          catalogo: cliente.estructuraDomicilio?.domicilios?.map(
            (domicilio, index) => ({
              descripcion: domicilio.ciudad,
              id: index + 1,
            })
          ),
        },
        editable: true,
        acronimo: "DOMICSEL",
        suplemLogico: null,
        visible: true,
      },
    ];
    setFormDta({ ...formDta, datosEndoso: datos, mensaje: null });
  };
  const renderSections = () => {
    if (Boolean(errorsSelect) && Object.keys(errorsSelect).length > 0) {
      return <></>;
    } else {
      if (
        !showButtons.domicilio &&
        !showButtons.highRisk &&
        !showButtons.sendEmail
      ) {
        if (data.tipend === 17 && !spinner && !!data.fechainicial) {
          return (
            <EndosoBajaRiesgo
              rows={rows}
              showButtons={showButtons}
              loading={loading}
            />
          );
        }
        if (data.tipend === 18 && !spinner && !!data.fechainicial) {
          return (
            <ModificacionDescripcion
              showButtons={showButtons}
              setShowButtons={setShowButtons}
              loading={loading}
            />
          );
        } else if (
          data.tipend === 15 &&
          !spinner &&
          !!data.fechainicial &&
          !showButtons?.multiTable
        ) {
          return <BajaEndoso15 />;
        } else if (data.tipend === 16 && !spinner && !!data.fechainicial) {
          return <AltaRiesgo16 />;
        } else if (
          data.tipend === 20 &&
          showButtons.formulario &&
          !spinner &&
          !!data.fechainicial
        ) {
          return (
            <CargaB20
              showButtons={showButtons}
              setShowButtons={setShowButtons}
            />
          );
        } else if (data.tipend === 21 && !spinner && showButtons.guardarEndoso) {
          return <BeneficiarioPreferente21 data={data} />;
        } else if (data.tipend === 22 && !spinner  && showButtons.guardarEndoso) {
          return <AltaUbicacion22 data={data} noSolicitud={formDta.noSolicitud} setData={setData} />;
        } else if (data.tipend === 23 && !spinner  && showButtons.guardarEndoso) {
          return <BajaUbicacion23 noSolicitud={formDta.noSolicitud} data={data} />;
        } else if (data.tipend === 6 && formDta.mensaje) {
          return (
            <Fragment>
              <Typography variant="h6">
                {!showinput
                  ? "No existe un beneficiario para esta póliza"
                  : "Alta de beneficiario Preferente"}
              </Typography>
              {!getDirecction &&
                (showinput ? (
                  <Fragment>
                    <div>
                      <div style={{ marginBottom: "16px" }}>
                        <InputLabel htmlFor={"rfc"}>{"RFC"}</InputLabel>
                        <TextField
                          className={classes.input}
                          variant="outlined"
                          name={"rfc"}
                          size="small"
                          fullWidth
                          placeholder="Ingrese RFC"
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 15,
                            style: { textTransform: "uppercase" },
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", columnGap: "16px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={!search}
                          onClick={handleSearchPerson}
                        >
                          Buscar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={() => {
                            setShowButtons({
                              ...showButtons,
                              disableSix: false,
                            });
                            setDirecction(true);
                          }}
                        >
                          Agregar
                        </Button>
                      </div>
                      {rfcResponse.length > 0 && (
                        <div className={classes.personsContainer}>
                          <Typography variant="h6" color="textPrimary">
                            Personas
                          </Typography>
                          <div className={classes.personsGrid}>
                            {rfcResponse.map((cliente) => (
                              <div
                                key={cliente.rfc}
                                className={classes.person}
                                onClick={() => {
                                  handleSearchDomicilios(cliente);
                                }}
                              >
                                <Typography variant="body2">
                                  <strong> {cliente.nombre}</strong>
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div />
                  </Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      setShowInput(true);
                    }}
                  >
                    Alta de Beneficiario
                  </Button>
                ))}
              {getDirecction && (
                <Fragment>
                  <AddDirection
                    dataSend={dataSend}
                    type={3}
                    showButtons={showButtons}
                    setShowButtons={setShowButtons}
                    formDta={formDta}
                    setFormDta={setFormDta}
                  />
                  <div />
                </Fragment>
              )}
            </Fragment>
          );
        } else {
          if (data.tipend === 16 || data.tipend === 20) return <></>;
          return (
            <Fragment>
              {showButtons.formulario && (
                <div className={classes.sections}>
                  {formDta.datosEndoso && !showButtons.infoTable && (
                    <DinamicForm
                      inputs={customizeDatosEndoso(
                        data.tipend !== 5
                          ? formDta.datosEndoso
                          : [...formDta.datosEndoso, Cancelacion],
                        dataSend
                      )}
                      handlechange={handleChangeData}
                      dataSend={dataSend}
                      typeEnd={data.tipend}
                    />
                  )}
                </div>
              )}
              {showButtons.formTable && !showButtons.infoTable && (
                <FormTable
                  rows={rows}
                  setFormDta={setFormDta}
                  setSpinner={setSpinner}
                  setMessage={setMessage}
                  setShowMessage={setShowMessage}
                  data={data}
                  formDta={formDta}
                  setShowButtons={setShowButtons}
                  showButtons={showButtons}
                  setRows={setRows}
                  check={showButtons.check}
                />
              )}
              {!showButtons.formTable && showButtons.multiTable && (
                <InfoTableForRisk rows={rows} />
              )}
              {showButtons.multiCheck && !showButtons.infoTable && (
                <TableChecked />
              )}
              {showButtons.infoTable && ![21, 22, 23].includes(data.tipend) && <InfoTable rows={infoTable} />}
              <div className={classes.sections}>
                {data.tipend === 1 &&
                  !showButtons.infoTable &&
                  formDta.datosEndoso &&
                  formDta.codigo === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmitData}
                    >
                      Agregar Domicilio
                    </Button>
                  )}
              </div>
            </Fragment>
          );
        }
      }
      if (
        showButtons.domicilio &&
        !showButtons.highRisk &&
        !showButtons.sendEmail
      ) {
        return (
          <Fragment>
            <AddDirection dataSend={dataSend} />
          </Fragment>
        );
      }
      if (
        showButtons.highRisk &&
        !showButtons.domicilio &&
        !showButtons.sendEmail
      ) {
        if (data.tipend === 12) {
          if (!showButtons.multiTable) {
            return (
              <HighRiskForm
                setMessage={setMessage}
                setShowMessage={setShowMessage}
              />
            );
          } else {
            return (
              <Fragment>
                <InfoTableHighRisk rows={persons} />
              </Fragment>
            );
          }
        } else if (data.tipend === 14) {
          return (
            <AltaRiesgo14
              setMessage={setMessage}
              setShowMessage={setShowMessage}
              showButtons={showButtons}
            />
          );
        }
      }
      if (showButtons.sendEmail) {
        if (!showButtons.formEmail) {
          return <FormTable rows={confirmData} check type={2} />;
        } else {
          return (
            <FormEmail
              setSpinner={setSpinner}
              setMessage={setMessage}
              setShowMessage={setShowMessage}
              data={data}
              formDta={formDta}
              setShowButtons={setShowButtons}
              showButtons={showButtons}
            />
          );
        }
      }
    }
  };

  useEffect(() => {
    if (data.tipend === 6 && Object.values(cliente)?.length > 0) {
      if (dataSend.DOMICSEL) {
        const domicilioData = [
          {
            idDato: 10,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .calle,
            valorAnterior: null,
            descripcionActual: "Calle",
            descripcionAnterior: null,
            caracteresMaximos: 50,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "DOMIC",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 11,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .numeroExterior,
            valorAnterior: null,
            descripcionActual: "Número Exterior",
            descripcionAnterior: null,
            caracteresMaximos: 10,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "NUMERO",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 12,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .numeroInterior,
            valorAnterior: null,
            descripcionActual: "Número Interior",
            descripcionAnterior: null,
            caracteresMaximos: 20,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "OTPISO",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 13,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .idColonia,
            valorAnterior: null,
            descripcionActual: "Colonia",
            descripcionAnterior:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .colonia,
            caracteresMaximos: 5,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "COLONI",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 14,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .idMunicipio,

            descripcionActual: "Delegación",
            descripcionAnterior:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .municipio,
            caracteresMaximos: 25,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "OTPOB",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 15,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .idEstado,
            descripcionActual: "Estado",
            descripcionAnterior:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .estado,
            caracteresMaximos: 2,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "PROVIN",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 18,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .codigoPostal,
            valorAnterior: "",
            descripcionActual: "Código Postal",
            descripcionAnterior: null,
            caracteresMaximos: 6,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "CP",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 19,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .telefono,
            valorAnterior: null,
            descripcionActual: "Teléfono",
            descripcionAnterior: null,
            caracteresMaximos: 15,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "TELEFO",
            suplemLogico: null,
            visible: true,
          },
          {
            idDato: 20,
            type: "text",
            valorActual:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .idPais,
            valorAnterior:
              cliente.estructuraDomicilio?.domicilios[dataSend.DOMICSEL - 1]
                .pais,
            descripcionActual: "País",
            descripcionAnterior: "MEXICO",
            caracteresMaximos: 3,
            caracteresMinimos: 0,
            obligatorio: true,
            catalogo: null,
            editable: false,
            acronimo: "PAIS",
            suplemLogico: null,
            visible: true,
          },
        ];
        setFormDta({
          ...formDta,
          datosEndoso: formDta.datosEndoso
            .filter(
              (input) =>
                input.idDato !== 10 ||
                input.idDato !== 11 ||
                input.idDato !== 12 ||
                input.idDato !== 13 ||
                input.idDato !== 14 ||
                input.idDato !== 15 ||
                input.idDato !== 20
            )
            .concat(domicilioData),
          mensaje: null,
        });
        if (dataSend.FECNAC && dataSend.FECNAC.length > 0)
          setShowButtons({ ...showButtons, disableSix: false });
      }
    } // eslint-disable-next-line
  }, [dataSend.DOMICSEL]);
  return (
    <Paper elevation={3} className={classes.root}>
      {spinner && <Spinner />}
      {renderSections()}
    </Paper>
  );
};

export default FormsSection;
